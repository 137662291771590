import moment from 'moment'
import React, {FC, useEffect, useState} from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import useAppToast from 'src/hooks/useAppToast'
import AppDatePickerRange from '../orders/components/AppDatePickerRange'
import {Spinner} from 'react-bootstrap'
import {KTIcon, KTSVG} from 'src/marketConnect/helpers'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {
  getCustomersAsync,
  loginAsCustomerAsync,
  updateCustomerStatusAsync,
} from 'src/redux/actions/customerAction'
import {UserActionsCell} from './UserActionCell'
import {CUSTOMER} from 'src/app/_interfaces/customer.interface'
import CommonAlertModal from 'src/utils/modals/CommonAlertModal'
import { Platform, PlatformDataType } from 'src/interfaces/Platform.interface'
import { Link } from 'react-router-dom'

const CommonColumn: FC<{rowData: string}> = ({rowData}) => {
  return (
    <div className='row'>
      <p className='d-inline-block  ' style={{maxWidth: '180px'}}>
        {rowData}
      </p>
    </div>
  )
}

const PlatformsList: FC = () => {

  const [searchString, setSearchString] = useState('')
  const [showDateRangePicker, setShowDateRangePicker] = useState(false)
  const [showDateRangePickerForOrders, setShowDateRangePickerForOrders] = useState(false)

  const dispatch = useAppDispatch()
  const {platformData, platformLoading } = useAppSelector((state) => state.platform)
  const [confirmUpdateStatus, setConfirmUpdateStatus] = useState(false)
  const [updatingStatus, setUpdatingStatus] = useState(false)
  const [selectedPlatform, setSelectedPlatform] = useState<Platform|null>(null)
  // -------

  //used for login as customer
  const [confirmLoginAsUser, setConfirmLoginAsUser] = useState(false)
  const [loggingIn, setLoggingIn] = useState(false)
  //------

  const [paginationData, setPaginationData] = useState({
    page_limit: 10,
    page_num: 1,
    order_by: '-id',
    search: '',
    user_type: 'USER',
    from_date: '',
    to_date: '',
    sync_from_date: '',
    sync_to_date: '',
    source: '',
  })

  const columns:TableColumn<Platform>[] = [
    {
      name: 'Display Name',
      selector: (row) => row.name,
      cell: (row) => (row ? <CommonColumn rowData={row.name} /> : null),
      minWidth: '140px',
    },

    {
      name: 'Platform Name',
      selector: (row) => row.name,
      cell: (row) => (row ? <CommonColumn rowData={row.name} /> : null),
      minWidth: '200px',
    },

    {
      name: 'Logo',
      cell: (row) => (row?.logo_ref ? <img src={row?.logo_ref} className='h-30px' /> : null),
      minWidth: '160px',
    },

    {
      name: 'Status',
      selector: (row) => row.isActive,
      cell: (row) => {
        if (row.isActive) {
          return <span className='badge badge-light-success px-3 py-2 text-success'>Active</span>
        } else {
          return <span className='badge badge-light-danger px-3 py-2 text-danger'>Inactive</span>
        }
      },
      center: true,
      minWidth: '120px',
    },

    {
      name: 'Auth Type',
      selector: (row) => row.isOauth,
      cell: (row) => {
        if(row.isOauth){
          return <span>{'Oauth'}</span>
        }else{
          return <span>API Keys</span>
        }
      },
      minWidth: '140px',
    },
    {
      name: 'Allowed Multiple Oauth',
      selector: (row) => row.isAllowMultiAuth,
      cell: (row) => (
        <div>
          <p>
            <span className='fw-bold'>{row?.isAllowMultiAuth ? 'Yes' : 'No' }</span>
          </p>
          
        </div>
      ),
      center: true,
      minWidth: '180px',
    },
    {
      name: 'Action',
      cell: (row) => (
        <UserActionsCell
          platform={row}
          onLoginAsCustomer={() => {
            setConfirmLoginAsUser(true)
             
          }}
          updateCustomerStatus={() => {
            setConfirmUpdateStatus(true)
            
          }}
        />
      ),
      center: true,
      minWidth: '140px',
    },
  ]

  const handleResetFilters = () => {
    setPaginationData({
      page_limit: 10,
      page_num: 1,
      order_by: 'id',
      search: '',
      user_type: 'USER',
      from_date: '',
      to_date: '',
      sync_from_date: '',
      sync_to_date: '',
      source: '',
    })
    setSearchString('')
  }

  useEffect(() => {
     //get platform list will call 
  }, [paginationData])

  return (
    <div>
      <>
        <div className='mb-3'>
          <span className='fw-bold text-lg ' style={{fontSize: '16px'}}>
            Manage Platforms
          </span>
        </div>

        <div className='card border-0 rounded shadow'>
          <div className='card-header bg-white'>
            <div className='card-title'>
              <input
                className='form-control input-sm '
                type='search'
                placeholder='Search By Name'
                aria-label='Search'
                onChange={(ev) => {
                  setSearchString(ev.target.value)
                }}
                onKeyDown={(ev) => {
                  if (ev.key == 'Enter') {
                    setPaginationData((pre) => {
                      return {...pre, search: searchString, page: 1}
                    })
                  }
                }}
                value={searchString}
                style={{minWidth: '180px'}}
              />
            </div>
            <div className='card-toolbar'>
              <div className='d-flex justify-content-end mb-4 mt-3 me-3'>
                <div className='mx-2'>
                  <Link
                    to={'/manage-platform'}
                    className='btn fs-8 btn-sm btn-primary fw-bold'
                    onClick={handleResetFilters}
                  >
                    Add Platform
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <DataTable
            fixedHeader={true}
            data={platformData}
            columns={columns}
            //   onSort={handleSort}
            paginationTotalRows={platformData.length ?? 0}
            responsive
            pagination
            paginationServer
            progressPending={platformLoading}
            progressComponent={<Spinner animation='border' />}
            onChangePage={(pageNumber, totalRows) => {
              setPaginationData((pre) => {
                return {...pre, page_num: pageNumber}
              })
            }}
            onChangeRowsPerPage={(currentPage) => {
              setPaginationData((pre) => {
                return {...pre, limit: currentPage}
              })
            }}
            customStyles={{
              table: {
                style: {
                  height: '52vh',
                },
              },
              headCells: {
                style: {fontWeight: 'bold', fontSize: '14px'},
              },
              cells: {
                style: {},
              },
            }}
          />
          <CommonAlertModal
            key={'status change modal'}
            title={` You are ${
              selectedPlatform?.isActive === true ? 'Deactivating' : 'Activating'
            } the ${
              selectedPlatform?.name ? selectedPlatform.name : 'customer'
            }. Do you want to continue?`}
            subtitle='Press "Confirm" for continue'
            successBtnTitle={updatingStatus ? 'Updating...' : 'Confirm'}
            successDisabled={updatingStatus}
            isVisible={confirmUpdateStatus}
            onSuccess={() => {}}
            onCancel={() => {}}
            isTermsVisible={false}
          />
        </div>
      </>
    </div>
  )
}

export default PlatformsList
