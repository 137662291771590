import React, { FC } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Platform } from 'src/interfaces/Platform.interface';
import { useAppSelector } from 'src/redux/storeHooks';

const AvailablePlatforms:FC = () => {
 
  const {platformData, platformLoading } = useAppSelector((state) => state.platform)

  return (
    <div className="row">
      {platformData.map((platform:Platform) => (
        <div key={platform.id} className='col-lg-3 col-md-4 col-12 g-4' >
          <Card className='shadow border-0 p-4 bg-hover-light-primary h-100' >
            <Card.Img variant="top" src={platform.logo_ref} height={'80px'} style={{objectFit:'contain', filter: 'drop-shadow(1px 2px 4px gray)'}}  />
            <Card.Body className='pb-0' >
              <Card.Title className='text-center fs-4 lh-1' >{platform.display_name}</Card.Title>
              <Card.Text className='text-center text-truncate' >
                Add events for {platform.name}
              </Card.Text>
              <div>
                <Link to={`/events/${1}`} className='btn btn-sm btn-success w-100 fw-bold' style={{backgroundColor:'#07bc0c'}} >Manage Event</Link>
              </div>
            </Card.Body>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default AvailablePlatforms;
