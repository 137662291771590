/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {toAbsoluteUrl} from 'src/marketConnect/helpers'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {loginAsync} from 'src/redux/actions/authActions'
import {accessSessionTokenKeyName, refreshSessionTokenKeyName} from 'src/utils/constants'
import useAppToast from 'src/hooks/useAppToast'
import {messageTemplete} from 'src/utils/messageTemplete'
import {userInfo} from 'os'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const dispatch = useAppDispatch()
  const {} = useAppSelector((state) => state.auth)
  const {successToast, errorToast, warningToast} = useAppToast()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await dispatch(loginAsync({email: values.email, password: values.password}))
          .then((response) => {
            console.log('response status:',response.payload?.status, response?.payload?.data?.detail)

            if(response.payload?.status=='401'){
              warningToast(response?.payload?.data?.detail)
            }

            // localStorage.setItem('userid', email)
            // console.log("userid:",localStorage.getItem(`userid`))
            // if(response.meta.requestStatus=='fulfilled'){

            //   console.log("login data", response.payload);
            //     // sessionStorage.setItem(sessionTokenKeyName, "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c")
            //     // setSubmitting(false)
            //     setLoading(false)
            //   }else{
            //     errorToast("login failed")
            //     setSubmitting(false)
            //     setLoading(false)
            //   }
            if (response?.payload?.status === 401) {
              errorToast(response?.payload?.detail)
              setLoading(false)
            } else if (response?.payload?.access) {
              console.log('show toast msg')
              localStorage.setItem(accessSessionTokenKeyName, response?.payload?.access)
              localStorage.setItem(refreshSessionTokenKeyName, response?.payload?.refresh)
              successToast(messageTemplete.LOGIN_SUCCESS)
              setLoading(false)
            } else {
              errorToast(messageTemplete.SOMETHING_WENT_WRONG)
              setLoading(false)
            }
          })
          .catch((error) => {
            console.log('error catch:', error)
            setLoading(false)
          })
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    // Check if the script is already added
    const scriptId = "mouseflow-script";
    if(process.env['REACT_APP_ENV'] === 'production'){
      if (!document.getElementById(scriptId)) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.defer = true;
        script.src = "//cdn.mouseflow.com/projects/d9c4c389-87cf-4a12-a4f1-2358807273b2.js";
        script.id = scriptId;
        document.head.appendChild(script);
  
        // Initialize the Mouseflow queue if not already set
        (window as any)._mfq = (window as any)._mfq || [];
      }
    }
    // Cleanup function to remove the script when the component unmounts
    return () => {
      const existingScript = document.getElementById(scriptId);
      if (existingScript) {
        existingScript.remove();
      }
      // Optionally clear the Mouseflow queue
      (window as any)._mfq = undefined;
    };
  }, []); // Empty dependency array ensures it runs only once when the component mounts

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
      </div>
      {/* begin::Heading */}

      {/* begin::Login options */}
      {/* <div className='row g-3 mb-9'> */}
      {/* begin::Col */}
      {/* <div className='col-md-6'> */}
      {/* begin::Google link */}
      {/* <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a> */}
      {/* end::Google link */}
      {/* </div> */}
      {/* end::Col */}

      {/* begin::Col */}
      {/* <div className='col-md-6'> */}
      {/* begin::Google link */}
      {/* <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a> */}
      {/* end::Google link */}
      {/* </div> */}
      {/* end::Col */}
      {/* </div> */}
      {/* end::Login options */}

      {/* begin::Separator */}
      {/* <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div> */}
      {/* end::Separator */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          placeholder='Password'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div>
    </form>
  )
}
