import React, {FC, useState, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import clsx from 'clsx'
import platformData from 'src/@dummyData/allplatformdata.json' // Import JSON file
import MarketPlace from './MarketPlace'
import ECommercePlatforms from './ECommercePlatforms'
import CRMPlatforms from './CRMPlatforms'
import ShippingPlatforms from './ShippingPlatforms'
import AccountingPlatforms from './AccountingPlatforms'
import Settings from './Settings'

const Onboard: FC = () => {
  const [show, setShow] = useState(false)
  const [selectedStep, setSelectedStep] = useState<string>('Marketplace Platforms') // Default to Marketplace
  const [platforms, setPlatforms] = useState<any[]>([])

  useEffect(() => {
    // Prevent scrolling on the body when modal is active
    if (show) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [show])

  useEffect(() => {
    // Load platforms from the JSON file
    setPlatforms(platformData.data)
    setShow(true)
  }, [])

  const handleClose = () => setShow(false)
  const handleStepClick = (step: string) => setSelectedStep(step)

  // Group platforms by type
  const groupedPlatforms = platforms.reduce((acc, platform) => {
    if (!acc[platform.type]) {
      acc[platform.type] = []
    }
    acc[platform.type].push(platform)
    return acc
  }, {} as Record<string, any[]>)

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size='xl'
        centered
        backdrop='static' // Disable closing on backdrop click
        keyboard={false} // Disable closing on Esc
        animation={false} // Disable fade-in/fade-out animation
        className='modal-static'
        style={{minHeight: '400px'}} // Set min-height of modal
      >
        <Modal.Header closeButton className='sticky-top bg-white z-index-modal-header'>
          <Modal.Title>Step Progress</Modal.Title>
        </Modal.Header>

        {/* Step Navigation in Modal.Body */}
        <Modal.Body
          className='d-flex overflow-auto bg-light-primary'
          style={{
            maxHeight: '8vh',
            whiteSpace: 'nowrap',
          }}
        >
          {[
            'Marketplace Platforms',
            'E-Commerce Platforms',
            'CRM Platforms',
            'Shipping Platforms',
            'Accounting Platforms',
            'Settings',
          ].map((step) => (
            <div
              key={step}
              className={clsx(
                'd-flex align-items-center px-4 py-2',
                selectedStep === step ? 'text-primary' : 'text-secondary',
                'cursor-pointer',
                'flex-grow-1', // Ensure each div takes equal space
                'justify-content-center' // Center content inside each div
              )}
              onClick={() => handleStepClick(step)}
              style={{
                minWidth: '0', // Allow the content to shrink and grow freely
              }}
            >
              <i
                className={clsx(
                  `bi bi-${
                    ['1', '2', '3', '4', '5', '6'][
                      [
                        'Marketplace Platforms',
                        'E-Commerce Platforms',
                        'CRM Platforms',
                        'Shipping Platforms',
                        'Accounting Platforms',
                        'Settings',
                      ].indexOf(step)
                    ]
                  }-circle-fill fs-2x`,
                  selectedStep === step ? 'text-primary' : 'text-secondary'
                )}
              ></i>
              <small className='fs-6 fw-bold lh-1 text-center ms-2'>{step}</small>
            </div>
          ))}
        </Modal.Body>

        {/* Render Platform Cards based on Selected Step */}
        <Modal.Body className='overflow-auto' style={{maxHeight: '70vh'}}>
          <div className='mt-4'>
            <h5>Selected Step: {selectedStep}</h5>
            {/* Render cards based on selected step */}
            {selectedStep === 'Marketplace Platforms' && (
              <MarketPlace platforms={groupedPlatforms['MARKETPLACE']} />
            )}
            {selectedStep === 'E-Commerce Platforms' && (
              <ECommercePlatforms platforms={groupedPlatforms['ECOMMERCE']} />
            )}
            {selectedStep === 'CRM Platforms' && (
              <CRMPlatforms platforms={groupedPlatforms['POS']} />
            )}
            {selectedStep === 'Shipping Platforms' && (
              <ShippingPlatforms platforms={groupedPlatforms['SOURCE']} />
            )}
            {selectedStep === 'Accounting Platforms' && (
              <>
                <h6>All Platform Types</h6>
                {Object.keys(groupedPlatforms).map((type) => (
                  <AccountingPlatforms platforms={groupedPlatforms[type]} key={type} />
                ))}
              </>
            )}
            {selectedStep === 'Settings' && <Settings />}
          </div>
        </Modal.Body>
      </Modal>
      <style>{`
        .modal-dialog .modal-content {
          min-height: 650px; 
        }
      `}</style>
    </>
  )
}

export default Onboard
