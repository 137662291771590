import {Formik, useFormik, useFormikContext} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {FIELD_MAPPING_DATA, FIELD_MAPPING_TYPE, RetrievePlatformDataType, SETTINGS_OF_PLATFORM, ShippingMethodMapping } from 'src/interfaces/Platform.interface'
import {getSettingsOfPrimaryPlatformAsync, getSettingsOfSelectedPlatformAsync, saveFieldMappingForSectionWiseAsync} from 'src/redux/actions/platformSettingActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'
import InfoTooltip from './components/InfoTooltip'
import CustomInput from './components/CustomInput'
import ShortCodeInput from './components/ShortCodeInput'
import {
  createPlatformSettingAsync,
  retrivePlatformDataAsync,
} from 'src/redux/actions/platformActions'
import {useParams} from 'react-router-dom'
import DynamicChildSetting from './components/DynamicChildSetting'
import useAppToast from 'src/hooks/useAppToast'
import {isActiveBoolean} from 'src/redux/actions/changeisActiveAction'
import MappingInput from './components/MappingInput'


const DynamicPlatformSetting: FC = () => {
  //possible groupcode ORDER, PRODUCT, INVENTORY, SHIPMENT, PRICING
  const {selectedPlatformId, availablePlatformId} = useParams()
  const dispatch = useAppDispatch()
  const {errorToast, successToast, warningToast} = useAppToast()
  const {loadPlatformSetting, settings} = useAppSelector((state) => state.platformSetting)
  const [platformSettings, setPlatformSettings] = useState<SETTINGS_OF_PLATFORM[] | []>([])
  const [primaryPlatformSettings, setPrimaryPlatformSettings] = useState<SETTINGS_OF_PLATFORM[] | []>([])
  const [formFields, setFormFields] = useState<any>({
    main_product_identifier: '',
    secondary_product_identifier: '',
  })
  const [retriveDataLoading, setRetriveDataLoading] = useState<boolean>(true)
  const [loadingFormFields, setLoadingFormFields] = useState(true)
  const [loadInitialKeyValues, setLoadInitialKeyValues] = useState<boolean>(false)
  const {connectedPlatformData} = useAppSelector((state) => state.connectedPlatform)
  const [retrivedPlatformData, setRetrivedPlatformData] = useState<RetrievePlatformDataType | null>(
    null
  )
  const [fieldMappings, setFieldMappings] = useState<FIELD_MAPPING_DATA[]>([])
  const [isMappingChanged, setIsMappingChanged] = useState(false)
  const {handleChange, values, handleSubmit, setFormikState, isSubmitting, setValues} = useFormik({
          initialValues:formFields,
          onSubmit:(values, helpers) => {
            const {...othervalues} = values
            const allSettings = {
              platformId: Number(selectedPlatformId),
              ...othervalues,
            }
            try {
              dispatch(createPlatformSettingAsync(allSettings)).then(async (response) => {
                
                if (response?.payload?.status_code === 200) {
                  if(response.payload?.data && fieldMappings.length ){
                   
                    for(let settingField of fieldMappings ){
                      if(response.payload?.data[settingField.groupCode]){
                        let mapping_for_save = settingField.fieldMappingDataSet.length && settingField.fieldMappingDataSet.map((item) => {
                          if (item.id) {
                            return item
                          } else {
                            return {
                              source_platform_data_id: item?.source_platform_data_id,
                              destination_platform_data_id: item?.destination_platform_data_id,
                              source_platform_data_str: item?.source_platform_data_str,
                              destination_platform_data_str: item?.destination_platform_data_str,
                            }
                          }
                        })
                        await dispatch(
                          saveFieldMappingForSectionWiseAsync({
                            mappingId: settingField.savedMappingId,
                            payload : {
                              [settingField.groupCode+'_id']:response.payload?.data[settingField.groupCode]['id'],
                              source_field_id: settingField.sourceFieldId,
                              destination_field_id: settingField.destinationFieldId,
                              fieldmappingdata_set: mapping_for_save,
                            }
                          })
                        ).then((res)=>{
                           
                          if(res.payload?.fieldmappingdata_set){
                             
                            let shasf = {...settingField}
                            let fildmpdset = res.payload?.fieldmappingdata_set?.map((item)=>{
                              return {
                                id: item?.id,
                                source_platform_data_id: item?.source_platform_data?.id,
                                destination_platform_data_id: item?.destination_platform_data?.id,
                                source_platform_data_str: item?.source_platform_data_str,
                                destination_platform_data_str: item?.destination_platform_data_str,
                              }
                            })

                            shasf.fieldMappingDataSet = [...fildmpdset]
                            let filtered = fieldMappings.filter((it)=>((it.fieldCode!==settingField.fieldCode && it.groupCode!==settingField.groupCode && it.savedMappingId!==settingField.savedMappingId)))
                              if(settingField?.savedMappingId==119){
                                console.log( filtered, '\nshasf',shasf, fildmpdset)
                              }
                            setFieldMappings([...filtered, {...shasf} ])
                          }
                          
                        })
                        
                      }
                    }
                    successToast('Channel settings saved successfully !')
                    helpers.setSubmitting(false)
                    setIsMappingChanged(!isMappingChanged)
                    // window.location.reload()
                  }else{
                    successToast('Channel settings saved successfully !')
                    helpers.setSubmitting(false)
                  }
                }else if(response?.payload?.data?.status_code === 400){
                  warningToast(response?.payload?.data?.massage)
                  helpers.setSubmitting(false)
                }else if(response?.payload?.data?.status_code === 500){
                  warningToast(response?.payload?.data?.massage)
                  helpers.setSubmitting(false)
                }
                else {
                  warningToast('Setting not updated')
                  helpers.setSubmitting(false)
                }
              })
            } catch (error) {
              helpers.setSubmitting(false)
            }

            const id = Number(selectedPlatformId)!
            const display_name = connectedPlatformData?.display_name
            const isActive = connectedPlatformData?.isActive == false ? true : true
            const payload = {
              display_name,
              isActive,
            }

            dispatch(isActiveBoolean({...payload, id}))
          }
  })

  
    const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)

  // Grouping function
  function groupByGroupCode(fields) {
    // Use a temporary object to group fields by group_code
    const tempGrouped = {}

    fields.forEach((field) => {
      const {group_code} = field

      // Initialize group if it doesn't exist
      if (!tempGrouped[group_code]) {
        tempGrouped[group_code] = []
      }

      // Add the field to the appropriate group
      tempGrouped[group_code].push(field)
    })

    // Convert the grouped object into an array
    const groupedArray = Object.keys(tempGrouped).map((group_code) => ({
      group_code,
      fields: tempGrouped[group_code],
    }))

    return groupedArray
  }

  useEffect(() => {
    setRetriveDataLoading(true)
    if (availablePlatformId && selectedPlatformId  && mainStoreData?.platform_info?.id) {
      dispatch(retrivePlatformDataAsync({id: selectedPlatformId})).then((resp) => {
        setRetrivedPlatformData(resp.payload)
        if(resp.payload?.platform_settings!==null){
          // setRetrivedPlatformData(resp.payload) //added this top coz we have to get data to show connection
        }
        Promise.all([
          dispatch(getSettingsOfSelectedPlatformAsync({availablePlatformId})).then((response) => {
            if (response.payload?.data?.length) {
              setPlatformSettings(response.payload?.data)
              const currentPlatformSettings = response?.payload?.data
              let shadow = {...formFields}

              if (currentPlatformSettings.length) {
                currentPlatformSettings.forEach((groupItem) => {
                  let InitialValues = {}
                  let settingField =
                    groupItem.group_code === 'ORDER'
                      ? 'order'
                      : groupItem.group_code === 'PRODUCT'
                      ? 'product'
                      : groupItem.group_code === 'INVENTORY'
                      ? 'inventory'
                      : groupItem.group_code === 'SHIPMENT'
                      ? 'shipment'
                      : groupItem.group_code === 'PRICING'
                      ? 'pricing'
                      : groupItem.group_code.toLocaleLowerCase()
                  if (!InitialValues[`${settingField}_setting`]) {
                    InitialValues[`${settingField}_setting`] = {}
                    //add self top setting to object
                    if (groupItem.input_type === 'BOOLEAN') {
                      InitialValues[`${settingField}_setting`][`${groupItem.name}`] = false
                    } else if (groupItem.input_type === 'INTEGER') {
                      InitialValues[`${settingField}_setting`][`${groupItem.name}`] = 0
                    } else {
                      InitialValues[`${settingField}_setting`][`${groupItem.name}`] = ''
                    }
                  }
          
                  if (groupItem.children.length) {
                    groupItem.children.forEach((childItem) => {
                      
                      if (childItem.input_type === 'BOOLEAN') {
                        InitialValues[`${settingField}_setting`][`${childItem.name}`] = false
                        if (childItem.children.length) {
                          let children = generateInitialKeyFromChildrenForPlatformSetting(childItem.children)
                          InitialValues[`${settingField}_setting`] = { ...InitialValues[`${settingField}_setting`], ...children}
                        }
                      } else if (childItem.input_type === 'INTEGER') {
                        InitialValues[`${settingField}_setting`][`${childItem.name}`] = 0
                        if (childItem.children.length) {
                          let children = generateInitialKeyFromChildrenForPlatformSetting(childItem.children)
                          InitialValues[`${settingField}_setting`] = { ...InitialValues[`${settingField}_setting`], ...children}
                        }
                      }else if (childItem.input_type === 'DATETIME') {
                        InitialValues[`${settingField}_setting`][`${childItem.name}`] = null
                        if (childItem.children.length) {
                          let children = generateInitialKeyFromChildrenForPlatformSetting(childItem.children)
                          InitialValues[`${settingField}_setting`] = { ...InitialValues[`${settingField}_setting`], ...children}
                        }
                      }else {
                        InitialValues[`${settingField}_setting`][`${childItem.name}`] = ''
                        if (childItem.children.length) {
                          let children = generateInitialKeyFromChildrenForPlatformSetting(childItem.children)
                          InitialValues[`${settingField}_setting`] = { ...InitialValues[`${settingField}_setting`], ...children}
                        }
                      }
                    })
                  }
                  shadow = {...shadow,...InitialValues}
                })
                setFormFields(shadow)
                setLoadingFormFields(false)
               
              } 
            } else {
              setLoadingFormFields(false)
            }
          }),
          dispatch(getSettingsOfPrimaryPlatformAsync({ primaryAvailablePlatformId: mainStoreData?.platform_info?.id! })).then((response) => {
             
            if (response.payload?.data?.length) {
              setPrimaryPlatformSettings(response.payload.data)
            } else {
              setPrimaryPlatformSettings([])
            }
          }),
        ]).finally(()=>{
          setRetriveDataLoading(false)
        })
      })
      .finally(()=>{
        setRetriveDataLoading(false)
      })
    }
  }, [availablePlatformId, selectedPlatformId,mainStoreData?.platform_info?.id])
 

  const generateInitialKeyFromChildrenForPlatformSetting = (fields: SETTINGS_OF_PLATFORM[]) => {
    const initialValues = {}

    fields.forEach((field) => {
      if (field.input_type === 'BOOLEAN') {
        initialValues[field.name] = false
      } else if (field.input_type === 'TEXT') {
        initialValues[field.name] = ''
      }else if (field.input_type === 'DATETIME') {
        initialValues[field.name] = null
      }else if (field.input_type === 'INTEGER') {
        initialValues[field.name] = 0
      } else {
        initialValues[field.name] = '' // Default to empty string for other types
      }

      // Recursively handle children
      if (field.children && field.children.length > 0) {
        const childValues = generateInitialKeyFromChildrenForPlatformSetting(field.children)
        Object.assign(initialValues, childValues)
      }
    })

    return initialValues
  }





  // this effect is work to set the saved setting in shown fields  
  useEffect(() => {
    setLoadInitialKeyValues(true)
     if (formFields && retrivedPlatformData?.platform_settings) {
       
       let newObj = {...formFields}
       for(let settingField in  {...retrivedPlatformData.platform_settings} ){
         if(settingField.includes('setting')){
           
           if(retrivedPlatformData.platform_settings[settingField]!==null){
             
              let isNotExistKeysForSpreadObj = typeof formFields[settingField] === 'object' ?  formFields[settingField] : {}
              newObj[settingField] = { ...isNotExistKeysForSpreadObj, ...retrivedPlatformData.platform_settings[settingField]  }
             // let newobj2 =   Object.assign(formFields, {...retrivedPlatformData.platform_settings[settingField]})
             
           }else{
           //  newObj = Object.assign( newObj , {...retrivedPlatformData.platform_settings[settingField]})
           }
           
         }else{
           if( typeof retrivedPlatformData.platform_settings[settingField] !== 'object' ){
             if(['secondary_product_identifier','main_product_identifier'].includes(settingField)){
               newObj[settingField] = retrivedPlatformData.platform_settings[settingField]
             }
           }
         }
       }
       console.log('formfields newObj',newObj);
       setValues({...newObj})
      }else{
        //set the initial values when platform_settings is null   
        setValues({...formFields})
      }
      setLoadInitialKeyValues(false)
  }, [formFields, retrivedPlatformData])
  
  console.log('fieldMappings',fieldMappings)  

  
  if ( loadingFormFields || retriveDataLoading || loadInitialKeyValues)
    return <Loader />

  return (
    <div
      key={
        {...formFields} +
        '' +
        retrivedPlatformData?.platform_settings +
        loadInitialKeyValues +
        retriveDataLoading + retriveDataLoading
      }
    >
      <div className='card'>
        <form onSubmit={handleSubmit}>
          <div className='card-header bg-white'>
            <div className='card-title'>Channel Settings</div>
            <div className='card-toolbar'>
              {/* <button type='button' disabled={isSubmitting} className='btn btn-sm btn-dark text-white'>
                Cancel
              </button> */}
              <button
                type='submit'
                disabled={isSubmitting|| settings?.length===0}
                className='btn btn-sm btn-success ms-2 fw-bold w-100px'
              >
                {isSubmitting ? 'Saving...' : 'Save'}
              </button>
            </div>
          </div>
          <div className='card-body p-2 px-sm-4'>
            <section>
              <div className='mt-5 border shadow rounded'>
                  <div className=' ribbon ribbon-start'>
                      <div className='ribbon-label bg-primary text-uppercase'>
                        Channel Connection
                      </div>
                   </div>
                   <div className='row p-2 p-sm-4 mx-auto'>
                      <div className="row my-4 my-sm-2 ">
                        <div className="col-6">
                            <p className='h4' >{retrivedPlatformData?.platform_info?.display_name} Store Channel Name:</p>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <p className='h4' >{retrivedPlatformData?.platform_info?.display_name}</p>
                            {/* <span className='ms-2' >{retrivedPlatformData?.display_name}</span> */}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-6 h4 '>Status:</div>
                        <div className='col-6'>
                          <span
                            className={`badge ${
                              retrivedPlatformData?.isConnected ? 'badge-success' : 'badge-danger'
                            }`}
                            style={{fontSize: '1.3rem', borderRadius: '6px'}}
                          >
                            {retrivedPlatformData?.isConnected ? 'Connected' : 'Not Connected'}
                          </span>
                        </div>
                      </div>
                   </div>
              </div>
            </section>
            {platformSettings?.length
              ? platformSettings.map(
                  (setting: SETTINGS_OF_PLATFORM, platformSettingIndex: number) => {
                    let settingField =
                      setting.group_code === 'ORDER'
                        ? 'order'
                        : setting.group_code === 'PRODUCT'
                        ? 'product'
                        : setting.group_code === 'INVENTORY'
                        ? 'inventory'
                        : setting.group_code === 'SHIPMENT'
                        ? 'shipment'
                        : setting.group_code === 'PRICING'
                        ? 'pricing'
                        : setting.group_code.toLocaleLowerCase()

                    return (
                      <section key={'sectionmap' + platformSettingIndex}>
                        <div className='mt-5 border shadow rounded'>
                          <div className=' ribbon ribbon-start'>
                            <div className='ribbon-label bg-primary'>
                              {setting.group_code}
                            </div>
                          </div>
                          <div className='row p-4 p-sm-4 mx-auto'>
                            {!setting.parent ? (
                              //currently parent is only considered for boolean
                              setting.input_type === 'BOOLEAN' ? (
                                <div
                                  className='row mt-4 mx-auto'
                                  key={
                                    platformSettingIndex +
                                    'parentSetting' +
                                    platformSettingIndex
                                  }
                                >
                                  <div className='col-12'>
                                    <div className=''>
                                      <CustomInput
                                        label={setting.display_name}
                                        labelClass='form-label  me-sm-10 me-0 fs-4 mb-0'
                                        message={setting.description}
                                        containerClasses='form-check form-switch form-check-solid d-sm-flex ps-0 align-items-center'
                                        inputProps={{
                                          type: 'checkbox',
                                          className:
                                            'form-check-input h-30px w-50px ms-sm-5 ms-0',
                                          name: settingField
                                            ? `${settingField}_setting.${setting.name}`
                                            : setting.name,
                                          checked: settingField && values[`${settingField}_setting`]
                                            ? values[`${settingField}_setting`][
                                                `${setting.name}`
                                              ]
                                            : false,
                                          onChange: handleChange,
                                          id: setting.group_code + setting.name,
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className='separator mt-3'></div>
                                  <div className='row p-4'>
                                    { values[`${settingField}_setting`] && values[`${settingField}_setting`][`${setting.name}`]
                                      ?
                                      <>
                                        {
                                          setting?.children?.length
                                            ? setting?.children?.map(
                                                (
                                                  childSetting1: SETTINGS_OF_PLATFORM,
                                                  childIndex1: number
                                                ) => {
                                                  return (
                                                    <DynamicChildSetting
                                                      key={
                                                        'childsetting' +
                                                        platformSettingIndex +
                                                        childIndex1
                                                      }
                                                      childItem={childSetting1}
                                                      childItemIndex={childIndex1}
                                                      settingField={settingField}
                                                      values={values}
                                                      handleChange={handleChange}
                                                      settingName={`${settingField}_setting`}
                                                      settingId={retrivedPlatformData?.platform_settings && retrivedPlatformData?.platform_settings[`${settingField}_setting`] ? retrivedPlatformData?.platform_settings[`${settingField}_setting`].id : null }
                                                      savedMappings={values[`${settingField}_setting`] && values[`${settingField}_setting`][`${settingField}_field_mappings`]?.length ?
                                                        values[`${settingField}_setting`][`${settingField}_field_mappings`] : []
                                                      }
                                                      setFieldMappings={setFieldMappings}
                                                      fieldMappings={fieldMappings}
                                                      mainStoreData={mainStoreData}
                                                      retrivedPlatformData={retrivedPlatformData}
                                                    />
                                                  )
                                                }
                                              )
                                            : null
                                        }
                                        {
                                          primaryPlatformSettings?.length && primaryPlatformSettings?.find(ite=>(ite.group_code==setting.group_code)) ? 
                                          primaryPlatformSettings?.find(ite=>(ite.group_code==setting.group_code))?.children?.map((primaryChildItem:SETTINGS_OF_PLATFORM, primaryChildItemIndex:number)=>{
                                            if(setting?.children?.length && setting?.children?.find((it)=>(it.name==primaryChildItem.name)) ){
                                              //it means setting is exist in secondary channel so no need to map particular setting form primary
                                              return null
                                            }else{
                                              if(primaryChildItem?.input_type=='BOOLEAN'){
                                                return(<div
                                                  className='col-12 col-sm-6 mt-4'
                                                  key={platformSettingIndex + 'primaryPlatformAdditionalSetting' + primaryChildItemIndex}
                                                >
                                                  <CustomInput
                                                    label={primaryChildItem.display_name}
                                                    labelClass='form-label  me-sm-10 me-0 mb-0'
                                                    message={primaryChildItem.description}
                                                    containerClasses='form-check form-switch form-check-solid '
                                                    inputProps={{
                                                      type: 'checkbox',
                                                      className: 'form-check-input h-30px w-50px ms-sm-5 ms-0',
                                                      name: settingField
                                                        ? `${settingField}_setting.${primaryChildItem.name}`
                                                        : primaryChildItem.name,
                                                      checked: settingField
                                                        ? values[`${settingField}_setting`][`${primaryChildItem.name}`]
                                                        : values[primaryChildItem.name],
                                                      onChange: handleChange,
                                                      id: primaryChildItem.group_code + primaryChildItem.name,
                                                    }}
                                                  />
                                                </div>)
                                              }else if(primaryChildItem?.input_type=='INTEGER'){
                                                return(
                                                  <CustomInput
                                                    key={platformSettingIndex + 'primaryPlatformAdditionalSetting' + primaryChildItemIndex}
                                                    label={primaryChildItem.display_name}
                                                    message={primaryChildItem.description}
                                                    inputProps={{
                                                      type: 'number',
                                                      className: 'form-control form-control-lg',
                                                      name: settingField
                                                        ? `${settingField}_setting.${primaryChildItem.name}`
                                                        : primaryChildItem.name,
                                                      value: settingField
                                                        ? values[`${settingField}_setting`][`${primaryChildItem.name}`]
                                                        : values[primaryChildItem.name],
                                                      onChange: handleChange,
                                                      id: primaryChildItem.group_code + primaryChildItem.name,
                                                    }}
                                                  />
                                                )
                                              }else if(primaryChildItem?.input_type=='DATETIME'){
                                                return(
                                                  <CustomInput
                                                    key={platformSettingIndex + 'primaryPlatformAdditionalSetting' + primaryChildItemIndex}
                                                    label={primaryChildItem.display_name}
                                                    message={primaryChildItem.description}
                                                    inputProps={{
                                                      type: 'datetime-local',
                                                      className: 'form-control form-control-lg',
                                                      name: settingField
                                                        ? `${settingField}_setting.${primaryChildItem.name}`
                                                        : primaryChildItem.name,
                                                      value: settingField
                                                        ? new Date(
                                                            values[`${settingField}_setting`][`${primaryChildItem.name}`]
                                                          )
                                                            .toISOString()
                                                            .slice(0, 16)
                                                        : values[primaryChildItem.name],
                                                      onChange: handleChange,
                                                      id: primaryChildItem.group_code + primaryChildItem.name,
                                                    }}
                                                  />
                                                )
                                              }else if(primaryChildItem?.input_type=='SELECT'){
                                                return(
                                                  <div
                                                    className='row p-4'
                                                    key={platformSettingIndex + 'primaryPlatformAdditionalSetting' + primaryChildItemIndex}
                                                  >
                                                  <div className='row p-4'>
                                                    <>
                                                      <div className='col-12 col-sm-6'>
                                                        <label className='form-label' htmlFor={primaryChildItem.name}>
                                                          {primaryChildItem.display_name}
                                                          &nbsp;&nbsp;<InfoTooltip message={primaryChildItem.description} />
                                                        </label>
                                                        <select
                                                          name={primaryChildItem.name}
                                                          value={values[`${primaryChildItem.name}`]}
                                                          onChange={handleChange}
                                                          className='form-select form-select-lg'
                                                        >
                                                          <option value=''>{primaryChildItem.placeholder}</option>
                                                          {primaryChildItem.setting_options.map((item: any) => {
                                                            return (
                                                              <option key={item.id} value={item.name}>
                                                                {item.display_name}
                                                              </option>
                                                            )
                                                          })}
                                                        </select>
                                                      </div>
                                                    </>
                                                  </div>
                                                  </div>
                                                )
                                              }else if( primaryChildItem?.input_type=='TEXT' && primaryChildItem?.showShortcodes){
                                                return (
                                                  <ShortCodeInput
                                                    key={
                                                      platformSettingIndex +
                                                      'primaryPlatformAdditionalSetting'
                                                    }
                                                    label={primaryChildItem.display_name}
                                                    message={primaryChildItem.description}
                                                    inputProps={{
                                                      type: 'text',
                                                      className: 'form-control form-control-lg',
                                                      placeholder: primaryChildItem.placeholder??'',
                                                      name: settingField
                                                        ? `${settingField}_setting.${primaryChildItem.name}`
                                                        : primaryChildItem.name,
                                                      value: settingField
                                                        ? values[`${settingField}_setting`][`${primaryChildItem.name}`]
                                                        : values[primaryChildItem.name],
                                                      onChange: handleChange,
                                                      id: primaryChildItem.group_code + primaryChildItem.name,
                                                    }}
                                                  />
                                                )
                                              }else{
                                                return (
                                                  <CustomInput
                                                    key={
                                                      platformSettingIndex +
                                                      'primaryPlatformAdditionalSetting' + primaryChildItemIndex
                                                    }
                                                    label={primaryChildItem.display_name}
                                                    message={primaryChildItem.description}
                                                    inputProps={{
                                                      type: 'text',
                                                      className: 'form-control form-control-lg',
                                                      name: settingField
                                                        ? `${settingField}_setting.${primaryChildItem.name}`
                                                        : primaryChildItem.name,
                                                      value: settingField
                                                        ? values[`${settingField}_setting`][`${primaryChildItem.name}`]
                                                        : values[primaryChildItem.name],
                                                      onChange: handleChange,
                                                      id: primaryChildItem.group_code + primaryChildItem.name,
                                                    }}
                                                  />
                                                )
                                              }
                                            }
                                          })
                                            : null
                                        }
                                      </> 
                                      : null
                                    }
                                  </div>
                                  {setting?.field_mappings?.length ? (
                                    <div className=''>
                                      {values[`${settingField}_setting`] && values[`${settingField}_setting`][
                                        `${setting.name}`
                                      ] ? setting.field_mappings.map((field_mapping:FIELD_MAPPING_TYPE, index)=>{
                                        return (
                                        <MappingInput
                                          key={field_mapping.display_name+index+isMappingChanged+''}
                                          selectedPlatformId={Number(selectedPlatformId)}
                                          sourceSide={mainStoreData?.id!}
                                          destinationSide={Number(selectedPlatformId)!}
                                          sourcePlatform={mainStoreData?.platform_info?.display_name}
                                          destinationPlatform={retrivedPlatformData?.platform_info?.display_name}
                                          isMappingVisible={
                                            values[`${settingField}_setting`] &&  values[`${settingField}_setting`][
                                              `${setting.name}`
                                            ]
                                              ? values[`${settingField}_setting`][
                                                  `${setting.name}`
                                                ]
                                              : false
                                          }
                                          handleChange={handleChange}
                                          field_mapping={field_mapping}
                                          savedMapping={ values[`${settingField}_setting`] && values[`${settingField}_setting`][`${settingField}_field_mappings`]?.length ?
                                                values[`${settingField}_setting`][`${settingField}_field_mappings`].find((it)=>(it?.destination_field?.api_code===field_mapping.field_code)) : null
                                          }
                                          settingName={`${settingField}_setting`}
                                          settingId={ retrivedPlatformData?.platform_settings && retrivedPlatformData?.platform_settings[`${settingField}_setting`] ? retrivedPlatformData?.platform_settings[`${settingField}_setting`].id : null }
                                          setFieldMappings={setFieldMappings}
                                          fieldMappings={fieldMappings}
                                        />
                                      ) 
                                      })
                                        : null}
                                    </div>
                                  ) : null}
                                  { values[`${settingField}_setting`] &&
                                     values[`${settingField}_setting`][
                                      `${setting.name}`
                                    ] && primaryPlatformSettings.length ? // checking the length of primary pltfm setting
                                        primaryPlatformSettings.find(it=>it.group_code===setting.group_code)?.field_mappings.length ?
                                        primaryPlatformSettings.find(it=>it.group_code===setting.group_code)?.field_mappings.map((field_mapping:FIELD_MAPPING_TYPE, index)=>{
                                          return (<>
                                              <MappingInput
                                                key={field_mapping.display_name+index+isMappingChanged+''}
                                                selectedPlatformId={Number(selectedPlatformId)}
                                                sourceSide={Number(selectedPlatformId)}
                                                destinationSide={Number(mainStoreData?.id)}
                                                sourcePlatform={retrivedPlatformData?.platform_info?.display_name}
                                                destinationPlatform={mainStoreData?.platform_info?.display_name}
                                                isMappingVisible={values[`${settingField}_setting`][`${setting.name}`]}
                                                handleChange={handleChange}
                                                field_mapping={field_mapping}
                                                savedMapping={values[`${settingField}_setting`][`${settingField}_field_mappings`]?.length ?
                                                  values[`${settingField}_setting`][`${settingField}_field_mappings`].find((it)=>(it?.destination_field?.api_code===field_mapping.field_code)) : null
                                                }
                                                settingName={`${settingField}_setting`}
                                                settingId={retrivedPlatformData?.platform_settings[`${settingField}_setting`] ? retrivedPlatformData?.platform_settings[`${settingField}_setting`].id : null }
                                                setFieldMappings={setFieldMappings}
                                                fieldMappings={fieldMappings}
                                              />
                                          </>)
                                        })
                                            : 
                                          null
                                      : null
                                  }
                                </div>
                              ) : setting.input_type === 'SELECT' ? (
                                <div
                                  className='row p-4'
                                  key={
                                    platformSettingIndex +
                                    'parentSetting' +
                                    platformSettingIndex
                                  }
                                >
                                  <div className='row p-4'>
                                    <>
                                      <div className='col-12 col-sm-6'>
                                        <label className='form-label' htmlFor={setting.name}>
                                          {setting.display_name}
                                          &nbsp;&nbsp;<InfoTooltip message={setting.description} />
                                        </label>
                                        <select
                                          name={setting.name}
                                          value={values[`${setting.name}`]}
                                          onChange={handleChange}
                                          className='form-select form-select-lg'
                                        >
                                          <option value=''>{setting.placeholder}</option>
                                          {setting.setting_options.map((item: any) => {
                                            return (
                                              <option key={item.id} value={item.name}>
                                                {item.display_name}
                                              </option>
                                            )
                                          })}
                                        </select>
                                      </div>
                                      <div className='col-12 col-sm-6'>
                                        <label className='form-label' htmlFor={primaryPlatformSettings?.find(it=>(it.group_code===setting.group_code))?.name}>
                                          {primaryPlatformSettings?.find(it=>(it.group_code===setting.group_code))?.display_name}
                                          &nbsp;&nbsp;<InfoTooltip message={primaryPlatformSettings.find(it=>(it.group_code===setting.group_code))?.description} />
                                        </label>
                                        <select
                                          name={primaryPlatformSettings?.find(it=>(it.group_code===setting.group_code))?.name}
                                          value={values[`${primaryPlatformSettings?.find(it=>(it.group_code===setting.group_code))?.name}`]}
                                          onChange={handleChange}
                                          className='form-select form-select-lg'
                                        >
                                          <option value=''>{primaryPlatformSettings?.find(it=>(it.group_code===setting.group_code))?.placeholder}</option>
                                          {primaryPlatformSettings?.find(it=>(it.group_code===setting.group_code))?.setting_options?.map((item: any) => {
                                            return (
                                              <option key={item.id} value={item.name}>
                                                {item.display_name}
                                              </option>
                                            )
                                          })}
                                        </select>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              ) : 
                              setting.input_type === 'INTEGER' ? (
                                <CustomInput
                                  key={
                                    platformSettingIndex +
                                    'parentSetting' +
                                    platformSettingIndex
                                  }
                                  label={setting.display_name}
                                  message={setting.description}
                                  inputProps={{
                                    type: 'number',
                                    className: 'form-control form-control-lg',
                                    placeholder:setting.placeholder ?? '',
                                    name: settingField
                                      ? `${settingField}_setting.${setting.name}`
                                      : setting.name,
                                    value: settingField && values[`${settingField}_setting`]
                                      ? values[`${settingField}_setting`][`${setting.name}`]
                                      : values[setting.name],
                                    onChange: handleChange,
                                    id: setting.group_code + setting.name,
                                  }}
                                />
                              ) : setting.input_type === 'DATETIME' ? (
                                <CustomInput
                                  key={
                                    platformSettingIndex +
                                    'parentSetting' +
                                    platformSettingIndex
                                  }
                                  label={setting.display_name}
                                  message={setting.description}
                                  inputProps={{
                                    type: 'datetime-local',
                                    className: 'form-control form-control-lg',
                                    name: settingField
                                      ? `${settingField}_setting.${setting.name}`
                                      : setting.name,
                                    value: settingField && values[`${settingField}_setting`]
                                      ? new Date(
                                          values[`${settingField}_setting`][`${setting.name}`]
                                        )
                                          .toISOString()
                                          .slice(0, 16)
                                      : values[setting.name],
                                    onChange: handleChange,
                                    id: setting.group_code + setting.name,
                                  }}
                                />
                              ) : //default is text field
                              setting.showShortcodes ? (
                                <>
                                  <ShortCodeInput
                                    key={
                                      platformSettingIndex +
                                      'parentSetting' +
                                      platformSettingIndex
                                    }
                                    label={setting.display_name}
                                    message={setting.description}
                                    inputProps={{
                                      type: 'text',
                                      className: 'form-control form-control-lg',
                                      placeholder:setting.placeholder ?? '',
                                      name: settingField
                                        ? `${settingField}_setting.${setting.name}`
                                        : setting.name,
                                      value: settingField && values[`${settingField}_setting`]
                                        ? values[`${settingField}_setting`][`${setting.name}`]
                                        : values[setting.name],
                                      onChange: handleChange,
                                      id: setting.group_code + setting.name,
                                    }}
                                  />
                                </>
                              ) : (
                                <CustomInput
                                  key={
                                    platformSettingIndex +
                                    'parentSetting' +
                                    platformSettingIndex
                                  }
                                  label={setting.display_name}
                                  message={setting.description}
                                  inputProps={{
                                    type: 'text',
                                    className: 'form-control form-control-lg',
                                    placeholder:setting.placeholder ?? '',
                                    name: settingField
                                      ? `${settingField}_setting.${setting.name}`
                                      : setting.name,
                                    value: settingField && values[`${settingField}_setting`]
                                      ? values[`${settingField}_setting`][`${setting.name}`]
                                      : values[setting.name],
                                    onChange: handleChange,
                                    id: setting.group_code + setting.name,
                                  }}
                                />
                              )
                            ) : //rest input type of parent...

                            //under this part the rest settings come those are not parent setting
                            setting.input_type === 'BOOLEAN' ? (
                              <div
                                className='col-12 col-sm-6 mt-4'
                                key={
                                  platformSettingIndex +
                                  'parentSetting' +
                                  platformSettingIndex
                                }
                              >
                                <CustomInput
                                  label={setting.display_name}
                                  labelClass='form-label  me-sm-10 me-0 mb-0'
                                  message={setting.description}
                                  containerClasses='form-check form-switch form-check-solid '
                                  inputProps={{
                                    type: 'checkbox',
                                    className: 'form-check-input h-30px w-50px ms-sm-5 ms-0',
                                    name: settingField
                                      ? `${settingField}_setting.${setting.name}`
                                      : setting.name,
                                    checked: settingField
                                      ? values[`${settingField}_setting`][`${setting.name}`]
                                      : values[setting.name],
                                    onChange: handleChange,
                                    id: setting.group_code + setting.name,
                                  }}
                                />
                              </div>
                            ) :  setting.input_type === 'SELECT' ? (
                              <div
                                className='row p-4'
                                key={
                                  platformSettingIndex +
                                  'parentSetting' +
                                  platformSettingIndex
                                }
                              >
                                <div className='row p-4'>
                                  <>
                                    <div className='col-12 col-sm-6'>
                                      <label className='form-label' htmlFor={setting.name}>
                                        {setting.display_name}
                                        &nbsp;&nbsp;<InfoTooltip message={setting.description} />
                                      </label>
                                      <select
                                        name={setting.name}
                                        value={values[`${setting.name}`]}
                                        onChange={handleChange}
                                        className='form-select form-select-lg'
                                      >
                                        <option value=''>{setting.placeholder}</option>
                                        {setting.setting_options.map((item: any) => {
                                          return (
                                            <option key={item.id} value={item.name}>
                                              {item.display_name}
                                            </option>
                                          )
                                        })}
                                      </select>
                                    </div>
                                  </>
                                </div>
                              </div>
                            ) :
                            setting.input_type === 'INTEGER' ? (
                              <CustomInput
                                key={
                                  platformSettingIndex +
                                  'parentSetting' +
                                  platformSettingIndex
                                }
                                label={setting.display_name}
                                message={setting.description}
                                inputProps={{
                                  type: 'number',
                                  className: 'form-control form-control-lg',
                                  name: settingField
                                    ? `${settingField}_setting.${setting.name}`
                                    : setting.name,
                                  value: settingField
                                    ? values[`${settingField}_setting`][`${setting.name}`]
                                    : values[setting.name],
                                  onChange: handleChange,
                                  id: setting.group_code + setting.name,
                                }}
                              />
                            ) : setting.input_type === 'DATETIME' ? (
                              <CustomInput
                                key={
                                  platformSettingIndex +
                                  'parentSetting' +
                                  platformSettingIndex
                                }
                                label={setting.display_name}
                                message={setting.description}
                                inputProps={{
                                  type: 'datetime-local',
                                  className: 'form-control form-control-lg',
                                  name: settingField
                                    ? `${settingField}_setting.${setting.name}`
                                    : setting.name,
                                  value: settingField
                                    ? new Date(
                                        values[`${settingField}_setting`][`${setting.name}`]
                                      )
                                        .toISOString()
                                        .slice(0, 16)
                                    : values[setting.name],
                                  onChange: handleChange,
                                  id: setting.group_code + setting.name,
                                }}
                              />
                            ) : //default is text field
                            setting.showShortcodes ? (
                              <>
                                <ShortCodeInput
                                  key={
                                    platformSettingIndex +
                                    'parentSetting' +
                                    platformSettingIndex
                                  }
                                  label={setting.display_name}
                                  message={setting.description}
                                  inputProps={{
                                    type: 'text',
                                    className: 'form-control form-control-lg',
                                    name: settingField
                                      ? `${settingField}_setting.${setting.name}`
                                      : setting.name,
                                    value: settingField
                                      ? values[`${settingField}_setting`][`${setting.name}`]
                                      : values[setting.name],
                                    onChange: handleChange,
                                    id: setting.group_code + setting.name,
                                  }}
                                />
                              </>
                            ) : (
                              <CustomInput
                                key={
                                  platformSettingIndex +
                                  'parentSetting' +
                                  platformSettingIndex
                                }
                                label={setting.display_name}
                                message={setting.description}
                                inputProps={{
                                  type: 'text',
                                  className: 'form-control form-control-lg',
                                  name: settingField
                                    ? `${settingField}_setting.${setting.name}`
                                    : setting.name,
                                  value: settingField
                                    ? values[`${settingField}_setting`][`${setting.name}`]
                                    : values[setting.name],
                                  onChange: handleChange,
                                  id: setting.group_code + setting.name,
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </section>
                    )
                  }
                )
              : (
                <div className='card-body mt-8' >
                  <p className='h3' >No settings found</p>
                </div>
              )}
          </div>
        </form>
      </div>
    </div>
  )
}

export default DynamicPlatformSetting
