import React, {ChangeEventHandler, FC, useEffect, useState} from 'react'
import { getAuthRequirementSelectOptionsAsync } from 'src/redux/actions/platformActions'
import { useAppDispatch } from 'src/redux/storeHooks'

interface InputProps {
  id: number
  name: string
  display_name: string
  input_type?: string
  inputValue: string
  onDataChange: (ev: React.ChangeEvent<HTMLSelectElement>) => void
  
}

const AuthRequirementSelectInput: FC<InputProps> = ({
  display_name,
  id,
  inputValue,
  name,
  onDataChange,
  input_type,
  
}) => {

  const [options, setOptions] = useState<{label: string; value: string}[]>([])
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(id){
      setLoading(true)
      dispatch(getAuthRequirementSelectOptionsAsync({auth_field_id:id }))
      .then((res)=>{
        if(res.payload?.results){
          let customizedOptions = res.payload?.results?.map((it)=>({label:it?.display_name, value:it?.id}))
          if(customizedOptions){
            setOptions(customizedOptions)
          }else{
            setOptions([])
          }
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching auth requirement select options:', error)
        setLoading(false)
      })
    }
  }, [id])
  

  return (
    <div className='row'>
      <div className='col-md-3 col-6' >
        <label className='form-label'>{display_name}</label>
      </div>
      <div className='col-sm-6 col-12' >
        <select
          value={inputValue}
          onChange={onDataChange}
          className='form-control form-control-solid'
          disabled={loading}
        >
          <option value=''>{display_name + `${loading? ' ...': ''}` } </option>
          {options.length &&
            options.map((option) => {
              return <option value={option.value}>{option.label}</option>
            })}
        </select>
      </div>
    </div>
  )
}

export default AuthRequirementSelectInput
