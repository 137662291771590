import React, {FC, useCallback, useEffect, useState} from 'react'
import Select, {GroupBase, Options} from 'react-select'
import {CATEGORY_ATTRIBUTE_FOR_SEARCH} from 'src/interfaces/Template.interface'
import {getPlatformCategoryAttributeDataAsync} from 'src/redux/actions/templateAndPricingActions'
import {useAppDispatch} from 'src/redux/storeHooks'

interface Attribute {
  id: number
  api_name: string
  api_id: string
  api_code: string
  updated_at: string
  created_at: string
  platform_category_attribute: number
}

interface Category {
  id: number
  api_name: string
  api_id: string
  api_code: string
  updated_at: string
  created_at: string
  parent_data: number
  platform: number
  platform_field: number
}

type CategoryAttribute = {
  id: number
  category: Category
  api_id: string
  api_code: string
  api_name: string
  display_name: string
  isRequired: boolean
  input_type: string
  description: string
}

type CategoryAttributeForSelectPropsType = {
  attribute: CategoryAttribute
  handleValueChange:  (attributeId: number, value: string) => void
  selectedValues:  string | null;
}

const CategoryAttributeForMultiSelect: FC<CategoryAttributeForSelectPropsType> = ({
  attribute,
  handleValueChange,
  selectedValues,
}) => {
  const [loading, setLoading] = useState(false)
  const [searchOptions, setSearchOptions] = useState<{ value: number; label: string }[]>([])
  const [nextPage, setNextPage] = useState<number>(1)
  const [totalCount, setTotalCount] = useState(0)
  const [search, setSearch] = useState('')
  

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (attribute.id) {
      setLoading(true)
      dispatch(
        getPlatformCategoryAttributeDataAsync({category_attribute_id: attribute.id, paramsData: {}})
      )
        .then((res) => {
          
          const options = res.payload?.results?.map((item: CATEGORY_ATTRIBUTE_FOR_SEARCH) => ({
            value: item.id, // Ensure `id` or another unique identifier is available
            label: item.api_name, // Ensure `api_name` is the desired display label
          }))
          if(res.payload?.next){
            setNextPage(2)
          }
          if(res.payload?.count){
            setTotalCount(res.payload?.count)
          }
          setSearchOptions(options)
          setLoading(false)
        })
        .catch((er) => {
          setLoading(false)
        })
    }
  }, [attribute])

  const fetchSearchOptions = useCallback(
    async (inputValue: string,) => {
        
        setLoading(true)
        try {
            const res = await dispatch(getPlatformCategoryAttributeDataAsync({ category_attribute_id: attribute.id, paramsData: { search_string : inputValue, page:1, limit:100 } }))
            const { results, next, count } = res.payload
            const options = results.map((item: CATEGORY_ATTRIBUTE_FOR_SEARCH) => ({
                value: item.id,
                label: item.api_name,
            }))

            setSearchOptions(options)
            if(next){
              setNextPage(nextPage+1)
            }
            if(count){
              setTotalCount(count)
            }
            setLoading(false)
        } catch (error) {
            console.error('Error fetching options:', error)
            setNextPage(1)
            setLoading(false)
        } finally {
            setLoading(false)
            setNextPage(1)
        }
    },
    [attribute.id, dispatch]
)

const handleInputChange = (inputValue: string) => {
    fetchSearchOptions(inputValue)
    setSearch(inputValue)
}

 
  return (
    <div>
      <Select
        isMulti={true}
        required={attribute.isRequired}
        isLoading={loading}
        value={searchOptions.filter(option => selectedValues?.split(',')?.includes(option.value+'')) || []}
        placeholder={'Select ' + attribute.display_name}
        isSearchable
        onInputChange={(inputvalue, actionmeta)=>{
          if(actionmeta.action=='input-change'){
            handleInputChange(inputvalue)
          }
        }}
        onMenuScrollToBottom={async ()=>{
          if(nextPage<totalCount/100){
            setLoading(true)
            const res = await dispatch(getPlatformCategoryAttributeDataAsync({ category_attribute_id: attribute.id, paramsData: { search_string : search, page:1, limit:100 } }))
            const { results, next, count } = res.payload
            const options = results.map((item: CATEGORY_ATTRIBUTE_FOR_SEARCH) => ({
                value: item.id,
                label: item.api_name,
            }))

            setSearchOptions(options)
            if(next){
              setNextPage(nextPage+1)
            }
            if(count){
              setTotalCount(count)
            }
            setLoading(false)
          }
        }}
        closeMenuOnSelect={false}
        options={searchOptions}
        styles={{
          control: (base, state) => ({
            ...base,
            minHeight: state.isFocused ? '30px' : 'auto',
          }),
          valueContainer: (base) => ({
            ...base,
            maxHeight: '60px',
            overflowY: 'auto',
          }),
          indicatorSeparator: (base) => ({
            ...base,
            display: 'none',
          }),
          dropdownIndicator: (base) => ({
            ...base,
            padding: '4px',
          }),
          clearIndicator: (base) => ({
            ...base,
            padding: '4px',
          }),
        }}
        onChange={(ev) => {
          let values = ev.map((item)=>item.value).join(',')
          handleValueChange(attribute.id, values)
        }}
      />
    </div>
  )
}

export default CategoryAttributeForMultiSelect
