import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from 'src/marketConnect/helpers'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Platforms from '../../../listing/Platforms'
import {Spinner} from 'react-bootstrap'
import Loader from 'src/utils/Loader'
import {Platform, PlatformDataType} from 'src/interfaces/Platform.interface'
import useAppToast from 'src/hooks/useAppToast'
import {useNavigate} from 'react-router-dom'
import {
  connectPlatformAsync,
  fetchConnectedPlatformAsync,
  fetchPlatformsAsync,
} from 'src/redux/actions/platformActions'
import ConnectPlatformModal from '../ConnectPlatformModal'
import PlatformCard from '../PlatformCard'
import clsx from 'clsx'
import { setStepsData } from 'src/redux/slices/tourPopupSlice'

type StepStatus = 'active' | 'inactive' | 'finished'

type TourSteps = {
  stepOne: StepStatus
  stepTwo: StepStatus
  stepThree: StepStatus
}

type TourStep1PropType = {
  // setStepsData: React.Dispatch<React.SetStateAction<TourSteps>>
  setSelectedPlatformId: React.Dispatch<React.SetStateAction<string | undefined>>
}

const TourStep1: FC<TourStep1PropType> = ({ setSelectedPlatformId}) => {
  const {fetchedConnectedPlatformData, fetchedConnectedPlatformLoading} = useAppSelector(
    (state) => state.fetchConnectedPlatform
  )
  
  const {fetchedUserAccountData} = useAppSelector((state) => state.userAccountData)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {platformData} = useAppSelector((state) => state.platform)
  const {stepsData} = useAppSelector((state) => state.tourPopup)
  const [showConnectPlatformModal, setShowConnectPlatformModal] = useState<boolean>(false)
  const [selectedPlatform, setSelectedPlatform] = useState<Platform | null>(null)
  const [ecommercePlatform, setEcommercePlatform] = useState<any>([])
  const [showSettingModal, setShowSettingModal] = useState<boolean>(false)
  const [modalData, setModalData] = useState<PlatformDataType>()
  const [connectingPlatformId, setConnectingPlatformId] = useState<{
    platformId: number | string | null
    isConnecting: boolean
  }>({isConnecting: false, platformId: null})
   

  useEffect(() => {
    if (fetchedConnectedPlatformData.length === 0) {
      let newPlatformData = platformData?.filter((ele) =>
        ['ECOMMERCE', 'SHIPPING_PLATFORM'].includes(ele.type)
      )
      setEcommercePlatform(newPlatformData)
    } else {
      setEcommercePlatform(platformData)
    }
  }, [])

  

  return (
    <>
    { fetchedConnectedPlatformData?.length ?
     <div className="alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row p-3 d-flex justify-content-between">
        <div className='d-flex' >
          <span className="svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0">
              <img src={fetchedConnectedPlatformData[0]?.platform_info?.logo_ref!} className="h-40px" />
          </span>
          <div className="d-flex flex-column text-light pe-0 pe-sm-10">
            <h5 className="mb-1 text-success">{fetchedConnectedPlatformData[0] ? fetchedConnectedPlatformData[0]?.display_name : '' }</h5>
            <span className='text-success' >{ fetchedConnectedPlatformData[0]?.isMainStore ?  'Primary Channel Connected' : 'Secondary Channel Conneted' }</span>
          </div>
        </div>
          <span className="svg-icon svg-icon-2x svg-icon-light">
            <KTSVG path="media/icons/duotune/general/gen043.svg" className="svg-icon-success svg-icon-2hx" />
          </span>
      </div> 
    : null }
      <div>
        <div className='d-flex align-items-center justify-content-between' >
          <h4>
            {connectingPlatformId.isConnecting
              ? ''
              : 'Available Primary Channels'}
          </h4>
          {
            ['COMMERCIUM','WOOCOMMERCE'].includes(fetchedUserAccountData?.source!) ?
            <button type='button' className='btn btn-sm btn-primary fs-6 fw-bold' 
              onClick={()=>{
                  dispatch(setStepsData({stepData:stepsData,visible:false}))
                  navigate('/pricing')
                }} > <i className={'bi bi-coin fs-4 me-2'}></i> Manage Plan</button>
            : null
          }
        </div>
      </div>
      <div className='py-4'>
        <div
          className={clsx(
            'row g-3 gx-2 g-xl-8 ',
            connectingPlatformId.isConnecting ? 'justify-content-center' : ''
          )}
        >
          {ecommercePlatform?.length > 0 &&
            ecommercePlatform.map((item: Platform) => {
              if (connectingPlatformId.isConnecting) {
                if (connectingPlatformId.platformId === item.id) {
                  return (
                    <PlatformCard
                      key={item.id}
                      data={item}
                      onClickConnectTop={setConnectingPlatformId}
                      // setStepsData={setStepsData}
                      setSelectedPlatformId={setSelectedPlatformId}
                    />
                  )
                }
              } else {
                return (
                  <PlatformCard
                    key={item.id}
                    data={item}
                    onClickConnectTop={setConnectingPlatformId}
                    // setStepsData={setStepsData}
                    setSelectedPlatformId={setSelectedPlatformId}
                  />
                )
              }
            })}
        </div>
      </div>
      {/* { showConnectPlatformModal ? 
        <ConnectPlatformModal
        isVisible={showConnectPlatformModal}
        closeModal={handleCloseModal}
        data={selectedPlatform}
        onDataReceived={handleDataReceived} // Pass the data receiving function
      /> : null } */}
    </>
  )
}

export default TourStep1
