import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import {Button} from 'react-bootstrap' // Added import for Button
import { useAppSelector } from 'src/redux/storeHooks'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const SidebarMenuItemForExternalLink: FC<Props & WithChildren> = ({
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config
  const { fetchedUserAccountData } = useAppSelector(state=>state.userAccountData)

  return (
    <div className='menu-item'>
      <a
        className={clsx('menu-link without-sub', {active: isActive})}
        href={to}
        target='_blank'
        style={{textDecoration: 'none'
          ,pointerEvents: (fetchedUserAccountData?.is_subscribed && fetchedUserAccountData?.isOnboardingProcessCompleted) ? 'auto' : 'none',
        }}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon '>
            {' '}
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className={ (fetchedUserAccountData?.is_subscribed && fetchedUserAccountData?.isOnboardingProcessCompleted) ? 'menu-title' : 'text-secondary' } style={{fontSize: '16px', fontWeight: 'bold'}}>
          {title}{' '}
        </span>
      </a>
    </div>
  )
}

export default SidebarMenuItemForExternalLink
