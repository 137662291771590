import React, {FC, useState} from 'react'

type Platform = {
  id: string | number
  display_name: string
  logo_ref: string
}

type PlatformCardPropType = {
  data: Platform
  onClickConnectTop: React.Dispatch<
    React.SetStateAction<{platformId: number | string | null; isConnecting: boolean}>
  >
}

const Card: FC<PlatformCardPropType> = ({data, onClickConnectTop}) => {
  const [showConnectPlatformModal, setShowConnectPlatformModal] = useState<boolean>(false)

  const handleCloseModal = () => {
    setShowConnectPlatformModal(false)
    onClickConnectTop({platformId: null, isConnecting: false})
  }

  const onClickConnect = () => {
    setShowConnectPlatformModal(true)
    onClickConnectTop({platformId: data.id, isConnecting: true})
  }

  return (
    <div className={`col-sm-6 col-lg-6 col-xl-4 col-md-6 m-0 mt-3`}>
      <div className='card position-relative mb-md-3 mb-2 mb-xl-8 cursor-pointer h-100 p-3'>
        {showConnectPlatformModal && (
          <div className='d-flex justify-content-end px-4'>
            <a
              target='_blank'
              href='https://commercium.tawk.help/'
              className='btn btn-link text-primary fs-6 fw-bold'
              rel='noopener noreferrer'
            >
              Need Help?
            </a>
          </div>
        )}
        <div className='card-body align-items-center p-3 px-7'>
          {/* Platform Information */}
          <div className='d-flex row'>
            <div className='col-xxl-9 col-md-8 col-9'>
              <div className='d-flex flex-column flex-grow-1 py-2 py-lg-9 me-2 w-100'>
                <div className='fw-bolder fs-4 fs-md-2 mb-2'>{data.display_name}</div>
              </div>
            </div>
            <div className='col-xxl-3 col-md-4 col-3 d-flex justify-content-end'>
              <div className='w-100px h-50px'>
                <img
                  src={data.logo_ref}
                  alt={data.display_name}
                  style={{width: '100%', height: '100%', objectFit: 'contain'}}
                />
              </div>
            </div>
          </div>
          {/* Connect Modal */}
          {showConnectPlatformModal && (
            <div className='modal position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center'>
              <div className='modal-content bg-white shadow p-4 rounded'>
                <h5>Connecting to {data.display_name}</h5>
                <button onClick={handleCloseModal} className='btn btn-secondary btn-sm mt-3'>
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
        {/* Connect Button */}
        {!showConnectPlatformModal && (
          <div className='d-flex justify-content-end my-4'>
            <button className='btn btn-primary btn-sm me-4' onClick={onClickConnect}>
              Connect
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default Card
