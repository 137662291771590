import React from 'react'

const OrdersProductList = ({line_items, order}) => {
  const calculateSubtotal = () => {
    const subtotal:number = line_items.reduce((sum:number, item) => {
      const totalPrice = Number(item?.total_price?.value)
      console.log(`Item total price: ${totalPrice}`)
      return sum + totalPrice
    }, 0)
    console.log(`Subtotal: ${subtotal}`)
    return subtotal
  }
  // const calculateTotalDiscount = () => {
  //   const totaldiscount = line_items.reduce((sum, item) => {
  //     const totaldiscountPrice = Number(item?.discount_price?.value)
  //     console.log(`Item total discount: ${totaldiscountPrice}`)
  //     return sum + totaldiscountPrice
  //   }, 0)
  //   console.log(`totaldiscount of line items: ${totaldiscount}`)
  //   return totaldiscount
  // }
  const calculateTotalDiscount = () => {
    const totaldiscount = line_items.reduce((sum, item) => {
      const totaldiscountPrice = Number(item?.discount_price?.value || 0);
      if (!isNaN(totaldiscountPrice)) {
        console.log(`Item total discount: ${totaldiscountPrice}`);
        return sum + totaldiscountPrice;
      } else {
        console.log(`Item total discount is not a number`);
        return sum;
      }
    }, 0);
    console.log(`totaldiscount of line items: ${totaldiscount}`);
    return totaldiscount;
  }


  // Ensure line_items and order are available before rendering
  if (!line_items || !order) {
    return null
  }
  return (
    <div className='card card-flush shadow flex-row-fluid overflow-hidden'>
      <div className='card-header'>
        <div className='card-title'>
          {/* <h2>Order #{order.id}</h2> */}
          <h2 className='card-title'>Line Items</h2>
        </div>
      </div>
      <div className='card-body pt-0'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5 mb-0'>
            <thead>
              <tr className='text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-175px'>Product</th>
                <th className='min-w-100px '>SKU</th>
                <th className='min-w-100px '>Barcode</th>
                <th className='min-w-70px text-center '>Qty</th>
                <th className='min-w-100px text-end'>Unit Price</th>
                <th className='min-w-100px text-end'>Discount</th>

                <th className='min-w-100px text-end'>Total</th>
              </tr>
            </thead>
            <tbody className='fw-semibold text-gray-600'>
              {line_items.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className='d-flex align-items-center p-2'>
                      {/* Replace with your image loading logic */}
                      <a
                        href={'#'}
                        className='symbol symbol-50px'
                      >
                        {/* <span className='symbol-label' style={{backgroundImage: 'none'}}>
                          <img
                            src='/media/products/1.png'
                            alt='Product Image'
                            style={{width: '50px', height: '50px'}}
                          />
                        </span> */}
                        <span className='symbol-label m-3' style={{backgroundImage: 'none'}}>
                          <img
                            src={
                              item?.platform_product?.image_set &&
                              item.platform_product.image_set.length > 0
                                ? item.platform_product.image_set[0].image_url // Assuming each image object in image_set has an image_url property
                                : 'https://appconnect-images.s3.us-east-2.amazonaws.com/No_Image_Available.jpg'
                            }
                            alt='Image'
                            style={{width: '50px', height: '50px'}}
                          />
                        </span>
                      </a>
                      <div className='ms-5'>
                        <a
                          href='#'
                          className='fw-bold text-gray-600 text-hover-primary'
                        >
                          {item.name}
                        </a>
                        <div className='fs-7 text-muted'>Delivery Date: {item.delivery_date}</div>
                      </div>
                    </div>
                  </td>
                  <td className=''>{item?.sku}</td>
                  <td className=''>{item?.platform_product_variant?.barcode}</td>
                  <td className='text-center'>{item?.quantity}</td>
                  <td className='text-end'>{item?.price?.value}</td>
                  <td className='text-end'>{item?.discount_price?.value || 0}</td> 
                  <td className='text-end'>{item?.total_price?.value}</td>
                </tr>
              ))}
              {/* Subtotal,Discount, VAT, Shipping Rate, and Grand Total */}
              <tr>
                <td colSpan={5} className='text-end'>
                  Subtotal
                </td>
                <td className='text-end'>
                  {calculateSubtotal().toFixed(2)}{' '}{order?.total_price?.currency_iso}
                </td>
              </tr>
              <tr>
                <td colSpan={5} className='text-end'>
                  Discount
                </td>
                <td className='text-end'>
                {calculateTotalDiscount() - (order?.discount_price ? order?.discount_price.value : 0)}{' '}
    {order?.discount_price ? order?.discount_price.currency_iso : ''}

                  
                </td>
              </tr>
              <tr>
                <td colSpan={5} className='text-end'>
                  Tax (0%)
                </td>
                <td className='text-end'>
                {(Math.round(order?.tax_price?.value * 100) / 100 || 0).toFixed(2)} {order?.tax_price?.currency_iso || ''}

                </td>
              </tr>
              <tr>
                <td colSpan={5} className='text-end'>
                  Shipping Rate
                </td>
                <td className='text-end'>
                  {order?.shipping_price ? order?.shipping_price.value : 0}{' '}
                  {order?.shipping_price?.currency_iso}
                </td>
              </tr>
              <tr>
                <td colSpan={5} className='fs-3 text-gray-900 text-end'>
                  Grand Total
                </td>
                <td colSpan={2} className='fs-3 text-gray-900 text-center'>
                  {order?.total_price?.value} {order?.total_price?.currency_iso}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default OrdersProductList
