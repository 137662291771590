import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from 'src/marketConnect/helpers'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Platforms from '../../../listing/Platforms'
import {Spinner} from 'react-bootstrap'
import Loader from 'src/utils/Loader'
import {Platform, PlatformDataType} from 'src/interfaces/Platform.interface'
import useAppToast from 'src/hooks/useAppToast'
import {useNavigate} from 'react-router-dom'
import {
  connectPlatformAsync,
  fetchConnectedPlatformAsync,
  fetchPlatformsAsync,
} from 'src/redux/actions/platformActions'
import ConnectPlatformModal from '../ConnectPlatformModal'
import PlatformCard from '../PlatformCard'
import clsx from 'clsx'
import { setStepsData } from 'src/redux/slices/tourPopupSlice'

type StepStatus = 'active' | 'inactive' | 'finished'

type TourSteps = {
  stepOne: StepStatus
  stepTwo: StepStatus
  stepThree: StepStatus
}

type TourStep1PropType = {
  // setStepsData: React.Dispatch<React.SetStateAction<TourSteps>>
  setSelectedPlatformId: React.Dispatch<React.SetStateAction<string | undefined>>
}

const TourStep2: FC<TourStep1PropType> = ({ setSelectedPlatformId}) => {
  const {fetchedConnectedPlatformData, fetchedConnectedPlatformLoading, mainStoreData} = useAppSelector(
    (state) => state.fetchConnectedPlatform
  )
  const {shopifyConnectFirstTimeMessageShow, firstTimeMessage} = useAppSelector(
    (state) => state.auth
  )
  const {fetchedUserAccountData} = useAppSelector((state) => state.userAccountData)
  const {platformData} = useAppSelector((state) => state.platform)
  const {stepsData }= useAppSelector(state=>state.tourPopup)
  const {connectedPlatformData} = useAppSelector((state) => state.connectedPlatform)
  const [showConnectPlatformModal, setShowConnectPlatformModal] = useState<boolean>(false)
  const [selectedPlatform, setSelectedPlatform] = useState<Platform | null>(null)
  const [ecommercePlatform, setEcommercePlatform] = useState<any>([])
  const [showSettingModal, setShowSettingModal] = useState<boolean>(false)
  const [modalData, setModalData] = useState<PlatformDataType>()
  const [connectingPlatformId, setConnectingPlatformId] = useState<{
    platformId: number | string | null
    isConnecting: boolean
  }>({isConnecting: false, platformId: null})
  const {successToast, errorToast} = useAppToast()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const onClickConnect = (platform: Platform) => {
    console.log('paltform', platform)
    setSelectedPlatform(platform)
    setShowConnectPlatformModal(true)
  }
  const handleCloseModal = () => {
    setShowConnectPlatformModal(false)
    setSelectedPlatform(null) // Reset selected platform
  }

  useEffect(() => {
    if (fetchedConnectedPlatformData.length === 0) {
      let newPlatformData = platformData?.filter((ele) =>
        ['ECOMMERCE', 'SHIPPING_PLATFORM'].includes(ele.type)
      )
      setEcommercePlatform(newPlatformData)
    } else {
      setEcommercePlatform(platformData)
    }
  }, [showSettingModal, []])

  const handleDataReceived = (dataCollector: any, friendlyName: string) => {
    console.log('Credentials taken from input:', dataCollector)
    // const email = localStorage.getItem('userid') // Assuming 'userid' is the key you used to store the email
    setShowSettingModal(true)
    if (selectedPlatform) {
      let isMainStore = fetchedConnectedPlatformData.length === 0
      const payload = {
        name: selectedPlatform.name,
        type: selectedPlatform.type,
        code: selectedPlatform.name,
        display_name: friendlyName,
        platform_info: selectedPlatform.id,
        credentials: {
          identifier: dataCollector.find((item: any) => item.name === 'identifier')?.value || null,
          client_id: dataCollector.find((item: any) => item.name === 'client_id')?.value || null,
          client_secret:
            dataCollector.find((item: any) => item.name === 'client_secret')?.value || null,
          token_id: dataCollector.find((item: any) => item.name === 'token_id')?.value || null,
          token_secret:
            dataCollector.find((item: any) => item.name === 'token_secret')?.value || null,
          refresh_token: null,
          sftp_hostname:
            dataCollector.find((item: any) => item.name === 'sftp_hostname')?.value || null,
          sftp_username:
            dataCollector.find((item: any) => item.name === 'sftp_username')?.value || null,
          sftp_password:
            dataCollector.find((item: any) => item.name === 'sftp_password')?.value || null,
        },
        isConnected: true,
        isActive: true,
        isMainStore: isMainStore,
        isOrderSyncProcessing: false,
        isNewOrderSyncProcessing: false,
        isOrderCreateProcessing: false,
      }
      console.log('payload: ', payload)
      dispatch(connectPlatformAsync(payload))
        .then((response) => {
          dispatch(fetchConnectedPlatformAsync())
          if (response.meta.requestStatus === 'fulfilled') {
            console.log('response after connecting platform ', response)
            const statusCode = response.payload.status_code
            const platformName = payload.name // Assuming name is the property containing the platform name
            // setModalData(response.payload.data)
            if (response.payload?.data?.isMainStore) {
              // navigate(`/primary-platform-setting/${response.payload?.data?.id}`)
              // setStepsData((pre) => {
              //   return {...pre, stepOne: 'finished', stepTwo: 'active'}
              // })
              dispatch(setStepsData({stepData:{stepOne:'finished', stepTwo:'active',stepThree:'inactive'},visible:true}))
            } else {
              // navigate(`/platform-setting/${response.payload?.data?.id}`)
              setSelectedPlatformId(response.payload?.data?.id)
              // setStepsData((pre) => {
              //   return {...pre, stepOne: 'finished', stepTwo: 'finished', stepThree: 'active'}
              // })
              dispatch(setStepsData({stepData:{stepOne:'finished', stepTwo:'finished',stepThree:'active'},visible:true}))
            }
            if (response?.payload?.status_code == 201) {
              successToast(`${selectedPlatform.display_name} Channel Connected Successfully`)
              setShowSettingModal(false)
              return
            } else {
              return errorToast(
                `Failed to connect platform ${platformName}. Status code: ${statusCode}`
              )
            }
          } else {
            console.error('Connection failed:', response.payload)
            return errorToast('Failed to connect platform. Please try again.')
          }
        })
        .catch((error) => {
          console.error('Error connecting platform:', error)
          errorToast('An error occurred while connecting the platform. Please try again.')
        })
    }
  }

  // if (fetchedConnectedPlatformLoading) return <Loader />

  return (
    <>
      <div className="alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row p-3 d-flex justify-content-between">
        <div className='d-flex' >
          <span className="svg-icon svg-icon-2hx svg-icon-light me-4 mb-5 mb-sm-0">
              <img src={fetchedConnectedPlatformData[0]?.platform_info?.logo_ref!} className="h-40px" />
          </span>
          <div className="d-flex flex-column text-light pe-0 pe-sm-10">
            <h5 className="mb-1 text-success">{fetchedConnectedPlatformData[0] ? fetchedConnectedPlatformData[0]?.display_name : '' }</h5>
            <span className='text-success' >{ fetchedConnectedPlatformData[0].isMainStore ?  'Primary Channel Connected' : 'Secondary Channel Connected' }</span>
          </div>
        </div>
          <span className="svg-icon svg-icon-2x svg-icon-light">
            <KTSVG path="media/icons/duotune/general/gen043.svg" className="svg-icon-success svg-icon-2hx" />
          </span>
      </div>
      <div className='d-flex align-items-center justify-content-between' >
        <h4>
          {connectingPlatformId.isConnecting
            ? ''
            : 'Available Secondary Channels'}
        </h4>
        {/* {
            ['COMMERCIUM','WOOCOMMERCE'].includes(fetchedUserAccountData?.source!) ?
            <button type='button' className='btn btn-sm btn-primary fs-6 fw-bold' 
              onClick={()=>{
                  dispatch(setStepsData({stepData:stepsData,visible:false}))
                  navigate('/pricing')
                }} > <i className={'bi bi-coin fs-4 me-2'}></i> Manage Plan</button>
            : null
          } */}
      </div>
      <div className='py-4'>
        <div
          className={clsx(
            'row g-3 gx-2 g-xl-8',
            connectingPlatformId.isConnecting ? 'justify-content-center' : ''
          )}
        >
          {ecommercePlatform?.length > 0 &&
            ecommercePlatform.map((item: Platform) => {
              if (connectingPlatformId.isConnecting) {
                if (connectingPlatformId.platformId === item.id) {
                  return (
                    <PlatformCard
                      key={item.id}
                      data={item}
                      onClickConnectTop={setConnectingPlatformId}
                      // setStepsData={setStepsData}
                      setSelectedPlatformId={setSelectedPlatformId}
                    />
                  )
                }
              } else {
                return (
                  <PlatformCard
                    key={item.id}
                    data={item}
                    onClickConnectTop={setConnectingPlatformId}
                    // setStepsData={setStepsData}
                    setSelectedPlatformId={setSelectedPlatformId}
                  />
                )
              }
            })}
        </div>
      </div>
      {/* <ConnectPlatformModal
        isVisible={showConnectPlatformModal}
        closeModal={handleCloseModal}
        data={selectedPlatform}
        onDataReceived={handleDataReceived} // Pass the data receiving function
      /> */}
    </>
  )
}

export default TourStep2
