import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'

export const getCustomersAsync = createAsyncThunk(
  'orders/getCustomersAsync',
  async function (params: any, toolkit) {
    return await AxiosClient('GET', `/user/users-list`, [], toolkit, params )
  }
)


export const updateCustomerStatusAsync = createAsyncThunk(
  'orders/updateCustomerStatusAsync',
  async function (payload: {user_id:number, status:'active'|'inactive'}, toolkit) {
    return await AxiosClient('POST', `/user/update-status`, payload, toolkit, [] )
  }
)

export const loginAsCustomerAsync = createAsyncThunk(
  'orders/loginAsCustomerAsync',
  async function (payload: {user_id:number}, toolkit) {
    return await AxiosClient('POST', `/user/login-as-user`, payload, toolkit, [] )
  }
)

export const getCustomerReportAsync = createAsyncThunk(
  'orders/getCustomerReportAsync',
  async function (params: any, toolkit) {
    return await AxiosClient('GET', `/api/reports?page_limit=100&page_num=1`, [], toolkit, params )
  }
)

export const updateCustomerProductModule = createAsyncThunk(
  'orders/updateCustomerProductModule',
  async function (payload: any, toolkit) {
    return await AxiosClient('PUT', `/user/users-list`, payload, toolkit, [] )
  }
)

export const deleteUserAsync = createAsyncThunk(
  'user/deleteUserAsync',
  async function (payload: any, toolkit) {
    return await AxiosClient('DELETE', `/user/users-list`, payload, toolkit, [] )
  }
)