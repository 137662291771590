import {FC} from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {ConnectedPlatform} from 'src/interfaces/Platform.interface'
import {KTIcon, KTSVG} from 'src/marketConnect/helpers'
import { loggedInAdminAccessToken } from 'src/utils/constants'
import moment from 'moment'

type ConnectedPlatformStripe = {
  platform: ConnectedPlatform
  onDelete: () => void
  onTokenRefresh: ()=>void
  isRefreshing:boolean
}

const ConnectPlatformStrip: FC<ConnectedPlatformStripe> = ({
  platform,
  onDelete,
  onTokenRefresh,
  isRefreshing
}) => {

  const expirationDate = moment(platform['credentials']['refresh_token_expires_date']);
  const today = moment();
  const daysUntilExpiration = expirationDate.diff(today, 'days');

  return (
    <div className='card shadow p-2 my-3'>
      <div className='d-sm-flex flex-md-row justify-content-between p-8 border border-light-primary rounded'>
        <div className='d-flex flex-row align-self-center text-align-center'>
          <img
            src={platform.platform_info.logo_ref}
            alt='Channel Logo'
            className='me-2'
            style={{width: '30px', height: '30px'}}
          />
          <h4 className='title mt-1'>{platform?.platform_info?.display_name + " - " + platform.display_name }</h4>
        </div>
        <div className='d-md-inline-flex d-flex  mt-sm-0 mt-4 justify-content-sm-start justify-content-between'>
          <div className='d-flex align-items-center' >
            
            <span className={`fs-5 fw-bold mx-2  ${platform.isConnected ? 'text-success' : 'text-danger' }`} style={{ height: '35px', alignContent:'center' }}>{ platform.isConnected ?  'Connected' : 'Not Connected' }</span>
            
            {
              ['LAZADA'].includes(platform?.code!) && (daysUntilExpiration <= 7 && daysUntilExpiration > 0) ? 
                <button disabled={isRefreshing}  className='btn btn-sm bg-light-primary text-primary shadow-sm fs-6 fw-bold ms-3' onClick={onTokenRefresh} >{ isRefreshing && platform?.code =='LAZADA' ?  'Refreshing...' : 'Refresh Token'}</button>
              : null
            }

          { (['AIMS360','BIGCOMMERCE'].includes(platform.code) || ( localStorage.getItem(loggedInAdminAccessToken))) &&
            <OverlayTrigger
             placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={(props)=><Tooltip id="button-tooltip" {...props}>
            New Platform Setting
              </Tooltip>}
            > 
              <Link to={`/platform-setting-new/${platform.platform_info.id}/${platform.id}`} className='me-0 mx-sm-2'>
                <KTSVG 
                  path='media/icons/duotune/coding/cod001.svg'
                  className='svg-icon-muted svg-icon-2hx p-2 '
                />
              </Link>
            </OverlayTrigger>
          }

          {/* <Link to={`/platform-setting-new/${platform.platform_info.id}/${platform.id}`} className='btn btn-sm me-0 me-sm-2 '> */}
          {
            !['AIMS360','BIGCOMMERCE'].includes(platform.code) && 
              <Link to={`/platform-setting/${platform.id}`} className=' me-0 me-sm-2 '>
                <KTSVG 
                  path='media/icons/duotune/coding/cod009.svg'
                  className='svg-icon-muted svg-icon-2hx p-2'
                />
              </Link>
          }
            <button className='btn btn-sm btn-danger' onClick={onDelete}>
              {/* <KTSVG
                path='media/icons/duotune/general/gen034.svg'
                className='svg-icon-muted svg-icon-2hx'
              /> */}
               <KTIcon iconName='trash' className=' fs-3' />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConnectPlatformStrip
