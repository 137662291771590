import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import { getUserInvitesListAsync, postInvitedUser } from "../actions/userInviteAction"


type InitialState={
    userInviteLoading:boolean
    userInviteList:any[]|[]
    userInvitePaginationData:{
        pageNumber:number
        count:number
    }

    postInviteResponseLoading:boolean
    postInviteResponse:any[]|[]
    status?: number

}
const initialState:InitialState={
    userInviteLoading:false,
    userInviteList:[],
    userInvitePaginationData:{
        pageNumber:0,
        count:0
    },
    postInviteResponseLoading:false,
    postInviteResponse:[],
    status: undefined,
}
const userInviteSlice=createSlice({
    name:"userInviteSlice",
    initialState,
    extraReducers:(builder)=> {
        builder.addMatcher(isAnyOf(getUserInvitesListAsync.pending),(state)=>{
            console.log('getUserInvitesListAsync is in pending state ')
            state.userInviteLoading = true
        })
        builder.addMatcher(isAnyOf(getUserInvitesListAsync.rejected),(state)=>{
            console.log('getUserInvitesListAsync is in pending state ')
            state.userInviteLoading = false
        })
        builder.addMatcher(isAnyOf(getUserInvitesListAsync.fulfilled),(state,action)=>{
            console.log('getUserInvitesListAsync is in pending state ',action.payload)
            state.userInviteList= action.payload?.results
            state.userInvitePaginationData.count=action.payload?.count
            state.userInviteLoading = false
        })


        //postInvitedUser
        builder.addMatcher(isAnyOf(postInvitedUser.pending),(state)=>{
            console.log('postInvitedUser is in pending state ')
            state.postInviteResponseLoading = true
        })
        builder.addMatcher(isAnyOf(postInvitedUser.rejected),(state)=>{
            console.log('postInvitedUser is in REJECTED state ')
            state.postInviteResponseLoading = false
        })
        builder.addMatcher(isAnyOf(postInvitedUser.fulfilled),(state,action)=>{
            console.log(action.payload?.status_code,'postInvitedUser is in FULFILLED state ',action.payload)
            state.postInviteResponseLoading = false
            state.postInviteResponse= action.payload?.results
            state.status=action.payload?.status_code
       
        })





    },


    reducers:{

    },
})

export const {} = userInviteSlice.actions

export default userInviteSlice.reducer
