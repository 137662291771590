export const accessSessionTokenKeyName = 'access_token'
export const refreshSessionTokenKeyName = 'refresh_token'
export const loggedInUserDataKeyName = 'loggedInUser'
export const shopifyDetailsForOauth = 'shopifyDetailsForOauth'
export const shopifyDetailsForWithoutAuth = 'shopifyDetailsForWithoutAuth'
export const loggedInAdminAccessToken = 'loggedInAdminAccessToken'
export const loggedInAdminRefreshToken = 'loggedInAdminRefreshToken'
export const logOutHitCount = 'logOutHitCount'
export const checkUserToNavigateInPricing = 'checkUserToNavigateInPricing'
export const refreshOauthTokenData = 'refreshOauthTokenData'


export const shopifyAccessScope = [
  {label: 'read_orders', value: 'read_orders'},
  {label: 'write_orders', value: 'write_orders'},
  {label: 'read_draft_orders', value: 'read_draft_orders'},
  {label: 'write_draft_orders', value: 'write_draft_orders'},
  {label: 'read_files', value: 'read_files'},
  {label: 'write_files', value: 'write_files'},
  {label: 'read_inventory', value: 'read_inventory'},
  {label: 'write_inventory', value: 'write_inventory'},
  {label: 'read_locales', value: 'read_locales'},
  {label: 'write_locales', value: 'write_locales'},
  {label: 'read_markets', value: 'read_markets'},
  {label: 'write_markets', value: 'write_markets'},
  {label: 'read_orders', value: 'read_orders'},
  {label: 'write_orders', value: 'write_orders'},
  {label: 'read_products', value: 'read_products'},
  {label: 'write_products', value: 'write_products'},
  {label: 'read_returns', value: 'read_returns'},
  {label: 'write_returns', value: 'write_returns'},
]

export const LANGUAGES_ENUM = [
  "ENGLISH",
  "SPANISH",
  "HINDI",
  "BENGALI",
  "PORTUGUESE",
  "RUSSIAN",
  "GERMAN",
  "FRENCH",
  "ITALIAN",
  "PUNJABI",
  "URDU",
  "PERSIAN",
  "GREEK",
  "POLISH",
  "DUTCH",
  "ROMANIAN",
  "CZECH",
  "SLOVAK",
  "SERBIAN",
  "CROATIAN",
  "BULGARIAN",
  "SLOVENIAN",
  "LITHUANIAN",
  "LATVIAN",
  "ALBANIAN",
  "ARMENIAN",
  "ICELANDIC",
  "IRISH",
  "WELSH",
  "SCOTS_GAELIC",
  "MANDARIN",
  "CANTONESE",
  "TIBETAN",
  "BURMESE",
  "MIN_NAN",
  "HAKKA",
  "WU",
  "HMONG",
  "ARABIC",
  "HEBREW",
  "AMHARIC",
  "SOMALI",
  "TIGRINYA",
  "HAUSA",
  "BERBER",
  "OROMO",
  "SWAHILI",
  "YORUBA",
  "IGBO",
  "SHONA",
  "ZULU",
  "XHOSA",
  "AKAN",
  "KIKUYU",
  "LINGALA",
  "WOLOF",
  "BEMBA",
  "FULA",
  "TWI",
  "TAGALOG",
  "JAVANESE",
  "MALAGASY",
  "FIJIAN",
  "SAMOAN",
  "MALAY",
  "INDONESIAN",
  "HAWAIIAN",
  "MAORI",
  "CHAMORRO",
  "TONGAN",
  "ILOCANO",
  "CEBUANO",
  "BIKOL",
  "TAMIL",
  "TELUGU",
  "KANNADA",
  "MALAYALAM",
  "TULU",
  "BRAHUI",
  "TURKISH",
  "AZERBAIJANI",
  "MONGOLIAN",
  "UZBEK",
  "KAZAKH",
  "KYRGYZ",
  "TATAR",
  "UYGHUR",
  "FINNISH",
  "HUNGARIAN",
  "ESTONIAN",
  "SAMI",
  "KOMI",
  "MARI",
  "KOREAN",
  "JAPANESE",
  "THAI",
  "LAO",
  "ZHUANG",
  "GEORGIAN",
  "CHECHEN",
  "ABKHAZ",
  "AVAR",
  "LEZGIN",
  "QUECHUA",
  "AYMARA",
  "NAVAJO",
  "GUARANI",
  "NAHUATL",
  "MAYA",
  "CHEROKEE",
  "INUIT",
  "PITJANTJATJARA",
  "WARLPIRI",
  "ARRENTE",
  "TOK_PISIN",
  "HULI",
  "ENGA",
  "AUSLAN",
  "PAPIAMENTO",
  "ESPERANTO",
  "KLINGON",
  "TOKI_PONA",
  "MANX",
  "CORNISH",
  "AINU",
  "YUCHI"
]

