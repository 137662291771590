import React, {FC, useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import useAppToast from 'src/hooks/useAppToast'
import {fetchUserAccountDetailsAsync} from 'src/redux/actions/accountActions'
import {
  handleOauthCallbackAsync,
  loginAsync,
  refreshTokenOauthUrlHandleAsync,
  userOauthLoginAsync,
} from 'src/redux/actions/authActions'
import {setShopifyConnectFirstTimeMessageShow} from 'src/redux/slices/authSlice'
import {setPlatformSettingModalData} from 'src/redux/slices/connectedPlatformSlice'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'
import {
  accessSessionTokenKeyName,
  checkUserToNavigateInPricing,
  refreshOauthTokenData,
  refreshSessionTokenKeyName,
  shopifyDetailsForOauth,
  shopifyDetailsForWithoutAuth,
} from 'src/utils/constants'
import {messageTemplete} from 'src/utils/messageTemplete'

const HandleOauth: FC = () => {
  const [loading, setLoading] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const [userId, setUserId] = useState<any>()
  const dispatch = useAppDispatch()
  const {errorToast, successToast, warningToast} = useAppToast()
  const navigate = useNavigate()

  useEffect(() => {
    // Dispatch the fetch user data action on component mount
    dispatch(fetchUserAccountDetailsAsync())
      .then((response) => {
        console.log('User account data:', response.payload)
        setUserId(response.payload) // Log the response data
      })
      .catch((error) => {
        console.error('Error fetching user data:', error)
      })
  }, [])

  useEffect(() => {
    const code = searchParams.get('code')
    const hmac = searchParams.get('hmac')
    const host = searchParams.get('host')
    const shop = searchParams.get('shop')
    const state = searchParams.get('state')
    const shop_region = searchParams.get('shop_region')
    const locale = searchParams.get('locale')
    const app_key = searchParams.get('app_key')
    const timestamp = searchParams.get('timestamp')
    const spapi_oauth_code = searchParams.get('spapi_oauth_code')
    const selling_partner_id = searchParams.get('selling_partner_id')
    const store_id = searchParams.get('store_id')
    const platform_name = searchParams.get('platform_name')
    const appid = searchParams.get('appid')
    const tempToken = searchParams.get('tempToken')
    const checkAvailabilityForPricing = localStorage.getItem(checkUserToNavigateInPricing)

    //this will get all query parameters dynamically
    let urlQueryParams = {}
    if (window.location.search) {
      let queryItems = window.location.search.slice(1, window.location.search.length).split('&')
      for (let ser of queryItems) {
        let keyval = ser.split('=')
        urlQueryParams[keyval[0]] = keyval[1]
      }
    }
    // -------------------------------- //

    console.log('params for dynamic--->', urlQueryParams)

    const pltfrmdetail = localStorage.getItem(shopifyDetailsForOauth)
    const platfrmDtlWithoutAuth = localStorage.getItem(shopifyDetailsForWithoutAuth)
    const refreshOauthData = localStorage.getItem(refreshOauthTokenData)

    //this condition always work after login so no need to check user data
    if (pltfrmdetail) {
      const parsedDetail = JSON.parse(pltfrmdetail)
      //  dispatch(handleOauthCallbackAsync({  store_id : store_id ?? '',  shop_region : shop_region ? shop_region : '', locale:locale ? locale : '',  app_key: app_key ? app_key : '', code,hmac,host,shop, state, timestamp, isMainStore: parsedDetail?.isStoreCreated ? 0 : 1  , user_id:parsedDetail?.userId, available_platform_name:parsedDetail?.name, display_name: parsedDetail?.friendlyName, spapi_oauth_code : spapi_oauth_code ? spapi_oauth_code :'',selling_partner_id: selling_partner_id ? selling_partner_id : '', auth_domain : parsedDetail?.zohoAuthDomain ? parsedDetail?.zohoAuthDomain : ''  }))
      dispatch(
        handleOauthCallbackAsync({
          isMainStore: parsedDetail?.isStoreCreated ? 0 : 1,
          user_id: parsedDetail?.userId,
          available_platform_name: parsedDetail?.name,
          display_name: parsedDetail?.friendlyName,
          auth_domain: parsedDetail?.zohoAuthDomain ? parsedDetail?.zohoAuthDomain : '',
          ...urlQueryParams,
        })
      ).then((response) => {
        console.log('final check response', response.payload)
        if (response.payload?.status_code == 400) {
          if (process.env['REACT_APP_ENV'] == 'staging') {
            errorToast(response.payload?.message)
          } else {
            errorToast('Url is expired')
          }
          navigate('/auth')
        }

        if (response.payload?.status_code === 200) {
          successToast('Channel Connected Successfully')
          localStorage.removeItem(shopifyDetailsForOauth)
          // dispatch(setPlatformSettingModalData(response.payload.data))
          // navigate?('/listing-channels')

          if (response.payload?.data?.id) {
            if (checkAvailabilityForPricing) {
              localStorage.removeItem(checkUserToNavigateInPricing)
              navigate(`/pricing/${response.payload?.data?.id}`)
            } else {
              if (response?.payload?.data?.isMainStore) {
                navigate(`/primary-platform-setting/${response?.payload?.data?.id}`)
              } else {
                navigate(`/platform-setting/${response?.payload?.data?.id}`)
              }
            }
          }

          // if(response?.payload?.data?.id){

          //     if(response?.payload?.data?.isMainStore){

          //       navigate(`/primary-platform-setting/${response?.payload?.data?.id}`)
          //     }else{
          //       navigate(`/platform-setting/${response?.payload?.data?.id}`)
          //     }

          // }
        }
      })
    } else if (platfrmDtlWithoutAuth) {
      let parsedPltDtl = JSON.parse(platfrmDtlWithoutAuth)
      let payload = {}
      let shopifyPlatform = parsedPltDtl?.platform_name
      if (parsedPltDtl?.user_id) {
        payload = {
          available_platform_name: parsedPltDtl?.platform_name,
          display_name: parsedPltDtl?.platform_name,
          user_id: parsedPltDtl?.user_id,
          ...urlQueryParams,
        }
      } else {
        payload = {
          available_platform_name: parsedPltDtl?.platform_name,
          display_name: parsedPltDtl?.platform_name,
          ...urlQueryParams,
        }
      }
      dispatch(handleOauthCallbackAsync(payload)).then((response1) => {
        console.log('final check response2', response1.payload)
        if (response1.payload?.status_code == 400) {
          if (process.env['REACT_APP_ENV'] == 'staging') {
            errorToast(response1.payload?.message)
          } else {
            errorToast('Url is expired')
          }
          navigate('/auth')
        }
        if (response1.payload?.status_code === 200) {
          localStorage.removeItem(shopifyDetailsForWithoutAuth)
          dispatch(
            userOauthLoginAsync({
              email: response1.payload?.data?.user?.email,
              // password: response.payload?.data?.credentials?.identifier
            })
          )
            .then((response) => {
              if (response?.payload?.status === 401) {
                errorToast(response?.payload?.detail)
                setLoading(false)
              } else if (response?.payload?.access) {
                console.log('show toast msg')
                localStorage.setItem(accessSessionTokenKeyName, response?.payload?.access)
                localStorage.setItem(refreshSessionTokenKeyName, response?.payload?.refresh)
                if (shopifyPlatform === 'SHOPIFY') {
                  successToast('Shopify connected successfully.')
                } else {
                  successToast('Success')
                }
                setLoading(false)
                dispatch(setShopifyConnectFirstTimeMessageShow({show: true}))
                if (checkAvailabilityForPricing) {
                  localStorage.removeItem(checkUserToNavigateInPricing)
                  navigate(`/pricing/${response1.payload?.data?.id}`)
                } else {
                  navigate('/auth')
                }
              } else {
                errorToast(messageTemplete.SOMETHING_WENT_WRONG)
                setLoading(false)
              }
            })
            .catch((error) => {
              console.log('error catch:', error)
              setLoading(false)
              navigate('/auth')
            })
        }
      })
    } else {
      if(refreshOauthData){
        const refreshOauthParsedData = JSON.parse(refreshOauthData) 
      dispatch(refreshTokenOauthUrlHandleAsync({available_platform_name: refreshOauthParsedData?.available_platform_name, user_id: refreshOauthParsedData?.user_id }))
      .then((res)=>{
        if (res.payload?.status_code === 200) {
          successToast('Refresh token updated successfully')
          localStorage.removeItem(refreshOauthData)
          navigate('/listing-channels')
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log('error catch:', error)
        localStorage.removeItem(refreshOauthData)
        errorToast('Refresh token not updated. ')
        setLoading(false)
        navigate('/auth')
      })

      }else{
        //for eqwid and wix cases
        let payload = {...urlQueryParams}
        dispatch(handleOauthCallbackAsync(payload)).then((response1) => {
          console.log('response1', response1)
          if (response1.payload?.status_code == 400) {
            if (process.env['REACT_APP_ENV'] == 'staging') {
              errorToast(response1.payload?.message)
            } else {
              errorToast('Url is expired')
            }
            navigate('/auth')
          }
          if (response1.payload?.status_code === 200) {
            localStorage.removeItem(shopifyDetailsForWithoutAuth)
            dispatch(
              userOauthLoginAsync({
                email: response1.payload?.data?.user?.email,
              })
            )
              .then((response) => {
                if (response?.payload?.status === 401) {
                  errorToast(response?.payload?.detail)
                  setLoading(false)
                } else if (response?.payload?.access) {
                  localStorage.setItem(accessSessionTokenKeyName, response?.payload?.access)
                  localStorage.setItem(refreshSessionTokenKeyName, response?.payload?.refresh)
                  if (response1?.payload?.data?.user?.source == 'WIX') {
                    successToast('WIX connected successfully.')
                  } else {
                    successToast('Ecwid connected successfully.')
                  }
                  setLoading(false)
                  dispatch(setShopifyConnectFirstTimeMessageShow({show: true}))
                  navigate(`/listing-channels`)
                  // navigate('/auth')
                } else {
                  errorToast(messageTemplete.SOMETHING_WENT_WRONG)
                  setLoading(false)
                }
              })
              .catch((error) => {
                console.log('error catch:', error)
                setLoading(false)
                navigate('/auth')
              })
          }
        })
      }
      
    }
  }, [])

  return (
    <div className='d-flex align-items-center justify-content-center'>
      {loading ? <Loader /> : null}
    </div>
  )
}

export default HandleOauth
