import React from 'react'
import Card from './Card'

const AccountingPlatforms = ({platforms}: {platforms: any[]}) => {
  return (
    <div className='row'>
      {platforms.map((platform) => (
        <Card
          key={platform.id}
          data={platform}
          onClickConnectTop={(connectState) => {
            console.log('Connection State:', connectState)
          }}
        />
      ))}
    </div>
  )
}

export default AccountingPlatforms
