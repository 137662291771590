import React, {FC, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import { ATR_ON_UPDATE, CATEGORY_ATTRIBUTE_FOR_SEARCH, CATEGORY_ITEM_ON_UPDATE } from 'src/interfaces/Template.interface'
import {KTIcon} from 'src/marketConnect/helpers'
import {getPlatformFieldsAsync} from 'src/redux/actions/productActions'
import {
  deleteCategoryTemplateDataAsync,
  getPlatformCategoryTemplateDataAsync,
} from 'src/redux/actions/templateAndPricingActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'
import Select, { GroupBase } from 'react-select'
import clsx from 'clsx'
import CategoryAttributeForSelect from './CategoryAttributeForSelect'
import CategoryAttributeForMultiSelect from './CategoryAttributeForMultiSelect'
import SelectAttributeUnit from './SelectAttributeUnit'

interface Attribute {
  id: number
  api_name: string
  api_id: string
  api_code: string
  updated_at: string
  created_at: string
  platform_category_attribute: number
}

interface Category {
  id: number
  api_name: string
  api_id: string
  api_code: string
  updated_at: string
  created_at: string
  parent_data: number
  platform: number
  platform_field: number
}

type CategoryAttribute = {
  id: number
  category: Category
  api_id: string
  api_code: string
  api_name: string
  display_name: string
  isRequired: boolean
  input_type: string
  description: string
  hasUnits:boolean
}

interface AttributeInputProps {
  categoryAttributes: CategoryAttribute[]
  paltformId: any
  handleAttributeSelection: (
    data: {
      attribute_field: number
      value_str?: string | null
      value?: number | null
      mapped_field?: number | null | string
    }[]
  ) => void
  updatingMapData : CATEGORY_ITEM_ON_UPDATE|null
  // selectedTemplateCategoryData:any
  // updateSelectedDataAttribute:(
  //   data: {
  //     id:number
  //     attribute_field: number
  //     value_str?: string | null
  //     value?: number | null
  //     mapped_field?: number | null | string
  //   }[]
  // ) => void // Adjusted type to match data structure
}

const InputAttributes: FC<AttributeInputProps> = ({
  categoryAttributes,
  paltformId,
  handleAttributeSelection,
  // updateSelectedDataAttribute,
  // selectedTemplateCategoryData,
  updatingMapData
}) => {
  const dispatch = useAppDispatch()

  const [selectedTextValues, setSelectedTextValues] = useState<{[key: number]: string | null}>({})
  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform) || {}
  const [selectedValues, setSelectedValues] = useState<{[key: number]: number | null}>({})
  const [selectedFieldValues, setSelectedFieldValues] = useState<{[key: number]: number }>({})
  const [selectedUnitValues, setSelectedUnitValues] = useState<{[key: number]: number }>({})
  const [attributeMappingOptions, setAttributeMappingOptions] = useState<{[key: number]: string}>(
    () => {
      const initialMappingOptions: {[key: number]: string} = {}
      categoryAttributes.forEach((attribute) => {
        initialMappingOptions[attribute.id] = 'DEFAULT'
      })
      return initialMappingOptions
    }
  )

  const [fieldData, setFieldData] = useState<any[]>([])
  const [fieldDataCustom, setFieldDataCustom] = useState<any[]>([])
  const [isMappingLoading, setIsMappingLoading] = useState(true)
  const [initialValueLoading, setInitialValueLoading] = useState(true)

  // const [mappingOptions, setMappingOptions] = useState<{[key: number]: string}>(() => {
  //   // Initialize mapping options with 'default' for each attribute
  //   const initialMappingOptions: {[key: number]: string} = {}
  //   categoryAttributes.forEach((attribute) => {
  //     initialMappingOptions[attribute.id] = 'default'
  //   })
  //   return initialMappingOptions
  // })

  useEffect(() => {
    constructPayloadAttribute(
      attributeMappingOptions,
      selectedTextValues,
      selectedValues,
      selectedFieldValues,
      selectedUnitValues
    )
  }, [attributeMappingOptions, selectedTextValues, selectedValues, selectedFieldValues, selectedUnitValues])

  useEffect(() => {
    if (paltformId) {
      setInitialValueLoading(true)
      Promise.all([
        dispatch(
          getPlatformFieldsAsync({
            platformId: mainStoreData?.id!,
            otherParams: {field_type: 'NATIVE', includeLocalFields: true},
          })
        ).then((response) => {
          // Set categoryMainId
          setFieldData(response.payload || []) // Array containing field objects
  
          console.log('mapped filed respone', fieldData)
        }),

        dispatch(
          getPlatformFieldsAsync({
            platformId: mainStoreData?.id!,
            otherParams: {field_type: 'CUSTOM', includeLocalFields: false},
          })
        ).then((response) => {
          // Set categoryMainId
          setFieldDataCustom(response.payload || []) // Array containing field objects
  
          console.log('mapped filed respone', fieldData)
        }),

      ]).finally(()=>{
        setInitialValueLoading(false)
      })

    }
  }, [paltformId])

  const constructPayloadAttribute = (
    mappingOptions: {[key: number]: string},
    selectedTextValues: {[key: number]: string | null},
    selectedValues: {[key: number]: number | null},
    selectedFieldValues: {[key: number]: number | null},
    selectedUnitValues: {[key: number]: number | null},

  ) => {
    const attributeData = categoryAttributes.map((attribute,ind) => {
      const mappingOption = mappingOptions[attribute.id] || 'DEFAULT' // Get the mapping option for the attribute
      if (mappingOption === 'DEFAULT') {
        return {
          id: updatingMapData?.attributes[ind]?.id ?? null  ,
          attribute_field: attribute.id,
          value_str: selectedTextValues[attribute.id] || null,
          value: selectedValues[attribute.id] || null,
          mapped_field: null,
          unit:selectedUnitValues[attribute.id] || null
        }
      } else {
        return {
          id: updatingMapData?.attributes[ind]?.id ?? null  ,
          attribute_field: attribute.id,
          value_str: null,
          value: null,
          mapped_field: selectedFieldValues[attribute.id] || null,
          unit:selectedUnitValues[attribute.id] || null
        }
      }
    })
  //  alert(1)
    console.log("attributeData",attributeData)
    handleAttributeSelection(attributeData)
  }

  // const constructPayloadAttribute = (
  //   mappingOptions: { [key: number]: string },
  //   selectedTextValues: { [key: number]: string | null },
  //   selectedValues: { [key: number]: number | null },
  //   selectedFieldValues: { [key: number]: number | null }
  // ) => {
  //   if (selectedTemplateCategoryData?.attributes[0]?.id) {
  //     const UpdatedattributeData = categoryAttributes.map((attribute) => {
  //       const mappingOption = mappingOptions[attribute.id] || 'default'; // Get the mapping option for the attribute
  //       if (mappingOption === 'default') {
  //         return {
  //           id: selectedTemplateCategoryData.attributes?.id,
  //           attribute_field: attribute.id,
  //           value_str: selectedTextValues[attribute.id] || null,
  //           value: selectedValues[attribute.id] || null,
  //           mapped_field: null,
  //         };
  //       } else {
  //         return {
  //           id: selectedTemplateCategoryData?.attributes[0]?.id,
  //           attribute_field: attribute.id,
  //           value_str: null,
  //           value: null,
  //           mapped_field: selectedFieldValues[attribute.id] || null,
  //         };
  //       }
  //     });
  //     console.log('attributeData', UpdatedattributeData);
  //     // updateSelectedDataAttribute(UpdatedattributeData);
  //   } else {
  //     const attributeData = categoryAttributes.map((attribute) => {
  //       const mappingOption = mappingOptions[attribute.id] || 'default'; // Get the mapping option for the attribute
  //       if (mappingOption === 'default') {
  //         return {
  //           attribute_field: attribute.id,
  //           value_str: selectedTextValues[attribute.id] || null,
  //           value: selectedValues[attribute.id] || null,
  //           mapped_field: null,
  //         };
  //       } else {
  //         return {
  //           attribute_field: attribute.id,
  //           value_str: null,
  //           value: null,
  //           mapped_field: selectedFieldValues[attribute.id] || null,
  //         };
  //       }
  //     });
  //     console.log('attributeData', attributeData);
  //     handleAttributeSelection(attributeData);
  //   }
  // };
  


  const handleMappingOptionChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    attributeId: number
  ) => {
    const option = e.target.value;
    setAttributeMappingOptions((prevOptions) => ({
      ...prevOptions,
      [attributeId]: option,
    }));
  
    // Reset selected field value for the current attribute
    // setSelectedFieldValues((prevValues) => ({
    //   ...prevValues,
   
    // }));
  
    // Fetch field data based on the selected mapping option
    const fieldType = option === 'CUSTOM' ? 'CUSTOM' : 'NATIVE';
    dispatch(
      getPlatformFieldsAsync({
        platformId: mainStoreData?.id!,
        otherParams: { field_type: fieldType, includeLocalFields: option === 'NATIVE' },
      })
    ).then((response) => {

      if(fieldType==='NATIVE'){
        setFieldData(response.payload);
      }
      else{
        setFieldDataCustom(response.payload);
      }
      
    });
  };
  

  const handleValueChange = (attributeId: number, value: number | null) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [attributeId]: value,
    }))
    // Call constructPayloadAttribute when attribute value changes
    // constructPayloadAttribute(mappingOptions, selectedTextValues, selectedValues, selectedFieldValues);
  }
  // Handle attribute  text value change
  const handleValueTextChange = (attributeId: number, value: string) => {
    setSelectedTextValues((prevValues) => ({
      ...prevValues,
      [attributeId]: value,
    }))
    // Call constructPayloadAttribute when attribute value changes
    // constructPayloadAttribute(mappingOptions, selectedTextValues, selectedValues, selectedFieldValues);
  }

  const handleFieldValueChange = (attributeId: number, value: number ) => {
    console.log(attributeId,"setSelectedFieldValues  value--",value)
    setSelectedFieldValues((prevValues) => ({
      ...prevValues,
      [attributeId]: value,
    }))
    // Call constructPayloadAttribute when field value changes
    //   constructPayloadAttribute(mappingOptions, selectedTextValues, selectedValues, selectedFieldValues);
  }


  const handleUnitValueChange = (attributeId: number, value: number ) => {
    setSelectedUnitValues((prevValues) => ({
      ...prevValues,
      [attributeId]: value,
    }))
  }
  
  useEffect(() => {
    // on update time mappping data save default values 
    if(updatingMapData && attributeMappingOptions ){
      setIsMappingLoading(true)
      updatingMapData.attributes?.map((attr:ATR_ON_UPDATE)=>{
        if(attributeMappingOptions[attr.attribute_field.id]=='DEFAULT' ){
          // setAttributeMappingOptions((pre)=>{
          //   return {...pre, [attr.attribute_field.id]:"CUSTOM" }
          // })
          let attrid = attr.attribute_field.id
          if(attr?.value?.id){
            setSelectedValues((pre)=>{
              return {...pre, [attrid]: attr?.value?.id!  }
            })
          }
          if(attr.value_str){
            setSelectedTextValues((pre)=>{
              return {...pre, [attrid]:attr?.value_str! }
            })
          }

          if(attr.mapped_field?.id){
            setAttributeMappingOptions((pre)=>{
              if(attr.mapped_field?.field_type=='LOCAL'){
                return {...pre, [attrid]: "NATIVE" }
              }else{
                return {...pre, [attrid]: attr.mapped_field?.field_type! }
              }
            })
            setSelectedFieldValues((pre)=>{
              return {...pre, [attrid]: attr?.mapped_field?.id!  }
            })
            
          }

        }else{
          let attrid = attr.attribute_field.id
          setAttributeMappingOptions((pre)=>{
            return {...pre, [attrid]: attr.mapped_field?.field_type! }
          })
          if(attr?.value?.id){
            setSelectedValues((pre)=>{
              return {...pre, [attrid]: attr?.mapped_field?.id!  }
            })
          }
          if(attr.mapped_field?.id){
            // setAttributeMappingOptions((pre)=>{
            //   return {...pre, [attrid]: attr.mapped_field?.field_type! }
            // })
            setSelectedFieldValues((pre)=>{
              return {...pre, [attrid]: attr?.mapped_field?.id!  }
            })
            
          }
        }
      })
      setIsMappingLoading(false)
    }
    setIsMappingLoading(false)
  
  }, [updatingMapData])
  

  return (
    <div className='d-flex flex-column align-items-center'>
      {/* Render attributes with mapping options */}
      { isMappingLoading || initialValueLoading ? 
      (
        <div className='d-flex align-items-center w-100 justify-content-center' >
          <Loader/> 
        </div>
      )
      
      : <div className='mb-3 pb-1 row align-items-center w-100' >

        {/* mapping for extra field */}
        {
          categoryAttributes?.filter((it)=>it['attribute_type']!==null)?.length ? 
          <div className='border border-1px rounded mt-2 pb-2 mx-auto' >
          <div className='align-items-start' >
            <label className='fs-8 mb-0 form-label bg-light-primary text-primary px-2 py-1 rounded position-relative' style={{top:'-10px'}} >Extra Specification Mapping</label>
          </div> 
            { categoryAttributes?.filter((it)=>it['attribute_type']!==null)?.map((attribute) => (
                <div
                  key={attribute.id}
                  // className='mb-3 pb-1 row align-items-center w-100 p-2 px-0 ps-3'
                  className='mb-3 row ms-3'
                >
                  
                  <div className='col-sm-2 col-6' >
                    <label className={clsx('form-label', attribute.isRequired ? 'required' : '' )}>
                      {attribute.display_name}
                    </label>
                  </div>
                  <div className='col-sm-3 col-12' >
                      <div className='row align-items-center'>
                        <div className='col-6' >
                          <label className='form-label'>
                            Mapping Option
                          </label>
                        </div>
                        <div className="col-6">
                          <select
                            className='form-select'
                            value={attributeMappingOptions[attribute.id] || 'DEFAULT'}
                            onChange={(e) => handleMappingOptionChange(e, attribute.id)}
                          >
                            <option value='DEFAULT'>Default</option>
                            <option value='NATIVE'>Native Field</option>
                            <option value='CUSTOM'>Custom Field</option>
                          </select>
                        </div>
                      </div>
                  </div>
                  <div className='col-12 col-sm-7 mt-1 mt-sm-0 row' >
                      {/* Render the mapping option dropdown */}
                      {/* Render input field based on selected mapping option */}
                      <div className={clsx(attribute.hasUnits ? 'col-8' : 'col-12' )} >
                        {attributeMappingOptions[attribute.id] === 'DEFAULT' ? (
                          // Render default input field
                          attribute.input_type === 'SELECT' ? (
                            <>
                            {/* <select
                              className='form-select'
                              required={attribute.isRequired}
                              value={selectedValues[attribute.id]+'' || '' || selectedFieldValues[attribute.id] }
                              onChange={(e) => handleValueChange(attribute.id, parseInt(e.target.value))}
                            >
                              <option value={''} >Select {attribute.display_name}</option>
                              {attribute.attribute_data.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.api_name}
                                </option>
                              ))}
                            </select> */}
                            <CategoryAttributeForSelect
                              attribute={attribute}
                              selectedValues={selectedValues}
                              handleValueChange={handleValueChange}
                            />
                            </>
                          ) : attribute.input_type ==='MULTISELECT' ? 
                          (
                            <>
                              <div>
                                <CategoryAttributeForMultiSelect
                                  attribute={attribute}
                                  selectedValues={selectedTextValues[attribute.id]}
                                  handleValueChange={handleValueTextChange}
                                />
                                {/* <Select
                                  isMulti
                                  required={attribute.isRequired}
                                  value={
                                    selectedTextValues[attribute.id] ? 
                                    selectedTextValues[attribute.id]?.split(',')
                                    .map((item)=>{
                                      let attributeItem = attribute.attribute_data.find(it=>(it.id===Number(item)))
                                      return { label: attributeItem?.api_name, value: attributeItem?.id }
                                    }) : []
                                  }
                                  placeholder={'Select ' + attribute.display_name }
                                  isSearchable
                                  closeMenuOnSelect={false}
                                  options={attribute.attribute_data.map((item)=>({label:item.api_name, value:item.id}))}
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      minHeight: state.isFocused ? '30px' : 'auto',
                                    }),
                                    valueContainer: (base) => ({
                                      ...base,
                                      maxHeight: '60px',
                                      overflowY: 'auto',
                                    }),
                                    indicatorSeparator: (base) => ({
                                      ...base,
                                      display: 'none',
                                    }),
                                    dropdownIndicator: (base) => ({
                                      ...base,
                                      padding: '4px',
                                    }),
                                    clearIndicator: (base) => ({
                                      ...base,
                                      padding: '4px',
                                    }),
                                  }}
                                  onChange={(ev)=>{
                                    let values = ev.map((item)=>item.value).join(',')
                                    handleValueTextChange(attribute.id, values)
                                  }}
                                /> */}
                              </div>
                            </>
                          ) 
                          // : attribute.input_type ==='SELECT_WITH_SEARCH' ? 
                          // (
                          //   <>
                          //    <CategoryAttributeForSearch
                          //     attribute={attribute}
                          //     selectedValues={selectedValues}
                          //     handleValueChange={handleValueChange}
                          //    />
                          //   </>
                          // ) 
                          :
                          (
                            <input
                              type='text'
                              required={attribute.isRequired}
                              className='form-control'
                              placeholder={attribute.display_name}
                              value={selectedTextValues[attribute.id] || '' || selectedFieldValues[attribute.id] }
                              onChange={(e) => handleValueTextChange(attribute.id, e.target.value)}
                            />
                          )
                        ) : (
                          // Render mapped field input field
                          <select
                          className='form-select'
                          required={attribute.isRequired}
                          name={attribute.id + ''}
                          value={selectedFieldValues[attribute.id]+'' }
                          onChange={(e) => handleFieldValueChange(attribute.id, parseInt(e.target.value))}
                        >
                          <option value={''} >Select Field</option>
                          {attributeMappingOptions[attribute.id] === 'NATIVE' ? (
                            fieldData.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.display_name}
                              </option>
                            ))
                          ) : (
                            fieldDataCustom.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.display_name}
                              </option>
                            ))
                          )}
                        </select>
                        )}
                      </div>
                      {
                        attribute.hasUnits ? 
                        (
                          <div className='col-4' >
                            <SelectAttributeUnit
                              attribute={attribute}
                              handleValueChange={handleUnitValueChange}
                              selectedValues={selectedUnitValues}
          
                            />
                          </div>
                        ) : null
                      }
                    
                  </div>
                </div>
              ))
            }
          </div>
          : null
        }

        <div className='mt-2 pt-2 mx-auto' >
        {  categoryAttributes?.filter((it)=>it['attribute_type']==null)?.map((attribute) => (
            <div
              key={attribute.id}
              // className='mb-3 pb-1 row align-items-center w-100'
              className='mb-3 row ms-3'
            >
              <div className='col-sm-2 col-6' >
                <label className={clsx('form-label', attribute.isRequired ? 'required' : '' )}>
                  {attribute.display_name}
                </label>
              </div>
              <div className='col-sm-3 col-12' >
                  <div className='row align-items-center'>
                    <div className='col-6' >
                      <label className='form-label'>
                        Mapping Option
                      </label>
                    </div>
                    <div className="col-6">
                      <select
                        className='form-select'
                        value={attributeMappingOptions[attribute.id] || 'DEFAULT'}
                        onChange={(e) => handleMappingOptionChange(e, attribute.id)}
                      >
                        <option value='DEFAULT'>Default</option>
                        <option value='NATIVE'>Native Field</option>
                        <option value='CUSTOM'>Custom Field</option>
                      </select>
                    </div>
                  </div>
              </div>
              <div className='col-12 col-sm-7 mt-1 mt-sm-0 row' >
                  {/* Render the mapping option dropdown */}
                  {/* Render input field based on selected mapping option */}
                  <div className={clsx(attribute.hasUnits ? 'col-8' : 'col-12' )} >
                    {attributeMappingOptions[attribute.id] === 'DEFAULT' ? (
                      // Render default input field
                      attribute.input_type === 'SELECT' ? (
                        <>
                        {/* <select
                          className='form-select'
                          required={attribute.isRequired}
                          value={selectedValues[attribute.id]+'' || '' || selectedFieldValues[attribute.id] }
                          onChange={(e) => handleValueChange(attribute.id, parseInt(e.target.value))}
                        >
                          <option value={''} >Select {attribute.display_name}</option>
                          {attribute.attribute_data.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.api_name}
                            </option>
                          ))}
                        </select> */}
                        <CategoryAttributeForSelect
                          attribute={attribute}
                          selectedValues={selectedValues}
                          handleValueChange={handleValueChange}
                        />
                        </>
                      ) : attribute.input_type ==='MULTISELECT' ? 
                      (
                        <>
                          <div>
                            <CategoryAttributeForMultiSelect
                              attribute={attribute}
                              selectedValues={selectedTextValues[attribute.id]}
                              handleValueChange={handleValueTextChange}
                            />
                            {/* <Select
                              isMulti
                              required={attribute.isRequired}
                              value={
                                selectedTextValues[attribute.id] ? 
                                selectedTextValues[attribute.id]?.split(',')
                                .map((item)=>{
                                  let attributeItem = attribute.attribute_data.find(it=>(it.id===Number(item)))
                                  return { label: attributeItem?.api_name, value: attributeItem?.id }
                                }) : []
                              }
                              placeholder={'Select ' + attribute.display_name }
                              isSearchable
                              closeMenuOnSelect={false}
                              options={attribute.attribute_data.map((item)=>({label:item.api_name, value:item.id}))}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  minHeight: state.isFocused ? '30px' : 'auto',
                                }),
                                valueContainer: (base) => ({
                                  ...base,
                                  maxHeight: '60px',
                                  overflowY: 'auto',
                                }),
                                indicatorSeparator: (base) => ({
                                  ...base,
                                  display: 'none',
                                }),
                                dropdownIndicator: (base) => ({
                                  ...base,
                                  padding: '4px',
                                }),
                                clearIndicator: (base) => ({
                                  ...base,
                                  padding: '4px',
                                }),
                              }}
                              onChange={(ev)=>{
                                let values = ev.map((item)=>item.value).join(',')
                                handleValueTextChange(attribute.id, values)
                              }}
                            /> */}
                          </div>
                        </>
                      ) 
                      // : attribute.input_type ==='SELECT_WITH_SEARCH' ? 
                      // (
                      //   <>
                      //    <CategoryAttributeForSearch
                      //     attribute={attribute}
                      //     selectedValues={selectedValues}
                      //     handleValueChange={handleValueChange}
                      //    />
                      //   </>
                      // ) 
                      :
                      (
                        <input
                          type='text'
                          required={attribute.isRequired}
                          className='form-control'
                          placeholder={attribute.display_name}
                          value={selectedTextValues[attribute.id] || '' || selectedFieldValues[attribute.id] }
                          onChange={(e) => handleValueTextChange(attribute.id, e.target.value)}
                        />
                      )
                    ) : (
                      // Render mapped field input field
                      <select
                      className='form-select'
                      required={attribute.isRequired}
                      name={attribute.id + ''}
                      value={selectedFieldValues[attribute.id]+'' }
                      onChange={(e) => handleFieldValueChange(attribute.id, parseInt(e.target.value))}
                    >
                      <option value={''} >Select Field</option>
                      {attributeMappingOptions[attribute.id] === 'NATIVE' ? (
                        fieldData.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.display_name}
                          </option>
                        ))
                      ) : (
                        fieldDataCustom.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.display_name}
                          </option>
                        ))
                      )}
                    </select>
                    )}
                  </div>
                  {
                    attribute.hasUnits ? 
                    (
                      <div className='col-4' >
                        <SelectAttributeUnit
                          attribute={attribute}
                          handleValueChange={handleUnitValueChange}
                          selectedValues={selectedUnitValues}
      
                        />
                      </div>
                    ) : null
                  }
                
              </div>
            </div>
          ))
        }
        </div>
      </div> 
      }
    </div>
  )
}

export default InputAttributes
