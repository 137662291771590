import {useFormik} from 'formik'
import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Draggable  from 'react-draggable'
import Select from 'react-select'
import DraggableList from './DraggableList'
import ManageEventForm from './ManageEventForm'

// Define the Event interface with the new fields
interface Event {
  id: number
  name: string
  group_code: string
  input_type: string
  display_name: string
  description: string
  type: string
}

// Update the PlatformEvents component to accept the renderEvent prop
const PlatformEvents: FC = () => {
  const [showAddEditEvent, setShowAddEditEvent] = useState<boolean>(false)
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null)
  const {values, setFormikState, handleChange, handleBlur, handleSubmit} = useFormik({
    initialValues: {
      name: '',
      group_code: '',
      input_type: '',
      display_name: '',
      description: '',
      type: '',
      auth_requirements: [
        {
          name: '',
          group_code: '',
          input_type: '',
          display_name: '',
          description: '',
          type: '',
          placeholder: '',
        },
      ],
    },
    onSubmit: (values) => {
      // Handle form submission
      // You can use the values object to update the selectedEvent state
      setShowAddEditEvent(false)
    },
  })

  const [events, setEvents] = useState<Event[]>([
    {
      id: 1,
      name: 'Event 1',
      group_code: 'ORDER',
      input_type: 'text',
      display_name: 'Notify on Unlinked Items or any Failed Orders ',
      description: 'Description for Event 1',
      type: 'event',
    },
    {
      id: 2,
      name: 'Event 2',
      group_code: 'SHIPMENT',
      input_type: 'number',
      display_name: 'Skip the Sync of Unpaid Orders',
      description: 'Description for Event 2',
      type: 'event',
    },
    {
      id: 3,
      name: 'Event 3',
      group_code: 'INVENTORY',
      input_type: 'text',
      display_name: 'Order Number Preference',
      description: 'Description for Event 3',
      type: 'event',
    },
    {
      id: 4,
      name: 'PRICING',
      group_code: 'PRICING',
      input_type: 'text',
      display_name: 'Tags',
      description: 'Description for Event 3',
      type: 'event',
    },
    {
      id: 5,
      name: 'Event 3',
      group_code: 'PRODUCT',
      input_type: 'number',
      display_name: 'Set Safety Stock Level',
      description: 'Description for Event 3',
      type: 'event',
    },
    {
      id: 6,
      name: 'Event 3',
      group_code: 'PRODUCT',
      input_type: 'number',
      display_name: 'Default quantity',
      description: 'Description for Event 3',
      type: 'event',
    },
    // Add more events as needed
  ])

  // Function to handle event editing
  const handleEditEvent = (eventData: Event | null) => {
    // Implement the logic to handle event editing
    console.log(`Editing event with ID: ${eventData}`)
    setSelectedEvent(eventData)
    setShowAddEditEvent(true)
  }

  const handleClose = () => {
    setSelectedEvent(null)
    setShowAddEditEvent(false)
  }


  const handleDragEnd = (id: number, yPos: number) => {
    const draggedItemIndex = values.auth_requirements.findIndex((item,ind) => ind === id);
    const newItems = [...values.auth_requirements];

    // Determine the new position for the dragged item
    const targetIndex = Math.round(yPos / 50); // Assuming each item height is 50px
    const newIndex = Math.max(0, Math.min(values.auth_requirements.length - 1, draggedItemIndex + targetIndex));

    // Reorder items
    const [draggedItem] = newItems.splice(draggedItemIndex, 1);
    newItems.splice(newIndex, 0, draggedItem);

    setFormikState((pre)=>{
      return {
       ...pre,
        values: {
         ...pre.values,
          auth_requirements: newItems,
        },
      }
    })
  };


  // Use the renderEvent prop to render each event in a card view
  return (
    <div>
      <div className='d-flex justify-content-end py-4'>
        <button
          className='btn btn-sm btn-primary fw-bold'
          onClick={() => {
            handleEditEvent(null)
          }}
        >
          Add New Event
        </button>
      </div>
      <div className='row g-3'>
        {events.map((event) => (
          <div key={event.id} className='card shadow border-0 bg-hover-light-primary mt-4'>
            <div className='ribbon ribbon-start ribbon-clip mt-2 '>
              <div className='ribbon-label shadow text-primary fw-bold '>
                {event.group_code}
                <span className='ribbon-inner bg-light-primary'></span>
              </div>
            </div>
            <div className='card-body '>
              <div className='d-flex justify-content-between align-items-center'>
                <div>
                  <h5 className='card-title'>
                    {event.display_name} ({event.name}){' '}
                  </h5>
                  <p>{event.description}</p>
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-primary btn-sm text-center ps-4'
                    onClick={() => handleEditEvent(event)}
                  >
                    <i className='bi bi-pencil-square fs-5'></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      { showAddEditEvent ?
       <ManageEventForm
        key={'Add Edit event'}
        handleClose={handleClose}
        selectedEvent={selectedEvent}
        showAddEditEvent={showAddEditEvent}
       /> : null }

      {/* <Modal
        fullscreen
        scrollable
         
        show={showAddEditEvent}
        onHide={() => {
          handleClose()
        }}
      >        
          <Modal.Header>
            <Modal.Title>{selectedEvent ? 'Edit Event' : 'Add Event'}</Modal.Title>
            <div className='d-flex '>
              <button className='btn btn-secondary btn-sm fs-5 fw-bold' onClick={handleClose}>
                Close
              </button>
              <button className='btn btn-success btn-sm fs-5 fw-bold ms-2'>Save</button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <DraggableList/>
            <form onSubmit={handleSubmit}>
            <div className='card border-light-primary shadow mt-5'>
              <div className='card-body'>
                <div
                    className='position-absolute bg-primary px-3 py-2 text-white rounded shadow'
                    style={{top: '-20px'}}
                  >
                    <h5 className='mb-1'>Set Main Event</h5>
                </div>
                 
                <div className='row gy-4 mt-4'>
                  <div className='col-3'>
                    <label htmlFor='' className='form-label'>
                      Group Code
                    </label>
                    <Select
                      placeholder='Select'
                      classNames={{
                        input: () => 'fs-5',
                        indicatorSeparator: () => 'd-none',
                        dropdownIndicator: () => 'text-secondary fs-8',
                        placeholder: () => 'fs-5 text-dark',
                      }}
                    />
                  </div>
                  <div className='col-3'>
                    <label htmlFor='' className='form-label'>
                      Input Type
                    </label>
                    <Select
                      placeholder='Select'
                      classNames={{
                        input: () => 'fs-5',
                        indicatorSeparator: () => 'd-none',
                        dropdownIndicator: () => 'text-secondary fs-8',
                        placeholder: () => 'fs-5 text-dark',
                      }}
                    />
                  </div>
                  <div className='col-3'>
                    <label htmlFor='' className='form-label'>
                      Event Name
                    </label>
                    <input
                      type='text'
                      placeholder='Name of event'
                      className='form-control form-control-lg '
                    />
                  </div>
                  <div className='col-3'>
                    <label htmlFor='' className='form-label'>
                      Event Display Name
                    </label>
                    <input
                      type='text'
                      placeholder='Display Name'
                      className='form-control form-control-lg '
                    />
                  </div>
                  <div className='col-3'>
                    <label htmlFor='' className='form-label'>
                      Event Type
                    </label>
                    <select className='form-select form-select-lg'>
                      <option value=''>Select</option>
                      <option value='MAIN_STORE_SETTING'>Main Store Setting</option>
                      <option value='SECONDARY_STORE_SETTING'>Secondary Store Setting</option>
                    </select>
                  </div>
                  <div className='col-3'>
                    <label htmlFor='' className='form-label'>
                      Event Description
                    </label>
                    <input
                      type='text'
                      placeholder='Event description'
                      className='form-control form-control-lg '
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='card border-light-primary shadow mt-5'>
              <div className='card-body'>
                <div
                  className='position-absolute bg-primary px-3 py-2 text-white rounded shadow'
                  style={{top: '-20px'}}
                >
                  <h5 className='mb-1'>Add Mappings</h5>
                </div>
                <div className='d-flex justify-content-end align-items-center mb-4'>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary'
                    onClick={() => {
                      let authReq = [...values.auth_requirements]
                      authReq.push({
                        name: '',
                        group_code: '',
                        input_type: '',
                        display_name: '',
                        description: '',
                        type: '',
                        placeholder: '',
                      })
                      setFormikState((pre) => {
                        return {...pre, values: {...pre.values, auth_requirements: authReq}}
                      })
                    }}
                  >
                    Add New
                  </button>
                </div>
                {values.auth_requirements.length ? (
                  values.auth_requirements.map((mapping, index) => {
                    return (
                      <Draggable
                          key={index}
                          axis="y"
                          bounds="parent"
                          onStop={(_, data) => handleDragEnd(index, data.y)}
                        >
                        <div className='mt-1 bg-hover-light-primary cursor-grab p-4 pt-0' >
                          <div className='d-flex justify-content-end' >
                            <button type='button' className='btn btn-link' 
                            onClick={() => {
                              let authReq = [...values.auth_requirements]
                              authReq.pop()
                              setFormikState((pre) => {
                                return {...pre, values: {...pre.values, auth_requirements: authReq}}
                              })
                            }}
                            >
                              <i className="bi bi-x-square-fill fs-5 text-danger"></i>
                            </button>
                          </div>
                          <div className='row gy-2'>
                            <div className='col-2'>
                              <label htmlFor='' className='form-label'>
                                Group Code
                              </label>
                              <Select
                                placeholder='Select'
                                classNames={{
                                  input: () => 'fs-5',
                                  indicatorSeparator: () => 'd-none',
                                  dropdownIndicator: () => 'text-secondary fs-8',
                                  placeholder: () => 'fs-5 text-dark',
                                }}
                              />
                            </div>
                            <div className='col-2'>
                              <label htmlFor='' className='form-label'>
                                Input Type
                              </label>
                              <Select
                                placeholder='Select'
                                classNames={{
                                  input: () => 'fs-5',
                                  indicatorSeparator: () => 'd-none',
                                  dropdownIndicator: () => 'text-secondary fs-8',
                                  placeholder: () => 'fs-5 text-dark',
                                }}
                              />
                            </div>
                            <div className='col-2'>
                              <label htmlFor='' className='form-label'>
                                Event Name
                              </label>
                              <input
                                type='text'
                                placeholder='Name of event'
                                className='form-control form-control-lg '
                              />
                            </div>
                            <div className='col-2'>
                              <label htmlFor='' className='form-label'>
                                Event Display Name
                              </label>
                              <input
                                type='text'
                                placeholder='Display Name'
                                className='form-control form-control-lg '
                              />
                            </div>
                            <div className='col-2'>
                              <label htmlFor='' className='form-label'>
                                Placeholder
                              </label>
                              <input
                                type='text'
                                placeholder='Display Name'
                                className='form-control form-control-lg '
                              />
                            </div>
                            <div className='col-2'>
                              <label htmlFor='' className='form-label'>
                                Event Description
                              </label>
                              <input
                                type='text'
                                placeholder='Event description'
                                className='form-control form-control-lg '
                              />
                            </div>
                            <div>
                              <div className="separator my-2"></div>
                            </div>
                          </div>
                        </div>
                      </Draggable>
                      )
                    })
                ) : (
                  <div className="bg-light-primary p-4 rounded border" >
                    <p className='mb-0 fs-6 text-primary' >Add New Auth Mapping</p>
                  </div>
                )}
              </div>
            </div>
            </form>
          </Modal.Body>
      </Modal> */}

    </div>
  )
}

export default PlatformEvents
