import React, {FC, useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import useAppToast from 'src/hooks/useAppToast'
import {fetchUserAccountDetailsAsync} from 'src/redux/actions/accountActions'
import {
  handleAppInstallationAsync,
  handleOauthForConnectPlatformAsync,
  loginAsync,
  userOauthLoginAsync,
} from 'src/redux/actions/authActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'
import {
  accessSessionTokenKeyName,
  checkUserToNavigateInPricing,
  refreshSessionTokenKeyName,
  shopifyDetailsForOauth,
  shopifyDetailsForWithoutAuth,
} from 'src/utils/constants'
import {messageTemplete} from 'src/utils/messageTemplete'

const HandleOauthUrl: FC = () => {
  const [loading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const {errorToast, successToast, warningToast} = useAppToast()
  const navigate = useNavigate()
  const {fetchedUserAccountLoading} = useAppSelector((state) => state.userAccountData)

  useEffect(() => {
    //this will get all query parameters dynamically
    let urlQueryParams = {}
    if (window.location.search) {
      let queryItems = window.location.search.slice(1, window.location.search.length).split('&')
      for (let ser of queryItems) {
        let keyval = ser.split('=')
        urlQueryParams[keyval[0]] = keyval[1]
      }
    }

    dispatch(handleOauthForConnectPlatformAsync({...urlQueryParams})).then((response2) => {
      console.log('response of oauth url', response2.payload)
      if (response2.payload?.data && response2.payload?.status_code === 200) {
        //setting a check to navigate in pricing page
        localStorage.setItem(checkUserToNavigateInPricing,'1')
        window.open(response2.payload?.data?.url, '_self')
        setLoading(false)
      }
      setLoading(false)
    })
  }, [])

  if (fetchedUserAccountLoading) return <Loader />

  return (
    <div>
      <Loader />
    </div>
  )
}

export default HandleOauthUrl
