import clsx from 'clsx'
import React, {Dispatch, FC, SetStateAction, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import TourStep1 from './components/step-one/TourStep1'
import TourStep2 from './components/step-two/TourStep2'
import PlatformConfiguration from './components/step-three/PlatformConfiguration'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {
  fetchConnectedPlatformAsync,
  fetchPlatformsAsync,
  retrivePlatformDataAsync,
} from 'src/redux/actions/platformActions'
import Loader from 'src/utils/Loader'
import {KTSVG, toAbsoluteUrl} from 'src/marketConnect/helpers'
import { setInitialPopupDataAsync } from 'src/redux/actions/authActions'
import PopupPricing from './components/PopupPricing'
import ConfirmPlatformConnectionType from './components/ConfirmPlatformConnectionType'
import { setStepsData } from 'src/redux/slices/tourPopupSlice'
import { fetchUserAccountDetailsAsync } from 'src/redux/actions/accountActions'

type StepStatus = 'active' | 'inactive' | 'finished'

type TourSteps = {
  // stepZero?:StepStatus
  stepOne: StepStatus
  stepTwo: StepStatus
  stepThree: StepStatus
}

type TourPopupPropsType = {
  // visible:boolean
  // loading:boolean
  // stepsData:TourSteps
  // selectedPlatformId:any
  // setStepsData: Dispatch<SetStateAction<TourSteps>>
  // setVisible: Dispatch<SetStateAction<boolean>>
  // setSelectedPlatformId: Dispatch<SetStateAction<string | undefined>>

}

// const TourPopup: FC<TourPopupPropsType> = ({loading,visible, stepsData, selectedPlatformId,setStepsData,setVisible,setSelectedPlatformId }) => {
  const TourPopup: FC<TourPopupPropsType> = () => {

  const [loading, setLoading] = useState(true)
  const { stepsData,visible } = useAppSelector(state=>state.tourPopup)
  const [selectedPlatformId, setSelectedPlatformId] = useState<string | undefined>(undefined)
  const dispatch = useAppDispatch()
  const notOpenInPaths = ['/handle-app-installation','/handle-oauth-callback-restricted','/platform-setting','/primary-platform-setting','/platform-setting-new']
  const {fetchedUserAccountData } = useAppSelector(state=>state.userAccountData)
  const { userCurrentSubscription } = useAppSelector(state=>state.pricing)
  const {fetchedConnectedPlatformData, fetchedConnectedPlatformLoading, mainStoreData} = useAppSelector(
    (state) => state.fetchConnectedPlatform
  )
  // const [visible, setVisible] = useState(false)
  // const [stepsData, setStepsData] = useState<TourSteps>({
  //   stepOne: 'active',
  //   stepTwo: 'inactive',
  //   stepThree: 'inactive',
  // })

  useEffect(() => {
    console.log('current path-->', window.location.pathname);
    if((!notOpenInPaths.includes(window.location.pathname) && !notOpenInPaths.some(item=>(window.location.pathname.startsWith(item))) && fetchedUserAccountData) ){
      (async function () {
        try {
          await dispatch(fetchPlatformsAsync())
          const res = await dispatch(fetchConnectedPlatformAsync())
  
          if (res.payload?.status_code == 200) {
            if (res.payload?.data?.length && res.payload?.data?.length <= 2) {
              setLoading(true)
              const isMainStoreCreated = res.payload?.data?.find((item) => item?.isMainStore == true)
              const isSecondaryStoreCreated = isMainStoreCreated && res.payload?.data?.length == 2
              //checking only main store created
              if (!isMainStoreCreated && !isSecondaryStoreCreated) {
                // setVisible(true)
                dispatch(setStepsData({stepData:stepsData,visible:true}))
                setLoading(false)

              }
  
              //this if indicates that we have connected with a channel
              if (isMainStoreCreated && !isSecondaryStoreCreated) {
                 if( !['COMMERCIUM','WOOCOMMERCE'].includes(fetchedUserAccountData?.source!) && !fetchedUserAccountData?.is_platform_choice_done  ){
          
                  if(window.location.pathname==='/pricing'){
                    dispatch(setStepsData({stepData:{stepOne: 'active', stepTwo: 'inactive',stepThree:'inactive'},visible:false}))
                  }else{
                    dispatch(setStepsData({stepData:{stepOne: 'active', stepTwo: 'inactive',stepThree:'inactive'},visible:true}))
                  }
                  }else{
                   dispatch(setStepsData({stepData:{stepOne: 'finished', stepTwo: 'active',stepThree:'inactive'},visible:true}))
                 }
                setLoading(false)
              }

              //we are checking that user has connected only one channel and changed the source type it means user has completed step 1
              if(fetchedUserAccountData.is_platform_choice_done && res.payload?.data?.length ==1 ){
                //now checking the source of user for other source
                if(!['COMMERCIUM','WOOCOMMERCE'].includes(fetchedUserAccountData?.source!)){
                  dispatch(setStepsData({stepData:{stepOne: 'finished', stepTwo: 'active',stepThree:'inactive'},visible:true}))
                }
              }
  
              //checking secondary store is also created but setting not configured
              if (isMainStoreCreated && isSecondaryStoreCreated) {
                const secondaryStoreSetting = await dispatch(
                  retrivePlatformDataAsync({id: res.payload?.data?.find((item) => item?.isMainStore == false)?.id})
                )
                setSelectedPlatformId(res.payload?.data?.find((item) => item?.isMainStore == false)?.id)
                if (secondaryStoreSetting.payload?.platform_settings) {
                  //it means user has created secondary store and made setting also
                  await dispatch(setInitialPopupDataAsync({isOnboardingProcessCompleted:true}))
                  // setVisible(false)
                  dispatch(setStepsData({stepData:stepsData,visible:false}))
                  setLoading(false)
                } else {
                  dispatch(setStepsData({stepData:{stepOne: 'finished', stepTwo: 'finished', stepThree: 'active'},visible:true}))
                }
                setLoading(false)
              }
            } else if (res.payload?.data?.length > 2) {
              // setVisible(false)
              dispatch(setStepsData({stepData:stepsData,visible:false}))
              setLoading(false)
              await dispatch(setInitialPopupDataAsync({isOnboardingProcessCompleted:true}))
            }else{
              if(window.location.pathname.includes('/pricing')){
                dispatch(setStepsData({stepData:stepsData,visible:false}))
                return
              }
              dispatch(setStepsData({stepData:stepsData,visible:true}))
              setLoading(false)
            }
          }
        } catch (error) {
          setLoading(false)
          dispatch(setStepsData({stepData:stepsData,visible:false}))
        }
      })();
    }else{
      // alert("your current path is ====> " + window.location.pathname)
      dispatch(setStepsData({stepData:stepsData,visible:false}))
      // setVisible(false)
    }
  }, [window.location.pathname])

  console.log('stepsData', stepsData)

  return (
    <Modal show={visible} scrollable size='xl' backdropClassName='bg-dark' className='mt-sm-0 mt-16'   >
      { loading ? (
        <Loader />
      ) : (
        <>
          <Modal.Header className='p-0 m-0 border-0'>
            {/* {
              ['SHOPIFY', 'WOOCOMMERCE','ECWID' ].includes(fetchedUserAccountData?.source!) ? 
              <>
                <div className='w-100 ' >
                    <div className='alert bg-light-primary d-flex flex-column flex-sm-row w-100 m-0'>
                      <div className='d-flex align-items-sm-center w-100'>
                          <div
                                className={clsx(
                                  'd-flex align-items-center text-center',
                                  stepsData.stepZero == 'active'
                                    ? 'text-primary'
                                    : stepsData.stepZero == 'finished'
                                    ? 'text-success'
                                    : ''
                                )}
                                style={{width:'20%'}}
                              >
                                {
                                  stepsData.stepZero==='finished'? 
                                  <i className={ clsx( "bi bi-check-circle-fill fs-2x me-2")} style={{color:'#07bc0c'}} ></i>
                                  : <i className={ clsx( "bi bi-coin fs-2x me-2" , stepsData.stepZero==='active'? "text-primary" : "text-secondary")} ></i>
                                  
                                }
                                <small className='fs-sm-6 fs-8 fw-bold lh-1'>Select Your Plan</small>
                                
                          </div>
                        
                        <div className='separator w-25 border-2 border-light-secondary mx-3 d-sm-block d-none'></div>

                          <div
                            className={clsx(
                              'd-flex align-items-center text-center',
                              stepsData.stepOne == 'active'
                                ? 'text-primary'
                                : stepsData.stepOne == 'finished'
                                ? 'text-success'
                                : 'text-secondary'
                            )}
                            
                            style={{width:'28%'}}
                          >
                            {
                              stepsData.stepOne==='finished'? 
                              <i className={ clsx( "bi bi-check-circle-fill fs-2x me-2")} style={{color:'#07bc0c'}} ></i>
                              : <i className={ clsx( "bi bi-1-circle-fill fs-2x me-2" , stepsData.stepOne==='active'? "text-primary" : "text-secondary")} ></i>
                              
                            }
                            <small className='fs-sm-6 fs-8 fw-bold lh-1'>Connect Primary Channel</small>
                            
                          </div>
                        
                        <div className='separator w-25 border-2 border-light-secondary me-5 d-sm-block d-none'></div>
                          <div
                            className={clsx(
                              'd-flex align-items-center text-center',
                              stepsData.stepTwo == 'active'
                                ? 'text-primary'
                                : stepsData.stepTwo == 'finished'
                                ? 'text-success'
                                : 'text-secondary'
                            )}
                            style={{width:'30%'}}
                          >
                            {
                              stepsData.stepTwo=='finished' ?
                              <i className={ clsx( "bi bi-check-circle-fill fs-2x me-2")} style={{color:'#07bc0c'}} ></i>
                              :
                              <i className={ clsx( "bi bi-2-circle-fill fs-2x me-2" , stepsData.stepTwo=='inactive' ? "text-secondary" : stepsData.stepTwo==='active' ? "text-primary" : "text-secondary" )} ></i>
                            }
                            <small className='fs-sm-6 fs-8 fw-bold lh-1'>Connect Secondary Channel</small>
                            
                          </div>
                        
                        <div className='separator w-25 border-2 border-light-secondary me-5 d-sm-block d-none'></div>
                          <div
                            className={clsx(
                              'd-flex align-items-center text-center',
                              stepsData.stepThree == 'active'
                                ? 'text-primary'
                                : stepsData.stepThree == 'finished'
                                ? 'text-success'
                                : 'text-secondary'
                            )}
                            style={{width:'30%'}}
                          >
                            {
                              stepsData.stepThree=='finished' ?
                              <i className={ clsx( "bi bi-check-circle-fill fs-2x me-2")} style={{color:'#07bc0c'}} ></i>
                              :
                            <i className={ clsx( "bi bi-3-circle-fill fs-2x me-2" , stepsData.stepThree=='inactive' ? "text-secondary" : stepsData.stepThree==='active' ? "text-primary" : "text-secondary" )} ></i>
                            }
                            <small className='fs-sm-6 fs-8 fw-bold lh-1'>Complete Settings</small>
                            
                          </div>
                        
                      </div>
                    </div>
                  
                </div>
              </> : 
              <>
              </>
            } */}
                <div className='w-100 ' >
                    <div className='alert bg-light-primary d-flex flex-column flex-sm-row w-100 m-0'>
                      <div className='d-flex align-items-sm-center w-100'>
                          <div
                            className={clsx(
                              'd-flex align-items-center w-auto',
                              stepsData.stepOne == 'active'
                                ? 'text-primary'
                                : stepsData.stepOne == 'finished'
                                ? 'text-success'
                                : ''
                            )}
                            style={{width:'15%'}}
                          >
                            {
                              stepsData.stepOne==='finished'? 
                              <i className={ clsx( "bi bi-check-circle-fill fs-2x")} style={{color:'#07bc0c'}} ></i>
                              : <i className={ clsx( "bi bi-1-circle-fill fs-2x " , stepsData.stepOne==='active'? "text-primary" : "text-secondary")} ></i>
                              
                            }
                            {/* <KTSVG path="media/icons/duotune/coding/cod008.svg" className={ stepsData.stepOne==='finished'? "svg-icon-success svg-icon-2hx" : "svg-icon-primary svg-icon-2hx"} /> */}
                            <small className='fs-sm-6 fs-8 fw-bold lh-1 text-center'>{ (!['COMMERCIUM','WOOCOMMERCE'].includes(fetchedUserAccountData?.source!)) ? 'Manage Connection' : 'Connect Primary Channel'}</small>
                            
                          </div>
                        
                        <div className='separator w-25 border-2 border-light-secondary me-5 d-sm-block d-none'></div>
                          <div
                            className={clsx(
                              'd-flex align-items-center w-auto',
                              stepsData.stepTwo == 'active'
                                ? 'text-primary'
                                : stepsData.stepTwo == 'finished'
                                ? 'text-success'
                                : 'text-secondary'
                            )}
                            style={{width:'15%'}}
                          >
                            {
                              stepsData.stepTwo=='finished' ?
                              <i className={ clsx( "bi bi-check-circle-fill fs-2x")} style={{color:'#07bc0c'}} ></i>
                              :
                              <i className={ clsx( "bi bi-2-circle-fill fs-2x" , stepsData.stepTwo=='inactive' ? "text-secondary" : stepsData.stepTwo==='active' ? "text-primary" : "text-secondary" )} ></i>
                            }
                            {/* <KTSVG path="media/icons/duotune/coding/cod007.svg" className={ stepsData.stepTwo=='inactive' ? "svg-icon-muted svg-icon-2hx" : stepsData.stepTwo==='active' ? "svg-icon-primary svg-icon-2hx" : "svg-icon-success svg-icon-2hx"  }  /> */}
                            <small className='fs-sm-6 fs-8 fw-bold lh-1 text-center'>{ fetchedConnectedPlatformData[0]?.isMainStore ? 'Connect Secondary Channel' :  'Connect Primary Channel'}</small>
                            
                          </div>
                        
                        <div className='separator w-25 border-2 border-light-secondary me-5 d-sm-block d-none'></div>
                          <div
                            className={clsx(
                              'd-flex align-items-center w-auto',
                              stepsData.stepThree == 'active'
                                ? 'text-primary'
                                : stepsData.stepThree == 'finished'
                                ? 'text-success'
                                : 'text-secondary'
                            )}
                            style={{width:'15%'}}
                          >
                            {
                              stepsData.stepThree=='finished' ?
                              <i className={ clsx( "bi bi-check-circle-fill fs-2x me-2")} style={{color:'#07bc0c'}} ></i>
                              :
                            <i className={ clsx( "bi bi-3-circle-fill fs-2x me-2" , stepsData.stepThree=='inactive' ? "text-muted" : stepsData.stepThree==='active' ? "text-primary" : "text-secondary" )} ></i>
                            }
                            {/* <KTSVG path="media/icons/duotune/coding/cod001.svg" className={ stepsData.stepThree=='inactive' ? "svg-icon-muted svg-icon-2hx" : stepsData.stepThree==='active' ? "svg-icon-primary svg-icon-2hx" : "svg-icon-success svg-icon-2hx"  } /> */}
                            <small className='fs-sm-6 fs-8 fw-bold lh-1 text-center'>Complete Settings</small>
                            
                          </div>
                        
                      </div>
                    </div>            
                </div>
          </Modal.Header>
          <Modal.Body>
            {/* { ['SHOPIFY', 'WOOCOMMERCE','ECWID' ].includes(fetchedUserAccountData?.source!) && 
              !userCurrentSubscription?.current_billing_plan && stepsData?.stepZero === 'active' ? (
                <PopupPricing 
                  setStepsData={setStepsData}
                />
              ) : null} */}
            
            { ['COMMERCIUM','WOOCOMMERCE'].includes(fetchedUserAccountData?.source!) && stepsData.stepOne === 'active' ? (
              <TourStep1
                // setStepsData={setStepsData}
                setSelectedPlatformId={setSelectedPlatformId}
              />
            ) : null }

            { !['COMMERCIUM','WOOCOMMERCE'].includes(fetchedUserAccountData?.source!) && stepsData.stepOne === 'active' ? (
              <ConfirmPlatformConnectionType/> 
            ) : null }


            { stepsData.stepTwo === 'active' ? fetchedConnectedPlatformData[0]?.isMainStore ?  (
              <TourStep2
                setSelectedPlatformId={setSelectedPlatformId}
              />
            ) :  (<TourStep1
                  setSelectedPlatformId={setSelectedPlatformId}
                />) 
            : null}

            {stepsData.stepThree === 'active' ? (
              <PlatformConfiguration selectedPlatformId={selectedPlatformId} afterSettingCB={ async ()=>{
                // setStepsData({
                //   stepOne:'finished',
                //   stepTwo:'finished',
                //   stepThree:'finished'
                // })
                // setVisible(false)
                dispatch(setStepsData({stepData:{stepOne: 'finished', stepTwo: 'finished', stepThree:'finished'},visible:false}))
                await dispatch(fetchUserAccountDetailsAsync())
              }} />
            ) : null}
            <section></section>
          </Modal.Body>

        </>
      )}
    </Modal>
  )
}

export default TourPopup
