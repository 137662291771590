import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'
import {PasswordChangeType} from 'src/app/_interfaces/passwordChange.interface'

export const updatepasswordAsync = createAsyncThunk(
  'user/updateUserpassword',
  async function (payload: PasswordChangeType, toolkit) {
    return await AxiosClient('POST', '/user/update-password', payload, toolkit, [])
  }
)

export const getNotificationsSettingsAsync = createAsyncThunk(
  'user/getNotificationsSettingsAsync',
  async function (params:any, toolkit) {
    return await AxiosClient('GET', `/api/user-settings/${params.user_id}`, [], toolkit, [])
  }
)

export const updateNotificationsSettingsAsync = createAsyncThunk(
  'user/updateNotificationsSettingsAsync',
  async function (params:{user_id:number|string, payload:any }, toolkit) {
    return await AxiosClient('POST', `/api/user-settings/${params.user_id}/`, params.payload, toolkit, [])
  }
)
