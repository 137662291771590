import React, {useState} from 'react'
import {Tooltip, OverlayTrigger} from 'react-bootstrap'
import Select from 'react-select'


const defaultOptions = [
  {label: 'Amazon', value: 'Amazon'},
  {label: 'Shopify', value: 'Shopify'},
  {label: 'flipkart', value: 'flipkart'},
  {label: 'Tiktok', value: 'Tiktok'},
]
// Mock platforms data
const platforms = [
  {
    id: 3,
    type: 'MARKETPLACE',
    name: 'shopify-allegro',
    display_name: 'Shopify',
    logo_ref: 'https://appconnect-images.s3.us-east-2.amazonaws.com/58482ec0cef1014c0b5e4a70.png',
    isActive: true,
    isConnected: true,
  },
  {
    id: 4,
    type: 'ECOMMERCE',
    name: 'woocommerce',
    display_name: 'WooCommerce',
    logo_ref: 'https://appconnect-images.s3.us-east-2.amazonaws.com/woo-920x530.jpeg',
    isActive: true,
    isConnected: false,
  },
]

const Settings = () => {
  const [expanded, setExpanded] = useState(null)
  const [values, setValues] = useState({
    shipment_setting: {
      isImportShipmentFromMain: false,
      isImportOrders: false,
      isCreateOrders: false,
      isImportShipments: false,
      isCreateShipments: false,
    },
    order_setting: {
      sync_start_date: '', // Initialize sync start date
    },
  })

  const InfoTooltip = ({message = ''}) => {
    return (
      <OverlayTrigger placement='right' overlay={<Tooltip>{message}</Tooltip>}>
        <i className='bi bi-info-circle text-dark fs-5 ms-3'></i>
      </OverlayTrigger>
    )
  }

  const handleToggle = (id) => {
    setExpanded((prevExpanded) => (prevExpanded === id ? null : id))
  }

  const handleChange = (event) => {
    const {name, value, type, checked} = event.target

    if (type === 'checkbox') {
      setValues((prevValues) => ({
        ...prevValues,
        shipment_setting: {
          ...prevValues.shipment_setting,
          [name]: checked, // Update the checkbox value properly
        },
      }))
    } else if (type === 'datetime-local') {
      setValues((prevValues) => ({
        ...prevValues,
        order_setting: {
          ...prevValues.order_setting,
          [name]: value,
        },
      }))
    }
  }

  const renderExpandableSection = (title, stateName) => (
    <div className='mt-5 border shadow rounded'>
      <div className='row p-4 mt-4 pt-0'>
        <div className='col-12 col-sm-6'>
          <div className='form-check form-switch form-check-solid d-flex ps-0 align-items-center'>
            <label className='form-label me-10 me-sm-0 fs-4 mb-0' htmlFor={stateName}>
              {title}
            </label>
            <input
              className='form-check-input h-30px w-50px ms-5'
              type='checkbox'
              name={stateName}
              checked={values.shipment_setting[stateName]}
              id={stateName}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      {values.shipment_setting[stateName] && (
        <div className='row p-4 pt-0'>
          <div className='col-12'></div>
          {/* Additional content for "Import Orders" */}
          {stateName === 'isImportOrders' && (
            <div className='row'>
              <div className='col-12'>
                <div className='mt-3 border rounded'>
                  <div className='row p-4'>
                    <div className='col-12 col-sm-6 mt-4'>
                      <label className='form-label' htmlFor='goLiveDate'>
                        Date Time For Sync
                      </label>
                      <input
                        type='datetime-local'
                        className='form-control w-100'
                        id='goLiveDate'
                        name='sync_start_date'
                        value={values.order_setting.sync_start_date || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Additional content for "Create Orders" */}
          {stateName === 'isCreateOrders' && (
            <div className='mt-2 border shadow rounded'>
              <div className='row p-4'>
                <div className='col-12 col-sm-6 w-100'>
                  <label htmlFor='' className='form-label mt-1'>
                    Select Platforms
                    <InfoTooltip
                      message={
                        'Choose a unique product identifier for your Primary store to link products.'
                      }
                    />
                  </label>
                  <Select
                    isMulti
                    name='main_product_identifier'
                    options={defaultOptions}
                    // defaultValue={defaultOptions} // Default selected options
                    onChange={(ev) => {
                      // Map selected options to string values
                      const valsStr = ev.map((ite) => ite.value).join(',')

                      // Update the state
                      setValues((prevValues) => ({
                        ...prevValues,
                        shipment_setting: {
                          ...prevValues.shipment_setting,
                          main_product_identifier: valsStr, // Update with the selected values
                        },
                      }))
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {/* Additional fields for Create Shipments */}
          {stateName === 'isCreateShipments' && (
            <div className='mt-2 border shadow rounded'>
              <div className='row p-4'>
                <div className='col-12 col-sm-6 w-100'>
                  <label className='form-label mt-1'>
                    Select Shipment Store<i className='bi bi-info-circle text-dark fs-5 ms-3'></i>
                  </label>
                  <Select
                    isMulti
                    name='main_product_identifier'
                    options={defaultOptions}
                    // defaultValue={defaultOptions} // Default selected options
                    onChange={(ev) => {
                      // Map selected options to string values
                      const valsStr = ev.map((ite) => ite.value).join(',')

                      // Update the state
                      setValues((prevValues) => ({
                        ...prevValues,
                        shipment_setting: {
                          ...prevValues.shipment_setting,
                          main_product_identifier: valsStr, // Update with the selected values
                        },
                      }))
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )

  return (
    <div className='row'>
      {platforms.map((platform) => (
        <div className='col-12' key={platform.id}>
          <div className='card shadow my-2 p-4'>
            <div className='card-title d-flex justify-content-between align-items-center'>
              <h4 className='text-primary fs-4'>{platform.type}</h4>
              <i className='bi bi-trash3-fill fs-4 cursor-pointer pe-2'></i>
            </div>
            <div className='d-sm-flex flex-md-row justify-content-between align-items-center pt-0 rounded'>
              <div className='d-flex flex-row align-items-center'>
                <img
                  src={platform.logo_ref}
                  alt={`${platform.name} Logo`}
                  className='me-2'
                  style={{width: '50px', height: '50px'}}
                />
                <h4 className='title mb-0'>{platform.name}</h4>
              </div>
              <span
                className={`fw-bold d-flex align-items-center justify-content-center fs-5 ${
                  platform.isConnected ? 'text-success' : 'text-danger'
                }`}
                style={{height: '36px', lineHeight: '36px'}}
              >
                {platform.isConnected ? 'Connected' : 'Not Connected'}
              </span>
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  handleToggle(platform.id)
                }}
                className='svg-icon svg-icon-muted svg-icon-2hx'
              >
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    opacity='0.3'
                    d='M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z'
                    fill='currentColor'
                  ></path>
                  <path
                    d='M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z'
                    fill='currentColor'
                  ></path>
                </svg>
              </a>
            </div>
            {expanded === platform.id && (
              <>
                {renderExpandableSection('Create Orders', 'isCreateOrders')}
                {renderExpandableSection(
                  'Import Orders', // Title for display
                  'isImportOrders' // State key
                )}
                {renderExpandableSection('Import Shipments', 'isImportShipments')}
                {renderExpandableSection('Create Shipments', 'isCreateShipments')}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Settings
