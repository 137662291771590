import {FieldArray, useFormik} from 'formik'
import React, {FC, useId, useState} from 'react'
import {prefetch} from 'webpack'
import InfoTooltip from '../listing/components/InfoTooltip'
import * as yup from 'yup'

const ManagePlatform: FC = () => {

  const id = useId()
  const [selectedLogo, setSelectedLogo] = useState<null|File>(null)

  const fieldNames = {
    type: 'type',
    name: 'name',
    display_name: 'display_name',
    logo_ref: 'logo_ref',
    isOauth: 'isOauth',
    isAllowMultiAuth: 'isAllowMultiAuth',
    isActive:'isActive',
    help_url:'help_url',
    available_platform_auth_requirement: 'available_platform_auth_requirement',
  }

  const validationSchema = yup.object().shape({
    type: yup.string().required('Platform type is required field'),
    name: yup.string().required('Name is required field'),
    display_name: yup.string().required('Display name is required field'),
    logo_ref: yup.string().required('Logo is required field'),
    help_url: yup.string().required('Help url is required'),
    available_platform_auth_requirement: yup.array().of(
      yup.object().shape({
        id: yup.number().nullable(),
        input_type: yup.string().required('Input type is required'),
        name: yup.string().required('Name is required'),
        display_name: yup.string().required('Display name is required'),
        placeholder: yup.string().required('Placeholder is required'),
      })
    ),
  })

  const {handleSubmit, handleChange, handleBlur, setFormikState, errors, values} = useFormik({
    validationSchema:validationSchema,
    validateOnBlur: true,
    initialValues: {
      id: 3,
      type: 'ECOMMERCE',
      name: 'SHOPIFY',
      display_name: 'Shopify',
      logo_ref: 'https://appconnect-images.s3.us-east-2.amazonaws.com/58482ec0cef1014c0b5e4a70.png',
      help_url:'',
      auth_route_name: null,
      isOauth: false,
      isAllowMultiAuth: false,
      isActive: true,
      updated_at: '2024-02-16T12:07:14.091996Z',
      created_at: '2024-02-16T12:07:14.092032Z',
      available_platform_auth_requirement: [
        {
          id: null,
          input_type: 'text',
          name: 'identifier',
          display_name: 'Shopify Domain',
          placeholder:'',
          // href: null,
          // platform: 3,
        },
        {
          id: 4,
          input_type: 'password',
          name: 'token_id',
          display_name: 'Shopify API Token',
          placeholder:'',
          // href: null,
          // created_at: '2024-02-16T12:07:14.103204Z',
          // updated_at: '2024-02-16T12:07:14.103236Z',
          // platform: 3,
        },
      ],
    },
    onSubmit: () => {
      alert('Form submitted')
    },
  })

  console.log('values', values, errors )

  return (
    <div>
      <div className='row justify-content-around'>
        <div className=''>
          <div className='card border-0 shadow'>
            <form onSubmit={handleSubmit}>
              <div className='card-body'>
                <div>
                  <h3>Manage Platform</h3>
                </div>
                <div className='separator my-4'></div>
                <div className='row mb-10'>
                  <div className='col-6'>
                    <label className='form-label required'>Platform Type <InfoTooltip message='Type of platform' /> </label>
                    <select
                      name={fieldNames['type']}
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className='form-select form-select-solid form-select-lg'
                    >
                      <option value=''>Select</option>
                      <option value='ECOMMERCE'>ECOMMERCE</option>
                      <option value='MARCKETPLACE'>MARCKETPLACE</option>
                      <option value='SHIPPING_PLATFORM'>SHIPPING_PLATFORM</option>
                    </select>
                    {
                      errors.type && (
                        <p className='text-danger mb-0'>{errors.type}</p>
                      )
                    }
                    
                  </div>
                  <div className='col-6'>
                    <label className='form-label required'>Platform Status { values.isActive ? 'Active' : 'Inactive' } <InfoTooltip message='Status of platform' /> </label>
                    <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                      <input
                        className='form-check-input w-50px h-30px'
                        type='checkbox'
                        checked={values.isActive}
                        name={fieldNames['isActive']}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className='row mb-10'>
                  <div className='col-6'>
                    <label className='required form-label'>Platform Name <InfoTooltip message='Name of platform in capital letter' /></label>
                    <input
                      type='text'
                      className='form-control  form-control-solid form-control-lg'
                      placeholder='Platform Name'
                      name={fieldNames['name']}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {
                      errors.name && (
                        <p className='text-danger mb-0'>{errors.name}</p>
                      )
                    }
                  </div>
                  <div className='col-6'>
                    <label className='required form-label'>Display Name <InfoTooltip message='Name of platform to show' /></label>
                    <input
                      type='text'
                      className='form-control  form-control-solid form-control-lg'
                      placeholder='Shopify Connection'
                      name={fieldNames['display_name']}
                      value={values.display_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {
                      errors.display_name && (
                        <p className='text-danger mb-0'>{errors.display_name}</p>
                      )
                    }
                  </div>
                </div>
                <div className='row mb-10'>
                  <div className='col-6' >
                    <label className='required form-label'>Platform Logo <InfoTooltip message='Logo of platform' /> </label>
                    <input
                      type='file'
                      accept='image/png, image/gif, image/jpeg'
                      className='form-control  form-control-solid form-control-lg'
                      multiple={false}
                      // value={selectedLogo}
                      // name={fieldNames['logo_ref']}
                      value={selectedLogo?.name}
                      onChange={(ev)=>{
                        console.log('selected file is', ev.target.files );  
                        if(ev.target.files?.length){
                          let fileUrl = URL.createObjectURL(ev.target.files[0])
                          setFormikState((pre)=>{
                            return {...pre, values:{...pre.values, logo_ref:fileUrl } }
                          })
                        }                      
                      }}
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                    />
                    {
                      errors.logo_ref && (
                        <p className='text-danger mb-0'>{errors.logo_ref}</p>
                      )
                    }
                  </div>
                  <div className="col-6">
                    <label className='form-label' >Selected Logo </label>
                    <div className='shadow p-2 rounded text-center' >
                      <img src={values.logo_ref} alt="selected logo" className='h-100px' />
                    </div>
                  </div>
                </div>
                <div className='mb-10'>
                  <label className='required form-label'>Help Link <InfoTooltip message='Platform related link' /></label>
                  <input
                    type='url'
                    className='form-control  form-control-solid form-control-lg'
                    placeholder='ex- https://commurcium.com/help'
                    value={values.help_url}
                    name={fieldNames['help_url']}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {
                      errors.help_url && (
                        <p className='text-danger mb-0'>{errors.help_url}</p>
                      )
                    }
                </div>
                <div className='mb-4'>
                  {/* <label className='required form-label'>Auth Type</label>
                  <div className='d-flex align-items-center pt-2'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm '>
                      <input
                        id='viaOauth'
                        className='form-check-input'
                        type='radio'
                        name={fieldNames['isOauth']}
                        checked={values.isOauth}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={'true'}
                      />
                      <label htmlFor='viaOauth' className='form-label ms-3'>
                        Using Oauth
                      </label>
                    </div>
                    <div className='form-check form-check-custom form-check-solid form-check-sm ms-sm-5 '>
                      <input
                        id='viaAPI'
                        className='form-check-input'
                        type='radio'
                        name={fieldNames['isOauth']}
                        checked={values.isOauth}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={'false'}
                      />
                      <label htmlFor='viaAPI' className='form-label ms-3'>
                        Using API Keys
                      </label>
                    </div>
                  </div> */}
                  <div className="row mb-10">
                    <div className='col-5'>
                      <label className='form-label'>Allowing connection using Oauth <InfoTooltip message='When user want connect platform using oauth or using API keys' /></label>
                      <div className='form-check form-switch form-check-custom form-check-solid mt-1 d-flex'>
                        <input
                          className='form-check-input w-50px h-30px'
                          type='checkbox'
                          checked={values.isOauth}
                          name={fieldNames['isOauth']}
                          onChange={handleChange}
                        />
                        {/* <p className='form-label mb-0 ms-2 text-success ' >{values.isOauth ? 'Connection made using Oauth' : 'Connection made using API Keys' }</p> */}
                      </div>
                      <p className='form-label mb-0 mt-2 text-success ' >{values.isOauth ? 'Connection made using Oauth' : 'Connection made using API Keys' }</p>
                    </div>
                    <div className='col-5'>
                      <label className='form-label'>Allowing Multiple Oauth <InfoTooltip message='Name of platform in capital letter' /></label>
                      <div className='form-check form-switch form-check-custom form-check-solid mt-1'>
                        <input
                          className='form-check-input w-50px h-30px'
                          type='checkbox'
                          checked={values.isAllowMultiAuth}
                          name={fieldNames['isAllowMultiAuth']}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mb-10'>
                  <label className='form-label'>Auth Requirements <InfoTooltip message='Name of platform in capital letter' /></label>
                  <div>
                    {values.available_platform_auth_requirement.length
                      ? values.available_platform_auth_requirement.map(
                          (auth_requirement, authRequirementIndex) => {
                            return (
                              <div key={id+authRequirementIndex} className='row mt-3'>
                                <div className='col-2'>
                                  <label className='form-label'>Input Type</label>
                                  <select
                                    value={auth_requirement.input_type}
                                    name={`available_platform_auth_requirement[${authRequirementIndex}]['input_type']`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className='form-select form-select-solid form-select-lg'
                                  >
                                    <option value=''>Select</option>
                                    <option value='text'>TEXT</option>
                                    <option value='boolean'>BOOLEAN</option>
                                    <option value='number'>NUMBER</option>
                                    <option value='select'>SELECT</option>
                                    <option value='datetime'>DATETIME</option>
                                  </select>
                                  {
                                    errors.available_platform_auth_requirement?.length && errors.available_platform_auth_requirement[authRequirementIndex]['input_type'] && (
                                      <p className='text-danger mb-0'>{errors.available_platform_auth_requirement[authRequirementIndex]['input_type']}</p>
                                    )
                                  }
                                </div>
                                <div className='col-3'>
                                  <label className='required form-label'>Name</label>
                                  <input
                                    type='text'
                                    className='form-control  form-control-solid form-control-lg'
                                    placeholder='ex- identifier'
                                    value={auth_requirement.name}
                                    name={`available_platform_auth_requirement[${authRequirementIndex}]['name']`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {
                                    errors.available_platform_auth_requirement?.length && errors.available_platform_auth_requirement[authRequirementIndex]['name'] && (
                                      <p className='text-danger mb-0'>{errors.available_platform_auth_requirement[authRequirementIndex]['name']}</p>
                                    )
                                  }
                                </div>
                                <div className='col-3'>
                                  <label className='required form-label'>Display Name</label>
                                  <input
                                    type='text'
                                    className='form-control  form-control-solid form-control-lg'
                                    placeholder='ex - Shopify Domain'
                                    value={auth_requirement.display_name}
                                    name={`available_platform_auth_requirement[${authRequirementIndex}]['display_name']`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {
                                    errors.available_platform_auth_requirement?.length && errors.available_platform_auth_requirement[authRequirementIndex]['display_name'] && (
                                      <p className='text-danger mb-0'>{errors.available_platform_auth_requirement[authRequirementIndex]['display_name']}</p>
                                    )
                                  }
                                </div>
                                <div className='col-3'>
                                  <label className='required form-label'>Placeholder</label>
                                  <input
                                    type='text'
                                    className='form-control  form-control-solid form-control-lg'
                                    placeholder='ex - Platform Domain Identifier'
                                    value={auth_requirement.placeholder}
                                    name={`available_platform_auth_requirement[${authRequirementIndex}]['placeholder']`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {
                                    errors.available_platform_auth_requirement?.length && errors.available_platform_auth_requirement[authRequirementIndex]['placeholder'] && (
                                      <p className='text-danger mb-0'>{errors.available_platform_auth_requirement[authRequirementIndex]['placeholder']}</p>
                                    )
                                  }
                                </div>
                                <div className="col-1 align-content-end">
                                  <button type='button' className="btn btn-sm btn-link btn-color-danger" onClick={()=>{
                                    let filteredAuthReqms = [...values.available_platform_auth_requirement].filter((_,ind)=>(ind!==authRequirementIndex))
                                    setFormikState((pre)=>{
                                      return {...pre, values:{...pre.values, available_platform_auth_requirement:filteredAuthReqms } }
                                    })
                                  }} >
                                      <i className="bi bi-x-square-fill fs-5"></i>
                                  </button>
                                </div>
                              </div>
                            )
                          }
                        )
                      : <div className='card' >
                        <div className="card-body">
                          <h4>Add required fields to connect platform </h4>
                        </div>
                      </div> }
                    <div className='d-flex justify-content-end my-2'>
                      <button
                        type='button'
                        className='btn btn-sm btn-primary fw-bold'
                        onClick={() =>
                          setFormikState((pre) => {
                            return {
                              ...pre,
                              values: {
                                ...pre.values,
                                available_platform_auth_requirement: [
                                  ...pre.values.available_platform_auth_requirement,
                                  {
                                    id: null,
                                    name: '',
                                    display_name: '',
                                    input_type: '',
                                    placeholder:''
                                  },
                                ],
                              },
                            }
                          })
                        }
                      >
                        {' '}
                        <i className='bi bi-plus-square fw-bold'></i> Add New
                      </button>
                    </div>
                  </div>
                  {/* <div className='row '>
                    <div className='col-3'>
                      <label className='form-label'>Input Type</label>
                      <select className='form-select form-select-solid form-select-lg'>
                        <option value=''>Select</option>
                        <option value='TEXT'>TEXT</option>
                        <option value='BOOLEAN'>BOOLEAN</option>
                        <option value='NUMBER'>NUMBER</option>
                        <option value='SELECT'>SELECT</option>
                        <option value='DATETIME'>DATETIME</option>
                      </select>
                    </div>
                    <div className='col-3'>
                      <label className='required form-label'>Name</label>
                      <input
                        type='text'
                        className='form-control  form-control-solid form-control-lg'
                        placeholder='ex- identifier'
                      />
                    </div>
                    <div className='col-3'>
                      <label className='required form-label'>Display Name</label>
                      <input
                        type='text'
                        className='form-control  form-control-solid form-control-lg'
                        placeholder='ex - Shopify Domain'
                      />
                    </div>
                    <div className='col-3'>
                      <label className='required form-label'>Placeholder</label>
                      <input
                        type='text'
                        className='form-control  form-control-solid form-control-lg'
                        placeholder='ex - Shopify Domain Identifier '
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className='d-flex justify-content-center my-2'>
                <button type='submit' className='btn btn-success'>
                  Save Platform
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManagePlatform
