import React, { FC, useState } from 'react'
import { CUSTOMER } from 'src/app/_interfaces/customer.interface'
import useAppToast from 'src/hooks/useAppToast'
import { updateCustomerProductModule } from 'src/redux/actions/customerAction'
import { useAppDispatch } from 'src/redux/storeHooks'


const EnableProductModule:FC<{isEnableProductModule:boolean,customer:CUSTOMER}> = ({isEnableProductModule,customer}) => {

    const [isEnable, setIsEnable] = useState<boolean>(isEnableProductModule)
    const dispatch = useAppDispatch()
    const { successToast, warningToast } = useAppToast()

    const changeProductModule = (ev: React.ChangeEvent<HTMLInputElement>) => {
      setIsEnable(ev.target.checked)
        dispatch(updateCustomerProductModule({user_id:customer.id, isEnableProductModule: ev.target.checked }))
        .then((res)=>{
          console.log('enable module res', res.payload);
          if(res.payload?.status_code==200){
            successToast(res.payload?.massage)
            setIsEnable(ev.target.checked)
          }else{
            warningToast(res.payload?.data?.massage)
            setIsEnable(false)
          }
        })
        .catch(()=>{
          setIsEnable(false)
          warningToast('Unable to update')

        })
    }

  return (
    <div>
        <div className='form-check form-switch form-check-custom form-check-solid'>
            <input
              className='form-check-input w-50px'
              type='checkbox'
              checked={isEnable}
              onChange={(ev)=>changeProductModule(ev)}
            />
          </div>
    </div>
  )
}

export default EnableProductModule