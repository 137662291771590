import * as Yup from 'yup';
import { FC, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useAppDispatch } from 'src/redux/storeHooks';
import { Button, Modal } from 'react-bootstrap';

// Define the type for form values
interface FormValues {
  email: string;
  name: string;
}

interface InvitingUserModalProps {
  show: boolean;
  onClose: () => void;
  onInviteUser: (values: FormValues) => void;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email is required'),
  name: Yup.string().required('Name is required'),
});

const InvitingUserModal: FC<InvitingUserModalProps> = ({ show, onClose, onInviteUser }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useAppDispatch();

  // Define initial form values
  const initialValues: FormValues = {
    email: '',
    name: '',
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setIsSubmitting(true);
    try {
      await validationSchema.validate(values); // Validate form values
      onInviteUser(values);
    } catch (error) {
      console.error('Form validation error:', error);
      // Handle validation errors (e.g., display error messages)
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    if (!isSubmitting) {
      onClose();
    }
  };

  return (
    <Modal   show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Invite User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mb-3 d-flex flex-column">
                <div className="">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <Field type="email" name="email" placeholder='User email' className="form-control" />
                </div>
                <ErrorMessage name="email" component="div" className="text-danger mt-2" /> {/* Updated error message position */}
              </div>
              <div className="mb-3 d-flex flex-column">
                <div className="">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <Field type="text" name="name" placeholder='User name' className="form-control" />
                </div>
                <ErrorMessage name="name" component="div" className="text-danger mt-2" /> {/* Updated error message position */}
              </div>
              <div className="d-flex justify-content-end">
                <button className="btn btn-sm fw-bold btn-primary me-2" type="submit" disabled={isSubmitting}>
                  Invite User
                </button>
                <button type='button' className="btn btn-sm btn-secondary fw-bold" disabled={isSubmitting} onClick={handleClose}>
                  Close
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default InvitingUserModal;
