import {faL, faXmark} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {FC, useCallback, useEffect, useState} from 'react'
import {PLATFORM_FIELD, platformfielddata_set} from 'src/interfaces/Platform.interface'
import {MAPPING_ITEM_OF_RECIPE_DATA, PRODUCT_RECIPE, RECIPE, RECIPE_FORM_DATA} from 'src/interfaces/Template.interface'
import Select, {components, MenuListProps} from 'react-select'
import {FormikState} from 'formik'
import {deleteRecipeMappingItemAsync, getPlatformChildFieldDataCategoriesAsync, getPlatformParentFieldDataCategoriesAsync, getPlatformParentFieldDataWhenPlatformIsCategorySelectedAsync} from 'src/redux/actions/templateAndPricingActions'
import {useAppDispatch} from 'src/redux/storeHooks'
import useAppToast from 'src/hooks/useAppToast'

type RecipeRuleItemPropsType = {
  formValues: RECIPE_FORM_DATA
  handleChange: (ev: React.ChangeEvent) => void
  customFieldLoading: boolean
  recipe: PRODUCT_RECIPE
  recipeIndex: number
  setFormikState: (
    f:
      | FormikState<RECIPE_FORM_DATA>
      | ((prevState: FormikState<RECIPE_FORM_DATA>) => FormikState<RECIPE_FORM_DATA>),
    cb?: (() => void) | undefined
  ) => void
  setSubmitting: (isSubmitting: boolean) => void
  getLatestRecipesList: () => void
  recipeData: RECIPE | null
  platformNativeFields : PLATFORM_FIELD[] | []
  platformCustomFields : PLATFORM_FIELD[] | []
  recipeDataInDetail:MAPPING_ITEM_OF_RECIPE_DATA|null
}

const RecipeRuleItem: FC<RecipeRuleItemPropsType> = ({
  formValues,
  handleChange,
  customFieldLoading,
  recipe,
  recipeIndex,
  setFormikState,
  setSubmitting,
  getLatestRecipesList,
  platformCustomFields,
  platformNativeFields,
  recipeData,
  recipeDataInDetail,

}) => {
   
console.log('recipe------', recipeDataInDetail?.check_platform_field.api_code) 
//   const [platformNativeFields, setPlatformNativeFields] = useState<PLATFORM_FIELD[] | []>([])
//   const [platformCustomFields, setPlatformCustomFields] = useState<PLATFORM_FIELD[] | []>([])
  const [platformFiledDataSet, setPlatformFiledDataSet] = useState<platformfielddata_set[]|[]>([])
  const [checkedPlatformField, setCheckedPlatformField] = useState<null|number>(null)
  const [platformFieldDataSetLoading, setPlatformFieldDataSetLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const {errorToast, successToast, warningToast} = useAppToast()

  //this is use when we select category field 
  const [isCategoryFieldSelected, setIsCategoryFieldSelected] = useState(false)
  const [noChildrenFound, setNoChildrenFound] = useState(false)
  const [lastAccessedParent, setLastAccessedParent] = useState([{label:'Select Sub category', value:''}])

  const getPlatformDataSetAsync = useCallback(
    (platformFieldId) => {
        setPlatformFieldDataSetLoading(true)
        dispatch(getPlatformParentFieldDataCategoriesAsync({platform_Id: platformFieldId }))
        .then((response)=>{
            console.log("platform filed data set response -----", response); 
            if(response.payload?.length){
                setPlatformFiledDataSet(response.payload)    
                setPlatformFieldDataSetLoading(false)      
            } 
            setPlatformFieldDataSetLoading(false)
        })
        .catch(()=>{
            setPlatformFieldDataSetLoading(false)
        })
    },
    [],
  )

  const getPlatformChildFieldDataAsync = (platformFieldId, fieldmeta={label:'', value:''},  isBack=false, isParentDataNull=false ) => {
        setPlatformFieldDataSetLoading(true)
        dispatch(getPlatformChildFieldDataCategoriesAsync({platform_Id: platformFieldId }))
        .then((response)=>{
            console.log("platform filed data set response -----", response); 
            if(response.payload?.length){
                setPlatformFiledDataSet(response.payload)
                if(isBack){
                  let shadow = [...lastAccessedParent]
                  shadow.pop()
                  setLastAccessedParent(shadow)
                }else{
                  setLastAccessedParent((pre)=>{
                    return [...pre, fieldmeta]
                  })    
                }
                setPlatformFieldDataSetLoading(false)      
               }else{
                if(!isParentDataNull){
                  setNoChildrenFound(true)
                }
                } 
                setPlatformFieldDataSetLoading(false)
          })
          .catch(()=>{
              setPlatformFieldDataSetLoading(false)
          })
  }
  
  console.log('lastAccessedParent', lastAccessedParent)

  useEffect(() => {
    // 1 take resipeData of  mapping_data
    // 2 loop on mapping_data and find with help native and custom fields what is the input_type
    // 3 jiska input type "SELECT" hai uske lie field-data ki api call
    if(recipe.id){
        if(recipeDataInDetail?.check_platform_field.input_type=='SELECT'||recipeDataInDetail?.check_platform_field.input_type=='MULTISELECT'){
            setPlatformFieldDataSetLoading(true)
            dispatch(getPlatformParentFieldDataCategoriesAsync({platform_Id: recipeDataInDetail.check_platform_field.id }))
            .then((response)=>{
                console.log("platform filed data set response -----", response); 
                if(response.payload?.length){
                    setPlatformFiledDataSet(response.payload)  
                    setPlatformFieldDataSetLoading(false)

                } 
                setPlatformFieldDataSetLoading(false)
            })
            .catch(()=>{
                setPlatformFieldDataSetLoading(false)
            })
        }

        if(recipeDataInDetail?.check_platform_field.api_code==='CATEGORY'){
          setIsCategoryFieldSelected(true)
          let sucategoryItemarray = recipeDataInDetail.check_value.split(',')
          let subcatitem = ''
          if(sucategoryItemarray.length){
            subcatitem = sucategoryItemarray[0]
          }
          if(subcatitem){
            dispatch(getPlatformParentFieldDataWhenPlatformIsCategorySelectedAsync({subCategory_id: subcatitem }))
            .then((response1)=>{
              if(response1.payload?.length){
                let parent = response1.payload[response1.payload?.length-1]['parent_data']
                dispatch(getPlatformChildFieldDataCategoriesAsync({platform_Id: Number(parent) }))
                .then((response)=>{
                    if(response.payload?.length){
                        setPlatformFiledDataSet(response.payload)    
                        setPlatformFieldDataSetLoading(false)
                        setLastAccessedParent([{label:'Select Category',value:''},{label: response1.payload[response1.payload?.length-2]['api_name'], value:  response1.payload[response1.payload?.length-2]['platform_field'] }])      
                    } 
                    setPlatformFieldDataSetLoading(false)
                })
                .catch(()=>{
                    setPlatformFieldDataSetLoading(false)
                })
              }
              
            })
          }

        }
    }
  }, [recipeDataInDetail])

  return (
    <div className='row mt-3 gx-2 '>
      <div className='col-6 col-sm-1 min-w-80px'>
        <select
          className='form-select h-100'
          name={`dataConjunction`}
          value={formValues.dataConjunction}
          onChange={handleChange}
        >
          <option value='AND'>And</option>
          <option value='OR'>Or</option>
        </select>
      </div>
      <div className='col-6 col-sm-2'>
        <select
          className='form-select h-100'
          disabled={platformFieldDataSetLoading}
          onChange={(ev)=>{
            if(ev.target.value){
              let matchedForInputTypeSelect =   [...platformCustomFields, ...platformNativeFields].find((item)=>(item.id==Number(ev.target.value)))
              if(matchedForInputTypeSelect?.api_code==='CATEGORY'){
                setIsCategoryFieldSelected(true)
              }else{
                setIsCategoryFieldSelected(false)
              }
              if(matchedForInputTypeSelect?.input_type=='SELECT'||matchedForInputTypeSelect?.input_type=='MULTISELECT'){
                // setCheckedPlatformField(Number(ev.target.value))
                getPlatformDataSetAsync(ev.target.value)
              }
            }
            handleChange(ev)
          }}
          value={recipe.checkPlatformField}
          name={`productRecipes[${recipeIndex}].checkPlatformField`}
        >
          <optgroup label='Native Fields'>
            {platformNativeFields.length &&
              [...platformNativeFields].map((item: PLATFORM_FIELD) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.display_name}
                  </option>
                )
              })}
          </optgroup>
          <optgroup label='Custom Fields'>
            {platformCustomFields.length &&
              [...platformCustomFields].map((item: PLATFORM_FIELD) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.display_name}
                  </option>
                )
              })}
          </optgroup>
        </select>
      </div>
      <div className='col-12 col-sm-8 mt-2 mt-sm-0'>
        {[...platformNativeFields, ...platformCustomFields].find(
          (pltFrm) => pltFrm.id + '' === formValues.productRecipes[recipeIndex].checkPlatformField
        )?.input_type == 'TEXT' && (
          <div>
            <div className='input-group'>
              <div className='input-group-text p-0'>
                <select
                  className='form-select rounded-0'
                  name={`productRecipes[${recipeIndex}].checkOperator`}
                  value={recipe.checkOperator}
                  onChange={handleChange}
                >
                  <option value='CONTAINS'>Contain</option>
                  <option value='NOT_CONTAINS'>Does not Contain</option>
                 { !['2079','1370'].includes(recipe?.checkPlatformField) ? 
                 <>
                  <option value='EQUAL'>Equals to</option>
                    <option value='NOT_EQUAL'>Not Equals</option> 
                 </>
                  : null }
                  {

                  }
                </select>
              </div>
              <input
                type='text'
                required
                className='form-control'
                value={recipe.checkValue}
                name={`productRecipes[${recipeIndex}].checkValue`}
                onChange={handleChange}
              />
            </div>
          </div>
        )}

      {[...platformNativeFields, ...platformCustomFields].find(
          (pltFrm) => pltFrm.id + '' === formValues.productRecipes[recipeIndex].checkPlatformField
        )?.input_type == 'FLOAT' && (
          <div>
            <div className='input-group'>
              <div className='input-group-text p-0'>
                <select
                  className='form-select rounded-0'
                  name={`productRecipes[${recipeIndex}].checkOperator`}
                  value={recipe.checkOperator}
                  onChange={handleChange}
                >
                  <option value='EQUAL'>Equals To</option>
                  <option value='CONTAINS'>Contain</option>
                  <option value='GREATER'>Greater Than</option>
                  <option value='SMALLER'>Less Than</option> 
                </select>
              </div>
              <input
                type='text'
                required
                className='form-control'
                value={recipe.checkValue}
                name={`productRecipes[${recipeIndex}].checkValue`}
                onChange={handleChange}
              />
            </div>
          </div>
        )}

        {[...platformNativeFields, ...platformCustomFields].find(
          (pltFrm) => pltFrm.id + '' === formValues.productRecipes[recipeIndex].checkPlatformField
        )?.input_type == 'SELECT' && (
          <div>
            <div className='input-group row ms-1 '>
              <div className='input-group-text p-0 col-6 col-sm-2'>
                <select
                  className='form-select h-100 rounded-0'
                  name={`productRecipes[${recipeIndex}].checkOperator`}
                  value={recipe.checkOperator}
                  onChange={handleChange}
                >
                  <option value='CONTAINS'>Contain</option>
                  <option value='NOT_CONTAINS'>Does not Contain</option>
                </select>
              </div>
              <div className='input-group-text p-0 col-6 col-sm-3'>
                <select
                  className='form-select h-100 rounded-0'
                  name={`productRecipes[${recipeIndex}].checkConjunction`}
                  value={recipe.checkConjunction}
                  onChange={handleChange}
                >
                  <option value='OR'>Any</option>
                  <option value='AND'>All</option>
                </select>
              </div>
              <div className='form-control p-0 col-12 col-sm-7 mt-2 mt-sm-0'>
                {!isCategoryFieldSelected ? 
                
                <Select
                  isMulti
                  isLoading={platformFieldDataSetLoading}
                  key={'selc' + recipeIndex + platformFiledDataSet.length }
                  hideSelectedOptions
                  required
                  placeholder={`Select your collection`}
                  options={platformFiledDataSet.map((pltd) => ({
                      label: pltd.api_name,
                      value: pltd.id + '',
                    }))}
                  value={
                    recipe.checkValue.length
                      ? recipe.checkValue.split(',').map((selc, ins) => {
                        console.log('selc', selc, isNaN(Number(selc)))
                        
                        if(!isNaN(Number(selc))){
                            return {
                               label: platformFiledDataSet.find((pltd) => pltd.id + '' == selc)?.api_name,
                               value: selc,
                             }
                        }
                    })
                      : []
                  }
                  onChange={(newValues, actionMeta) => {
                   
                    let updats: string[] = newValues.map((it) => {
                      return it!.value
                    })
                    let fullary = [...formValues.productRecipes]
                    let modifyingRecipe = recipe
                    modifyingRecipe.checkValue = updats.join(',')
                    fullary[recipeIndex] = modifyingRecipe

                    setFormikState((pre) => {
                      return {
                        ...pre,
                        values: {
                          ...pre.values,
                          productRecipes: [...fullary],
                        },
                      }
                    })
                  }}
                /> 
                : 
                <div>
                <Select
                  isMulti
                  required
                  defaultMenuIsOpen={recipe.id ? false : true }
                  isLoading={platformFieldDataSetLoading}
                  key={'selc' + recipeIndex + platformFiledDataSet.length }
                  hideSelectedOptions
                  placeholder={`Select your Category`}
                  options={platformFiledDataSet.map((pltd) => ({
                      label: pltd.api_name,
                      value: pltd.id + '',
                    }))}
                  value={
                    recipe.checkValue.length
                      ? recipe.checkValue.split(',').map((selc, ins) => {
                        console.log('selc', selc, isNaN(Number(selc)))
                        
                        if(!isNaN(Number(selc))){
                            return {
                              label: platformFiledDataSet.find((pltd) => pltd.id + '' == selc)?.api_name,
                              value: selc,
                            }
                        }
                    })
                      : []
                  }
                  onChange={(newValues, actionMeta) => {
                  
                    let updats: string[] = newValues.map((it) => {
                      return it!.value
                    })
                    let fullary = [...formValues.productRecipes]
                    let modifyingRecipe = recipe
                    modifyingRecipe.checkValue = updats.join(',')
                    fullary[recipeIndex] = modifyingRecipe

                    setFormikState((pre) => {
                      return {
                        ...pre,
                        values: {
                          ...pre.values,
                          productRecipes: [...fullary],
                        },
                      }
                    })
                  }}
                  closeMenuOnSelect={false}
                  components={{
                  // top of menu list
                  MenuList:(props)=>{
                    // if nested children not found after select a sub category or category allow user to select value otherwise allow user to nest select subcategory
                    if(noChildrenFound){
                      return(
                        <components.MenuList {...props} >
                            <button type='button' className='w-100 btn btn-text d-flex justify-content-start align-items-center border-bottom p-2'
                              onClick={()=>{
                              console.log(lastAccessedParent, platformFiledDataSet[0]);
                              if(lastAccessedParent[lastAccessedParent.length-1]['value']){
                                if(lastAccessedParent.length>2){
                                  getPlatformChildFieldDataAsync(lastAccessedParent[lastAccessedParent.length-1]['value'], {label:'Select Category', value:''}, true )
                                  setNoChildrenFound(false)
                                }else{
                                  // this part always run when we want top category
                                  getPlatformDataSetAsync(lastAccessedParent[lastAccessedParent.length-1]['value'])
                                  setLastAccessedParent([{label:'Select Category', value:''}])
                                  setNoChildrenFound(false)
                                }
                              }
                              let fullary = [...formValues.productRecipes]
                              let modifyingRecipe = recipe
                              modifyingRecipe.checkValue = ''
                              fullary[recipeIndex] = modifyingRecipe
                              setFormikState((pre) => {
                                return {
                                  ...pre,
                                  values: {
                                    ...pre.values,
                                    productRecipes: [...fullary],
                                  },
                                }
                              })
                              
                              }}
                            >
                              <i className="bi bi-arrow-left-circle-fill text-primary"></i>
                              <p className='fs-6 fw-bold mb-0 ms-3' >{lastAccessedParent[lastAccessedParent.length-1]['label']}</p>
                            </button>
                            <div>
                              {props.children}                              
                            </div>
                        </components.MenuList>
                      ) 
                    }else{
                      return(
                              <div>
                                <button type='button' className='w-100 btn btn-text d-flex justify-content-start align-items-center border-bottom p-2'
                                onClick={()=>{
                                  //this is case when we are under the sub category
                                  if(lastAccessedParent[lastAccessedParent.length-1]['value']){
                                    if(lastAccessedParent.length>2){
                                      getPlatformChildFieldDataAsync(lastAccessedParent[lastAccessedParent.length-1]['value'], {label:'Select Category', value:''}, true )
                                      setNoChildrenFound(false)
                                    }else{
                                      // this part always run when we want top category
                                      getPlatformDataSetAsync(lastAccessedParent[lastAccessedParent.length-1]['value'])
                                      setLastAccessedParent([{label:'Select Category', value:''}])
                                      setNoChildrenFound(false)
                                    }
                                  }
                                  let fullary = [...formValues.productRecipes]
                                  let modifyingRecipe = recipe
                                  modifyingRecipe.checkValue = ''
                                  fullary[recipeIndex] = modifyingRecipe
                                  setFormikState((pre) => {
                                    return {
                                      ...pre,
                                      values: {
                                        ...pre.values,
                                        productRecipes: [...fullary],
                                      },
                                    }
                                  })
                                }}
                                
                                >
                                    <i className="bi bi-arrow-left-circle-fill text-primary"></i> 
                                    <p className='fs-6 fw-bold mb-0 ms-3' >{lastAccessedParent[lastAccessedParent.length-1]['label']}</p>
                                </button>
                                <div className='h-100px overflow-y-scroll' >
                                  <p className='mb-0 fs-4 fw-bold' >{props.children}</p> 
                                </div>
                              </div>
                            )
                    }
                  },
                  Option:(props)=>{
                    if(noChildrenFound){
                      return(
                        <div className='p-2  border-bottom' {...props.innerProps} >
                            <p className='fs-5 mb-0' >{props.label}</p>
                        </div>
                      )
                    }else{
                      return (
                        <button type='button' className='btn btn-outline rounded-0 text-start justify-content-between d-flex w-100 fs-5 align-items-center' onClick={()=>{
                          console.log('-------', lastAccessedParent, props['value'] , platformFiledDataSet[0]);
                          
                          // in subcategory the have parent_data but in case of top category they not have parent_data and calling api is different
                          // we are setting last accessed parent with second params
                          if(platformFiledDataSet[0]['parent_data']===null){
                            getPlatformChildFieldDataAsync(props['value'], {label:props.label, value: platformFiledDataSet[0]['platform_field']+'' }, false, true )
                          }else{
                            getPlatformChildFieldDataAsync(props['value'], {label:props.label, value: platformFiledDataSet[0]['parent_data'] } ) 
                          }
                        }}  >
                          {props.label } <i className="bi bi-arrow-right-square-fill text-primary"></i>
                        </button>
                      )
                    }
                    }
                  }}
                /> 
                </div>
                
                 }
              </div>
            </div>
          </div>
        )}

        {[...platformNativeFields, ...platformCustomFields].find(
          (pltFrm) => pltFrm.id + '' === formValues.productRecipes[recipeIndex].checkPlatformField
        )?.input_type == 'MULTISELECT' && (
          <div>
            <div className='input-group row ms-1'>
              <div className='input-group-text p-0 col-6 col-sm-2'>
                <select
                  className='form-select h-100 rounded-0'
                  name={`productRecipes[${recipeIndex}].checkOperator`}
                  value={recipe.checkOperator}
                  onChange={handleChange}
                >
                  <option value='CONTAINS'>Contain</option>
                  <option value='NOT_CONTAINS'>Does not Contain</option>
                </select>
              </div>
              <div className='input-group-text p-0 col-6 col-sm-3'>
                <select
                  className='form-select h-100 rounded-0'
                  name={`productRecipes[${recipeIndex}].checkConjunction`}
                  value={recipe.checkConjunction}
                  onChange={handleChange}
                >
                  <option value='OR'>Any</option>
                  <option value='AND'>All</option>
                </select>
              </div>
              <div className='form-control p-0 col-12 col-sm-7 mt-2 mt-sm-0'>
                <Select
                  isMulti
                  required
                  isLoading={platformFieldDataSetLoading}
                  key={'selc' + recipeIndex + platformFiledDataSet.length }
                  hideSelectedOptions
                  placeholder={`Select your collection`}
                  options={platformFiledDataSet.map((pltd) => ({
                      label: pltd.api_name,
                      value: pltd.id + '',
                    }))}
                  value={
                    recipe.checkValue.length
                      ? recipe.checkValue.split(',').map((selc, ins) => {
                        console.log('selc', selc, isNaN(Number(selc)))
                        
                        if(!isNaN(Number(selc))){
                            return {
                               label: platformFiledDataSet.find((pltd) => pltd.id + '' == selc)?.api_name,
                               value: selc,
                             }
                        }
                    })
                      : []
                  }
                  onChange={(newValues, actionMeta) => {
                   
                    let updats: string[] = newValues.map((it) => {
                      return it!.value
                    })
                    let fullary = [...formValues.productRecipes]
                    let modifyingRecipe = recipe
                    modifyingRecipe.checkValue = updats.join(',')
                    fullary[recipeIndex] = modifyingRecipe

                    setFormikState((pre) => {
                      return {
                        ...pre,
                        values: {
                          ...pre.values,
                          productRecipes: [...fullary],
                        },
                      }
                    })
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='col-1 mt-xl-0 mt-md-2 mt-2 align-content-center'>
        <button
          className='btn btn-outline btn-light-danger'
          type='button'
          onClick={() => {
            if (recipe.id) {
              dispatch(deleteRecipeMappingItemAsync({templateMappingItemId: recipe.id + ''})).then(
                (response) => {
                  setSubmitting(true)
                  console.log('delete mapping response----->>', response)
                  if (response.payload?.status == 400) {
                    warningToast(response.payload?.data?.error)
                    setSubmitting(false)
                    return
                  }

                  successToast(response.payload?.message)
                  let copiedArray = [...formValues.productRecipes]
                  let filteredItems = copiedArray.filter(
                    (_, existingRecipeIndex) => existingRecipeIndex !== recipeIndex
                  )
                  console.log(recipeIndex, 'filteredItems', filteredItems)
                  setFormikState((pre) => {
                    return {
                      ...pre,
                      values: {
                        ...pre.values,
                        productRecipes: [...filteredItems],
                      },
                    }
                  })

                  setSubmitting(false)
                  getLatestRecipesList()
                }
              )
            } else {
              let copiedArray = [...formValues.productRecipes]
              let filteredItems = copiedArray.filter(
                (_, existingRecipeIndex) => existingRecipeIndex !== recipeIndex
              )

              setFormikState((pre) => {
                return {
                  ...pre,
                  values: {
                    ...pre.values,
                    productRecipes: [...filteredItems],
                  },
                }
              })
            }
          }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>
    </div>
  )
}

export default RecipeRuleItem

// {platformNativeFields.find(
//     (pltFrm) =>
//       pltFrm.id + '' ===
//       formValues.productRecipes[recipeIndex].checkPlatformField
//   )?.input_type  == 'TEXT' && (
//   <div>
//     <div className='input-group'>
//       <div className='input-group-text p-0'>
//         <select className='form-select h-100 rounded-0' name='' id=''>
//           <option value=''>Contain</option>
//           <option value=''>Does not Contain</option>
//         </select>
//       </div>
//       <div className='input-group-text p-0'>
//         <select className='form-select h-100 rounded-0' name='' id=''>
//           <option value=''>Any</option>
//           <option value=''>All</option>
//         </select>
//       </div>
//      <div className='form-control p-0' >
//         <CreatableSelect
//          isMulti
//          placeholder='Enter Value'
//         />

//       </div>

//     </div>
//   </div>
//   )}
