/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG, toAbsoluteUrl} from 'src/marketConnect/helpers'
import {PageTitle} from 'src/marketConnect/layout/core'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import AddPrimaryStoreModal from 'src/utils/modals/AddPrimaryStoreModal'
import Platforms from '../listing/Platforms'
import { Spinner } from 'react-bootstrap'
import Dashboard from './Dashboard'

// const DashboardPage: FC = () => {

//   return(
//   <>
//     {/* begin::Row */}
//     <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
//       {/* begin::Col */}
//       <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>

//         <CardsWidget20
//           className='h-md-50 mb-5 mb-xl-10'
//           description='Active Projects'
//           color='#F1416C'
//           img={toAbsoluteUrl('/media/patterns/vector-1.png')}
//         />
//         <CardsWidget7
//           className='h-md-50 mb-5 mb-xl-10'
//           description='Professionals'
//           icon={false}
//           stats={357}
//           labelColor='dark'
//           textColor='gray-300'
//         />
//       </div>
//       {/* end::Col */}

//       {/* begin::Col */}
//       <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
//         <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
//         <ListsWidget26 className='h-lg-50' />
//       </div>
//       {/* end::Col */}

//       {/* begin::Col */}
//       <div className='col-xxl-6'>
//         <EngageWidget10 className='h-md-100' />
//       </div>
//       {/* end::Col */}
//     </div>
//     {/* end::Row */}

//     {/* begin::Row */}
//     <div className='row gx-5 gx-xl-10'>
//       {/* begin::Col */}
//       <div className='col-xxl-6 mb-5 mb-xl-10'>
//         {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
//       </div>
//       {/* end::Col */}

//       {/* begin::Col */}
//       <div className='col-xxl-6 mb-5 mb-xl-10'>
//         {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
//       </div>
//       {/* end::Col */}
//     </div>
//     {/* end::Row */}

//     {/* begin::Row */}
//     <div className='row gy-5 gx-xl-8'>
//       <div className='col-xxl-4'>
//         <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
//       </div>
//       <div className='col-xl-8'>
//         <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
//       </div>
//     </div>
//     {/* end::Row */}

//     {/* begin::Row */}
//     <div className='row gy-5 g-xl-8'>
//       <div className='col-xl-4'>
//         <ListsWidget2 className='card-xl-stretch mb-xl-8' />
//       </div>
//       <div className='col-xl-4'>
//         <ListsWidget6 className='card-xl-stretch mb-xl-8' />
//       </div>
//       <div className='col-xl-4'>
//         <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
//         {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
//       </div>
//     </div>
//     {/* end::Row */}

//     <div className='row g-5 gx-xxl-8'>
//       <div className='col-xxl-4'>
//         <MixedWidget8
//           className='card-xxl-stretch mb-xl-3'
//           chartColor='success'
//           chartHeight='150px'
//         />
//       </div>
//       <div className='col-xxl-8'>
//         <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
//       </div>
//     </div>
//   </>
// )}

// import React from 'react'
// import axios from 'axios'

// import {Platform} from 'src/interfaces/Platform.interface'
// import {connectPlatformAsync, fetchPlatformsAsync} from 'src/redux/actions/platformActions'

// import {toast} from 'react-toastify'
// import ConnectPlatformModal from '../listing/components/ConnectPlatformModal'
// import PlatformCard from '../listing/components/PlatformCard'
// import {Spinner} from 'react-bootstrap'


// const Platforms: FC = () => {
//   const {platformData} = useAppSelector((state) => state.platform)

//   const {connectedPlatformData} = useAppSelector((state) => state.connectedPlatform)
//   const [showConnectPlatformModal, setShowConnectPlatformModal] = useState<boolean>(false)
//   const [selectedPlatform, setSelectedPlatform] = useState<Platform | null>(null)

//   const [ecommercePlatform, setEcommercePlatform] = useState<any>([])


//   const {fetchedConnectedPlatformData} = useAppSelector((state) => state.fetchConnectedPlatform)

//   const dispatch = useAppDispatch()

//   const onClickConnect = (platform: Platform) => {
//     setSelectedPlatform(platform)
//     setShowConnectPlatformModal(true)
//   }
 
//   // useEffect(() => {
  
//   //   dispatch(fetchPlatformsAsync())
//   //   dispatch(fetchConnectedPlatformAsync())
  
//   // }, [])

//   useEffect(() => {
//     if (fetchedConnectedPlatformData?.length === 0) {
//       let newPlatformData = platformData?.filter((ele) => ele.type == 'ECOMMERCE'||[])
//       setEcommercePlatform(newPlatformData)
//     } else {
//       setEcommercePlatform(platformData)
//     }
//   }, [fetchedConnectedPlatformData,[]])

//   const handleDataReceived = (dataCollector: any, friendlyName: string) => {
//     console.log('Credentials taken from input:', dataCollector)
//     // const email = localStorage.getItem('userid') // Assuming 'userid' is the key you used to store the email

//     if (selectedPlatform) {
//       let isMainStore = fetchedConnectedPlatformData.length === 0
//       const payload = {
//         name: selectedPlatform.name,
//         type: selectedPlatform.type,
//         code: selectedPlatform.name,
//         display_name: friendlyName,
//         platform_info: selectedPlatform.id,
//         credentials: {
//           identifier: dataCollector.find((item: any) => item.name === 'identifier')?.value || null,
//           client_id: dataCollector.find((item: any) => item.name === 'client_id')?.value || null,
//           client_secret:
//             dataCollector.find((item: any) => item.name === 'client_secret')?.value || null,
//           token_id: dataCollector.find((item: any) => item.name === 'token_id')?.value || null,
//           token_secret:
//             dataCollector.find((item: any) => item.name === 'token_secret')?.value || null,
//           refresh_token: null,
//         },
//         isConnected: true,
//         isActive: selectedPlatform.isActive,
//         isMainStore: isMainStore,
//         isOrderSyncProcessing: false,
//         isNewOrderSyncProcessing: false,
//         isOrderCreateProcessing: false,
//       }

//       console.log('payload: ', payload)

//       dispatch(connectPlatformAsync(payload))
//         .then((response) => {
//           if (response.meta.requestStatus == 'fulfilled') {
//             const statusCode = response.payload.status_code
//             const platformName = payload.name // Assuming name is the property containing the platform name
           

//             if (statusCode === 201) {
//               console.log('Combined payload:', connectedPlatformData)
//              return toast.success(`Platform ${platformName} connected successfully!`)
             
//             } else {
//               toast.error(`Failed to connect platform ${platformName}. Status code: ${statusCode}`)
//             }
//           } else {
//             console.error('Connection failed:', response.payload)
//             toast.error('Failed to connect platform. Please try again.')
//           }
//         })
//         .catch((error) => {
//           console.error('Error connecting platform:', error)
//           toast.error('An error occurred while connecting the platform. Please try again.')
//         })
//     }
//   }

//   return (
//     <>
//       <div>
//         <h3>Available E-Commerce Channels</h3>
//       </div>
//       <div className='py-2'>
//         <div className='row g-5 gx-2 g-xl-8 my-2  mt-5'>
//           {ecommercePlatform?.length > 0 &&
//             ecommercePlatform.map((item: any) => (
//               <React.Fragment key={item.id}>
//                 {item && <PlatformCard data={item} onClickConnect={() => onClickConnect(item)} />}
//               </React.Fragment>
//             ))}
//         </div>
//       </div>
//       <ConnectPlatformModal
//         isVisible={showConnectPlatformModal}
//         closeModal={() => setShowConnectPlatformModal(false)}
//         data={selectedPlatform?.available_platform_auth_requirement || []}
//         onDataReceived={handleDataReceived} // Pass the data receiving function
//       />
//       {/* <Modal show={true} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Change Password</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <h1>Abhishek</h1>
//         </Modal.Body>
//         <Modal.Footer></Modal.Footer>
//       </Modal> */}
//     </>
//   )
// }

const DashboardWrapper: FC = () => {
  const [showAddPrimaryStoreModal, setShowAddPrimaryStoreModal] = useState(false)
  const {fetchedConnectedPlatformData,fetchedConnectedPlatformLoading} = useAppSelector((state) => state.fetchConnectedPlatform)
  const { shopifyConnectFirstTimeMessageShow,firstTimeMessage } = useAppSelector(state=>state.auth)
  const { fetchedUserAccountData } = useAppSelector(state=>state.userAccountData)

  useEffect(() => {
    const hasPrimaryStore =
      fetchedConnectedPlatformData.length &&
      fetchedConnectedPlatformData?.some((platform) => platform.isMainStore)

    if (!hasPrimaryStore) {
      setShowAddPrimaryStoreModal(true)
    } else {
      setShowAddPrimaryStoreModal(false)
    }
  }, [fetchedConnectedPlatformData])

  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      {/* <DashboardPage /> */}
      {/* { shopifyConnectFirstTimeMessageShow ? 
      <div className='alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-2 mb-10'>
        <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0'>
          <KTSVG path="media/icons/duotune/general/gen045.svg" className="svg-icon-primary svg-icon-2hx" />
        </span>
        <div className='d-flex flex-column text-secondary pt-2 pe-sm-10'>
          <span className='fw-bold text-primary' >
              {firstTimeMessage}
          </span>
        </div>
        <button
          type='button'
          className='position-absolute position-sm-relative m-2 mt-0 m-sm-0 top-0 end-0 btn btn-sm btn-icon ms-sm-auto'
          data-bs-dismiss='alert'
        >
          <span className='svg-icon svg-icon-1 svg-icon-secondary '>
            <KTSVG path="media/icons/duotune/abstract/abs012.svg" className="svg-icon-dark svg-icon-sm" />
          </span>
        </button>
      </div> : null } */}
      {/* <div className='mb-5' >
        <Dashboard/>
      </div> */}
      <Platforms />
      {/* {fetchedConnectedPlatformLoading && (
        <div className='d-flex align-items-center justify-content-center h-100'>
          <h1>
            <Spinner animation='border' variant='primary' />
          </h1>
        </div>
      )} */}

      {/* {!fetchedConnectedPlatformLoading && fetchedUserAccountData?.source!=='WOOCOMMERCE' && fetchedUserAccountData?.user_type=='USER' && (
        <AddPrimaryStoreModal
          show={showAddPrimaryStoreModal}
          onClose={() => setShowAddPrimaryStoreModal(false)}
        />
      )} */}
    </>
  )
}

export {DashboardWrapper}
