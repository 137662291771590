import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import { ORDER_ITEM } from 'src/app/_interfaces/order.interface'
import { getOrdersListAsync, orderResyncAsync } from '../actions/orderActions'

type StepStatus = 'active' | 'inactive' | 'finished'

type TourSteps = {
  // stepZero?:StepStatus
  stepOne: StepStatus
  stepTwo: StepStatus
  stepThree: StepStatus
}

type InitialState = {
    visible:boolean
    stepsData:TourSteps
}
const initialState:InitialState  = {
    visible:false,
    stepsData:{
        stepOne: 'active',
        stepTwo: 'inactive',
        stepThree: 'inactive',
    }
    
}

const tourPopupSlice = createSlice({
  name: 'tourPopupSlice',
  initialState,
  extraReducers: (builder) => {},
  reducers: { 
    setStepsData:(state, action:{payload:{stepData:TourSteps, visible:boolean }})=>{
        state.stepsData = action.payload.stepData
        state.visible = action.payload.visible
    }
  },
})

export const { setStepsData } = tourPopupSlice.actions

export default tourPopupSlice.reducer
