import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useAppToast from 'src/hooks/useAppToast'
import { fetchUserAccountDetailsAsync } from 'src/redux/actions/accountActions'
import { handleAppInstallationAsync, handleOauthForConnectPlatformAsync, loginAsync, userOauthLoginAsync } from 'src/redux/actions/authActions'
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'
import { accessSessionTokenKeyName, checkUserToNavigateInPricing, refreshSessionTokenKeyName, shopifyDetailsForOauth, shopifyDetailsForWithoutAuth } from 'src/utils/constants'
import { messageTemplete } from 'src/utils/messageTemplete'

const HandleShopifyAppInstallation:FC = () => {

    const [loading, setLoading] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useAppDispatch()
    const { errorToast,successToast,warningToast } = useAppToast()
    const navigate = useNavigate()
    const { fetchedUserAccountLoading } = useAppSelector(state=>state.userAccountData)

    useEffect(() => {
        const hmac = searchParams.get('hmac')
        const host = searchParams.get('host')
        const platform_name = searchParams.get('platform_name')
        const shop = searchParams.get('shop')
        const timestamp = searchParams.get('timestamp')
        dispatch(fetchUserAccountDetailsAsync())
        .then((loginRes)=>{
          console.log('response of login', loginRes.payload );
          setLoading(true)
          dispatch(handleAppInstallationAsync({hmac, host, platform_name, shop, timestamp}))
          .then((response)=>{
              console.log('response of app instalation', response.payload);
              if(response.payload?.status_code==400){
                  dispatch(handleOauthForConnectPlatformAsync({ available_platform_name : platform_name, shop: shop }))
                      .then((response2)=>{
                          console.log('response of oauth url', response2.payload);
                      if(response2.payload?.data && response2.payload?.status_code===200 ){
                        if(loginRes.payload?.email){
                          localStorage.setItem(shopifyDetailsForWithoutAuth, JSON.stringify({ platform_name, shop, user_id: loginRes.payload?.id }))
                        }else{
                          localStorage.setItem(shopifyDetailsForWithoutAuth, JSON.stringify({ platform_name, shop }))
                        }
                          //setting a check to navigate in pricing page
                          localStorage.setItem(checkUserToNavigateInPricing,'1')
                          window.open(response2.payload?.data?.url, '_self')
                          setLoading(false)
                      }
                      setLoading(false)  
                      })
              }
  
              if(response.payload?.status_code==200){
                //the below case works when a user already is logged in and and the platform is already connected to another user account 
                if(loginRes.payload?.email){
                  if(loginRes.payload?.email==response.payload?.data?.user?.email){
                    dispatch(userOauthLoginAsync({email: response.payload?.data?.user?.email, 
                        // password: response.payload?.data?.credentials?.identifier 
                    }))
                        .then((response) => {
                          localStorage.removeItem(shopifyDetailsForWithoutAuth)
                          if (response?.payload?.detail) {
                            errorToast(response?.payload?.detail)
                            setLoading(false)
                          } else if (response?.payload?.access) {
                            console.log('show toast msg')
                            localStorage.setItem(accessSessionTokenKeyName, response?.payload?.access)
                            localStorage.setItem(refreshSessionTokenKeyName, response?.payload?.refresh)
                            navigate('/auth')
                            successToast(messageTemplete.LOGIN_SUCCESS)
                            setLoading(false)
                          } else {
                            errorToast(messageTemplete.SOMETHING_WENT_WRONG)
                            setLoading(false)
                          }
                        })
                        .catch((error) => {
                          console.log('error catch:', error)
                          setLoading(false)
                        })
                  }else{
                    warningToast("This Shopify account is already in use")
                    navigate('/listing-channels')
                  }
                }else{
                  dispatch(userOauthLoginAsync({email: response.payload?.data?.user?.email, 
                    // password: response.payload?.data?.credentials?.identifier 
                }))
                    .then((response) => {
                      localStorage.removeItem(shopifyDetailsForWithoutAuth)
                      if (response?.payload?.detail) {
                        errorToast(response?.payload?.detail)
                        setLoading(false)
                      } else if (response?.payload?.access) {
                        console.log('show toast msg')
                        localStorage.setItem(accessSessionTokenKeyName, response?.payload?.access)
                        localStorage.setItem(refreshSessionTokenKeyName, response?.payload?.refresh)
                        navigate('/auth')
                        successToast(messageTemplete.LOGIN_SUCCESS)
                        setLoading(false)
                      } else {
                        errorToast(messageTemplete.SOMETHING_WENT_WRONG)
                        setLoading(false)
                      }
                    })
                    .catch((error) => {
                      console.log('error catch:', error)
                      setLoading(false)
                    })
                }
              }
              
          })
        })
    }, [])
    
  if(fetchedUserAccountLoading) return <Loader/>

  return (
    <div>
        <Loader/>
    </div>
  )
}

export default HandleShopifyAppInstallation