import React, {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Select from 'react-select'
import Draggable from 'react-draggable';
import {useFormik} from 'formik'
import clsx from 'clsx'
import DraggableList from './DraggableList'
import EventMappingRow from './EventMappingRow';

type AUTH_REQUIREMENT = {
  name: string;
  group_code: string;
  input_type: string;
  display_name: string;
  description: string;
  type: string;
  placeholder: string;
}

type EventInitialValues = {
  name: string;
  group_code: string;
  input_type: string;
  display_name: string;
  description: string;
  type: string;
  auth_requirements: AUTH_REQUIREMENT[];
}

type Event = {
    id: number
    name: string
    group_code: string
    input_type: string
    display_name: string
    description: string
    type: string
  }

type ManageEventFormProps = {
  showAddEditEvent: boolean
  handleClose: () => void
  selectedEvent: null | Event
}

const initialValues:EventInitialValues = {
  name: '',
  group_code: '',
  input_type: '',
  display_name: '',
  description: '',
  type: '',
  auth_requirements: [
    {
      name: '',
      group_code: '',
      input_type: '',
      display_name: '',
      description: '',
      type: '',
      placeholder: '',
    },
  ],
}

const ManageEventForm: FC<ManageEventFormProps> = ({
  handleClose,
  showAddEditEvent,
  selectedEvent,
}) => {

  const {values, setFormikState, handleChange, handleBlur, handleSubmit} = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      // Handle form submission
      // You can use the values object to update the selectedEvent state
      handleClose()
    },
  })

  const [draggableItemHeight, setDraggableItemHeight] = useState<number>(200)
  const [positions, setPositions] = useState<Record<number, { x: number; y: number }>>(
    values.auth_requirements.reduce((acc, item, index) => {
      acc[index] = { x: 0, y: index * draggableItemHeight }; // Set initial positions
      return acc;
    }, {} as Record<number, { x: number; y: number }>)
  );

  // const [positions, setPositions] = useState<Record<number, { x: number; y: number }>>(
  //  {
  //   0: { x: 0, y: 0 },
  //   1: {x: 0, y: 50},
  //   2:{x: 0, y: 20},
  //   3:{x: 0, y: 30}
  //  }
  // );

  const [draggingId, setDraggingId] = useState<number | null>(null);

  console.log('positions', positions)

  const handleDragStart = (id: number) => {
    console.log('start dragging')
    setDraggingId(id);
  };

  const handleDrag = (id: number, e: any, data: any) => {
    const newPositions = { ...positions };
    newPositions[id] = { x: data.x, y: data.y };
    console.log('handle drag', newPositions)
    setPositions(newPositions);
  };

  const handleStop = (id: number, e: any, data: any) => {
    setDraggingId(null); // Reset the dragging ID

    // Find the closest item based on `y` position
    const draggedItemIndex = values.auth_requirements.findIndex((item, ind) => ind === id);
    const draggedItemY = data.y;
    const targetIndex = Math.round(draggedItemY / draggableItemHeight); // Each item has a height of 60px

    // Clamp the target index to avoid going out of bounds
    const clampedIndex = Math.max(0, Math.min(values.auth_requirements.length - 1, targetIndex));

    // Reorder the items array
    const reorderedItems = [...values.auth_requirements];
    const [draggedItem] = reorderedItems.splice(draggedItemIndex, 1);
    reorderedItems.splice(clampedIndex, 0, draggedItem);

    console.log('reorderedItems', reorderedItems, draggedItemIndex, draggedItemY, targetIndex, clampedIndex )

    setFormikState((pre)=>{
      return {
       ...pre,
        values: {
         ...pre.values,
          auth_requirements: reorderedItems,
        },
      }
    })

    console.log('drag stopped');
    // Reset positions
    setPositions(
      reorderedItems.reduce((acc, item, index) => {
        acc[index] = { x: 0, y: index*draggableItemHeight };
        return acc;
      }, {} as Record<number, { x: number; y: number }>)
    );
  };

  const preventDragHandler = (e: React.MouseEvent) => {
    if (e.target instanceof HTMLInputElement) {
      e.stopPropagation();
    }
  };

  useEffect(() => {

    let mappingElement = document.getElementsByClassName('react-draggable')
    if(mappingElement.length){
      console.log('mappingElement', mappingElement[0].clientHeight)
      setDraggableItemHeight(mappingElement[0].clientHeight+20)
    }
     
  }, [])
  
  

  return (
    <Modal
      fullscreen
      scrollable
      show={showAddEditEvent}
      onHide={() => {
        handleClose()
      }}
    >
      <Modal.Header>
        <Modal.Title>{selectedEvent ? 'Edit Event' : 'Add Event'}</Modal.Title>
        <div className='d-flex '>
          <button className='btn btn-secondary btn-sm fs-5 fw-bold' onClick={handleClose}>
            Close
          </button>
          <button className='btn btn-success btn-sm fs-5 fw-bold ms-2'>Save</button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className='card border-light-primary shadow mt-5'>
            <div className='card-body'>
              <div
                className='position-absolute bg-primary px-3 py-2 text-white rounded shadow'
                style={{top: '-20px'}}
              >
                <h5 className='mb-1'>Set Main Event</h5>
              </div>

              <div className='row gy-4 mt-4'>
                <div className='col-2'>
                  <label htmlFor='' className='form-label'>
                    Group Code
                  </label>
                  <Select
                    placeholder='Select'
                    classNames={{
                      input: () => 'fs-5',
                      indicatorSeparator: () => 'd-none',
                      dropdownIndicator: () => 'text-secondary fs-8',
                      placeholder: () => 'fs-5 text-dark',
                    }}
                  />
                </div>
                <div className='col-2'>
                  <label htmlFor='' className='form-label'>
                    Input Type
                  </label>
                  <Select
                    placeholder='Select'
                    classNames={{
                      input: () => 'fs-5',
                      indicatorSeparator: () => 'd-none',
                      dropdownIndicator: () => 'text-secondary fs-8',
                      placeholder: () => 'fs-5 text-dark',
                    }}
                  />
                </div>
                <div className='col-2'>
                  <label htmlFor='' className='form-label'>
                    Event Name
                  </label>
                  <input
                    type='text'
                    placeholder='Name of event'
                    className='form-control form-control-lg '
                  />
                </div>
                <div className='col-2'>
                  <label htmlFor='' className='form-label'>
                    Event Display Name
                  </label>
                  <input
                    type='text'
                    placeholder='Display Name'
                    className='form-control form-control-lg '
                  />
                </div>
                <div className='col-2'>
                  <label htmlFor='' className='form-label'>
                    Event Type
                  </label>
                  <select className='form-select form-select-lg'>
                    <option value=''>Select</option>
                    <option value='MAIN_STORE_SETTING'>Main Store Setting</option>
                    <option value='SECONDARY_STORE_SETTING'>Secondary Store Setting</option>
                  </select>
                </div>
                <div className='col-2'>
                  <label htmlFor='' className='form-label'>
                    Event Description
                  </label>
                  <input
                    type='text'
                    placeholder='Event description'
                    className='form-control form-control-lg '
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='card border-light-primary shadow mt-5'>
            <div className='card-body'>
              <div
                className='position-absolute bg-primary px-3 py-2 text-white rounded shadow'
                style={{top: '-20px'}}
              >
                <h5 className='mb-1'>Add Mappings</h5>
              </div>
              <div className='d-flex justify-content-end align-items-center mb-4'>
                <button
                  type='button'
                  className='btn btn-sm btn-primary'
                  onClick={() => {
                    let authReq = [...values.auth_requirements]
                    authReq.push({
                      name: '',
                      group_code: '',
                      input_type: '',
                      display_name: '',
                      description: '',
                      type: '',
                      placeholder: '',
                    })
                    setFormikState((pre) => {
                      return {...pre, values: {...pre.values, auth_requirements: authReq}}
                    })

                    //on item add set initial position
                    setPositions(
                      authReq.reduce((acc, item, index) => {
                        acc[index] = { x: 0, y: index*draggableItemHeight };
                        return acc;
                      }, {} as Record<number, { x: number; y: number }>)
                    );
                  }}
                >
                  Add New
                </button>
              </div>
              {values.auth_requirements.length ? (
                <div style={{ height: `${values.auth_requirements.length * draggableItemHeight}px`, position:'relative' }} >
                  {
                    values.auth_requirements.map((mapping, index) => {
                      return (
                        // <EventMappingRow
                        //   key={index}
                        //   index={index}
                        //   mapping={mapping}
                        //   positions={positions}
                        //   draggingId={draggingId}
                        //   handleDragStart={handleDragStart}
                        //   handleDrag={handleDrag}
                        //   handleStop={handleStop}
                        //   auth_requirements={values.auth_requirements}
                        //   handleChange={handleChange}
                        //   setFormikState={setFormikState}
                        // />

                        <Draggable
                          key={index}
                          position={positions[index]}
                          onStart={() => handleDragStart(index)}
                          onDrag={(e, data) => handleDrag(index, e, data)}
                          onStop={(e, data) => handleStop(index, e, data)}
                          bounds="parent"
                          allowAnyClick={false}
                          axis='y'
                          cancel='.no-drag'
                          handle='.on-drag'
                        >
                          
                          <div 
                            // className='mt-1 bg-hover-light-primary cursor-grab p-4 pt-0'
                            className={clsx('mt-1 bg-light-secondary p-4 pt-0 position-absolute w-100', draggingId === index ? ' bg-hover-light-primary cursor-grab' : '' )}
                            style={{
                              transform: draggingId === index ? 'scale(1.05)' : 'scale(1)', // Slight scale effect while dragging
                              transition: draggingId === index ? 'none' : 'transform 0.2s ease', // Smooth transition when not dragging
                              cursor: draggingId === index ? 'grabbing' : 'auto', // Smooth transition when not dragging
                              zIndex: draggingId === index ? 1000 : 1, // Higher z-index for the dragged item
                              top:'10px'
                            }}
                            onLoad={(ev)=>{
                              alert(ev.currentTarget.clientHeight)
                              console.log( '=============>>', ev.currentTarget.clientHeight)
                              if(index==0){
                              }
                            }}

                          >
                            <button type='button' className='on-drag btn btn-sm btn-link px-3 py-1' style={{cursor:'grab'}} > <i className="bi bi-grip-horizontal fs-3"></i></button>
                            <div onMouseDown={preventDragHandler} className='no-drag d-flex justify-content-end'>
                              <button
                                type='button'
                                className='btn btn-link'
                                onClick={() => {
                                  let authReq = [...values.auth_requirements]
                                  authReq.pop()
                                  setFormikState((pre) => {
                                    return {...pre, values: {...pre.values, auth_requirements: authReq}}
                                  })
                                }}
                              >
                                <i className='bi bi-x-square-fill fs-5 text-danger'></i>
                              </button>
                            </div>
                            <div onMouseDown={preventDragHandler} className='no-drag row gy-2'>
                              <div className='col-xl-2 col-lg-4 col-md-6 col-12'>
                                <label htmlFor='' className='form-label'>
                                  Group Code
                                </label>
                                <Select
                                  placeholder='Select'
                                  classNames={{
                                    input: () => 'fs-5',
                                    indicatorSeparator: () => 'd-none',
                                    dropdownIndicator: () => 'text-secondary fs-8',
                                    placeholder: () => 'fs-5 text-dark',
                                  }}
                                />
                              </div>
                              <div className='col-xl-2 col-lg-4 col-md-6 col-12'>
                                <label htmlFor='' className='form-label'>
                                  Input Type
                                </label>
                                <Select
                                  placeholder='Select'
                                  classNames={{
                                    input: () => 'fs-5',
                                    indicatorSeparator: () => 'd-none',
                                    dropdownIndicator: () => 'text-secondary fs-8',
                                    placeholder: () => 'fs-5 text-dark',
                                  }}
                                />
                              </div>
                              <div className='col-xl-2 col-lg-4 col-md-6 col-12'>
                                <label htmlFor='' className='form-label'>
                                  Event Name
                                </label>
                                <input
                                  type='text'
                                  name={`auth_requirements[${index}][name]`}
                                  onChange={handleChange}
                                  value={mapping.name}
                                  placeholder='Name of event'
                                  className='form-control form-control-lg '
                                />
                              </div>
                              <div className='col-xl-2 col-lg-4 col-md-6 col-12'>
                                <label htmlFor='' className='form-label'>
                                  Event Display Name
                                </label>
                                <input
                                  type='text'
                                  placeholder='Display Name'
                                  className='form-control form-control-lg '
                                />
                              </div>
                              <div className='col-xl-2 col-lg-4 col-md-6 col-12'>
                                <label htmlFor='' className='form-label'>
                                  Placeholder
                                </label>
                                <input
                                  type='text'
                                  placeholder='Display Name'
                                  className='form-control form-control-lg '
                                />
                              </div>
                              <div className='col-xl-2 col-lg-4 col-md-6 col-12'>
                                <label htmlFor='' className='form-label'>
                                  Event Description
                                </label>
                                <input
                                  type='text'
                                  placeholder='Event description'
                                  className='form-control form-control-lg '
                                />
                              </div>
                              <div>
                                <div className='separator my-2'></div>
                              </div>
                            </div>
                          </div>
                        </Draggable>
                      )
                    })
                  }
                </div>
              ) : (
                <div className='bg-light-primary p-4 rounded border'>
                  <p className='mb-0 fs-6 text-primary'>Add New Auth Mapping</p>
                </div>
              )}
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default ManageEventForm
